import {
  Control,
  Field,
  Textarea
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableTextAreaCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  inlineEdit,
  doubleWide
}) {
  const styles = `small-row-black ${doubleWide ? "double-wide-textarea" : "min-column-width"}`;
  return (
    <td onClick={e => e.stopPropagation()}>
      <Field className="has-addons">
        <Control>
          <Textarea
            rows={2}
            className={styles}
            onChange={onChange}
            type="text"
            name={propName}
            value={value}
            disabled={!editable}
          />
        </Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable})}
      </Field>
    </td>
  );
}

export default useEditableTableTextAreaCell;
