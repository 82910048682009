import React, {useState} from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Table from "react-bulma-components/lib/components/table";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import {
  Control,
  Field,
  Input,
  Label,
  Select
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import {Icon} from "react-bulma-components";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import {NotificationManager} from "react-notifications";
import {getQueryString} from "../../utils";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function CreateWorkModal({open, setOpen, endpoint, historicWork}) {
  // const prefilledWork = {
  //   description: historicWork.description,
  //   supplier: historicWork.supplier,
  //   labour_labour_cost: historicWork.labour_labour_cost,
  //   labour_hours: historicWork.labour_labour_cost,
  //
  // } : {vehicle_type: vehicleTypes[0]}

  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  let [work, setWork] = useState( {});
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);



  const onClickAction = () => {
    if (checkAllFieldsFilled()) {
      save();
      setWork({})
      setOpen(false);
    } else {
      NotificationManager.error("Please fill in all fields", "Error!", 10000);
    }
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  }

  const handleWorkSupplierSelect = supplier => {
    let newWork = {...work}
    newWork.supplier = supplier[0];
    setWork(newWork);
  };

  const handleChange = (e, index) => {
    let newWork = {...work};
    newWork[e.target.name] = e.target.value;
    setWork(newWork);
  };

  const checkAllFieldsFilled = () => {
    if (
      false
    ) {
      return false;
    }
    return true;
  };

  const save = () => {
    const url = endpoint + "worklookup";
    const queryString = getQueryString({
      description: work.description,
      labour_cost: work.labour_cost,
      labour_hours: work.labour_hours,
    });
    return axios.get(`${endpoint}worklookup${queryString}`).then((response) => {
      if(response.data.results.length === 0) {
        const conf = {
          method: "post",
          data: work,
          url
        };
        //
        return axios(conf).then(() => {
          dispatch(createSetDataFreshAction(dataFresh + 1));
          setOpen(false);
        });
      } else {
        NotificationManager.warning("This work already exists!")
      }
    });
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      onClick={e => {e.stopPropagation()}}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content className="wider-modal">
        <Section style={{backgroundColor: "white"}}>
          <Heading className="is-centered">Create New Work Lookup</Heading>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>Description</Label>
                <Control>
                  <Input
                    type="text"
                    name="description"
                    onChange={handleChange}
                    value={work.description || ""}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Supplier</Label>
                <Control>
                  <AsyncTypeahead
                    id="typeahead"
                    labelKey="display_name"
                    minLength={2}
                    name={"supplier"}
                    onSearch={handleSearch}
                    onChange={handleWorkSupplierSelect}
                    placeholder={"Search Supplier"}
                    options={searchData}
                    ref={typeahead => setTypeahead(typeahead)}
                    className="typeahead"
                    isLoading={isLoading}
                    selected={work.supplier && work.supplier.id ? [work.supplier] : []}
                  />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>Labour Hours</Label>
                <Control>
                  <Input
                    type="text"
                    name="labour_hours"
                    onChange={handleChange}
                    value={work.labour_hours || ""}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Labour Cost</Label>
                <Control className="has-icons-left">
                  <Input
                    type="text"
                    name="labour_cost"
                    onChange={handleChange}
                    value={work.labour_cost || ""}
                    required
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Customer Labour Charge</Label>
                <Control className="has-icons-left">
                  <Input
                    type="text"
                    name="customer_labour_cost"
                    onChange={handleChange}
                    value={work.customer_labour_cost || ""}
                    required
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  setWork({});
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="danger" fullwidth onClick={onClickAction}>
                Save Work Lookup
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
