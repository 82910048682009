import {
  Control,
  Field,
  Input, Label, Select
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableDropdownCell({
  propName,
  value,
  options,
  onChange,
  onSave,
  editable,
  setEditable,
  inlineEdit
}) {
  if (value && !value.id) {
    value = {id: value}
  }
  return (
    <td onClick={e => e.stopPropagation()}>
      <Field className="has-addons">
        <Control>
          <Select
            onChange={onChange}
            name={propName}
            value={value ? value.id : options[0].id}
            disabled={!editable}
          >
            {options.map(value =>
              <option key={value.id} value={value.id}>
                {value.name}
              </option>
            )}
          </Select>
        </Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable})}
      </Field>
    </td>
  );
}

export default useEditableTableDropdownCell;
