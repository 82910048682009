import { useHttp } from "./useHttp";
import { useDispatch } from "react-redux";

export const useGetAndSet = (resource, params, action, dataFresh, nofetch) => {
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    resource,
    "get",
    params,
    dataFresh,
    nofetch
  );
  if (error) {
    throw Error("There was an error");
  }

  if (!loading && data !== null) {
    dispatch(action(data));
  }

  return !loading;
};
