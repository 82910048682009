import {
  Control,
  Field,
  Input
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableDateCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  disabled,
  inlineEdit,
  monthOnly
}) {
  return (
    <td onClick={e => e.stopPropagation()}>
      <Field className="has-addons">
        <Control className={inlineEdit ? "date-cell" : ""}>
          <Input
            className={`small-row-black ${monthOnly ? 'extra-max-width' : ''}`}
            onChange={onChange}
            type={monthOnly ? 'month': 'date'}
            name={propName}
            value={value || ""}
            disabled={!editable}
          />
        </Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
      </Field>
    </td>
  );
}

export default useEditableTableDateCell;
