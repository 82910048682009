import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useJobsData } from "../../hooks/useJobsData";
import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import Heading from "react-bulma-components/lib/components/heading";
import Table from "react-bulma-components/lib/components/table";
import { getJobsData } from "../../state/selectors";
import Spinner from "react-spinner-material";
import { readableDate } from "../../utils";

function Jobs() {
  const [page, setPage] = useState(1);
  const complete = useJobsData({ view: "today", page: page, ws: "all" });
  const data = useSelector(getJobsData);
  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Heading size={5}>Jobs Starting Today</Heading>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Registration</th>
            <th>Customer</th>
            <th>Customer 2</th>
            <th>Make</th>
            <th>Model</th>
            <th>Book In Date</th>
            <th>Start Date</th>
            <th>Workshop</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map(value => (
            <tr
              className="clickable"
              style={{ fontSize: 12 }}
              onClick={() => window.open(`/editjob/${value.id}`, "_self")}
              key={value.id}
            >
              <td>{value.id}</td>
              <td>{value.vehicle.registration}</td>
              <td>{value.customer.display_name}</td>
              <td>
                {value.customer2 ? value.customer2.display_name : ""}
              </td>
              <td>{value.vehicle.make}</td>
              <td>{value.vehicle.model}</td>
              <td>{readableDate(value.book_in_date)}</td>
              <td>{readableDate(value.start_date)}</td>
              <td>{value.workshop ? value.workshop.display_name : ""}</td>
              <td>{value.comments_list.length > 0 ? value.comments_list[value.comments_list.length -1].text : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        onChange={page => setPage(page)}
        current={page}
        total={Math.ceil(data.count / 25)}
      >
        {" "}
      </Pagination>
    </Box>
  );
}

export default Jobs;
