import React, {useState} from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input,
  Select, Checkbox
} from "react-bulma-components/lib/components/form";
import {formatDate} from "../../utils";
import Button from "react-bulma-components/lib/components/button";
import Collapsible from "react-collapsible";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import Icon from "react-bulma-components/lib/components/icon";
import axios from "axios";
import {createSetVehicleFieldAction} from "../../actions/dataActions";

const AsyncTypeahead = asyncContainer(Typeahead);

function OrderingInfoSection(props) {
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [, setTypeahead] = useState();

  const handleSupplierSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handleCustomerSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=CC&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handleVehicleSupplierSelect = supplier => {
    props.onChange({target: {name: 'vehicle_supplier', value: supplier[0]}})
  };

  const handleReserveCustomerSelect = reserve_customer => {
    props.onChange({target: {name: 'reserve_customer', value: reserve_customer[0]}})
  };

  const onDealerDiscountChange = e => {
    props.onChange(e);
    if ((props.data.manufacturer_discount || props.data.manufacturer_discount === 0) && props.data.retail_price) {
      const newPurchasePrice = (props.data.retail_price - ((1 * e.target.value + 1 * props.data.manufacturer_discount) / 100) * props.data.retail_price) + 1 * (props.data.body_aux_price | 0) + 1 * (props.data.delivery_charge | 0) + 1 * (props.data.extra_cost | 0)  ;
      props.onChange({target: {name: 'ordered_purchase_price', value: newPurchasePrice}})
    }
  };

  const onManufacturerDiscountChange = e => {
    props.onChange(e);
    if ((props.data.dealer_discount || props.data.dealer_discount === 0) && props.data.retail_price) {
      const newPurchasePrice = (props.data.retail_price - ((1 * e.target.value + 1 * props.data.dealer_discount) / 100) * props.data.retail_price)  + 1 * (props.data.body_aux_price | 0) + 1 * (props.data.delivery_charge | 0) + 1 * (props.data.extra_cost | 0);
      props.onChange({target: {name: 'ordered_purchase_price', value: newPurchasePrice}})
    }
  };

  const onRetailPriceChange = e => {
    props.onChange(e);
    if ((props.data.dealer_discount || props.data.dealer_discount === 0) && (props.data.manufacturer_discount || props.data.manufacturer_discount === 0) && props.data.retail_price) {
      const newPurchasePrice = (e.target.value - ((1 * props.data.manufacturer_discount + 1 * props.data.dealer_discount) / 100) * e.target.value)  + 1 * (props.data.body_aux_price | 0) + 1 * (props.data.delivery_charge | 0) + 1 * (props.data.extra_cost | 0);
      props.onChange({target: {name: 'ordered_purchase_price', value: newPurchasePrice}})
    }
  };

  const onCalcChange = e => {
    const body_aux_price = e.target.name === "body_aux_price" ? e.target.value : props.data.body_aux_price;
    const delivery_charge = e.target.name === "delivery_charge" ? e.target.value : props.data.delivery_charge;
    const extra_cost = e.target.name === "extra_cost" ? e.target.value : props.data.extra_cost;
    props.onChange(e);
    if ((props.data.dealer_discount || props.data.dealer_discount === 0) && (props.data.manufacturer_discount || props.data.manufacturer_discount === 0) && props.data.retail_price) {
      const newPurchasePrice = (props.data.retail_price - ((1 * props.data.dealer_discount + 1 * props.data.manufacturer_discount) / 100) * props.data.retail_price) + 1 * (body_aux_price | 0) + 1 * (delivery_charge | 0) + 1 * (extra_cost | 0)  ;
      props.onChange({target: {name: 'ordered_purchase_price', value: newPurchasePrice}})
    }

  };

  const handleCheckbox = e => {
    const {name, checked} = e.target;
    props.onChange({target: {name, value: checked}});
  };

  return (<Box>
    <Collapsible
      className="title"
      triggerOpenedClassName="title"
      trigger="Ordering Info"
    >
      <div>
        <Columns>

          <Columns.Column>
            <Field>
              <Label>Retail Price</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="retail_price"
                  onChange={onRetailPriceChange}
                  value={props.data.retail_price}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>

          <Columns.Column>
            <Field>
              <Label>Dealer Discount (%)</Label>
              <Control>
                <Input
                  type="number"
                  name="dealer_discount"
                  onChange={onDealerDiscountChange}
                  value={props.data.dealer_discount}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Manufacturer Discount (%)</Label>
              <Control>
                <Input
                  type="number"
                  name="manufacturer_discount"
                  onChange={onManufacturerDiscountChange}
                  value={props.data.manufacturer_discount}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Body/Auxiliaries Price</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="body_aux_price"
                  onChange={onCalcChange}
                  value={props.data.body_aux_price}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Delivery Charge</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="delivery_charge"
                  onChange={onCalcChange}
                  value={props.data.delivery_charge}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Extra Costs</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="extra_cost"
                  onChange={onCalcChange}
                  value={props.data.extra_cost}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Spec</Label>
              <Control>
                <Input
                  type="text"
                  name="spec"
                  onChange={props.onChange}
                  value={props.data.spec}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Ordered Date</Label>
                <Input
                  type="date"
                  name="ordered_date"
                  onChange={props.onChange}
                  value={String(props.data.ordered_date)}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Order Number</Label>
              <Control>
                <Input
                  type="text"
                  name="order_number"
                  onChange={props.onChange}
                  value={props.data.order_number}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Deposit to be paid</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="deposit_to_be_paid"
                  onChange={props.onChange}
                  value={props.data.deposit_to_be_paid}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Deposit Allocated Date</Label>
                <Input
                  type="date"
                  name="deposit_allocated_date"
                  onChange={props.onChange}
                  value={String(props.data.deposit_allocated_date)}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Month Due</Label>
                <Input
                  type="month"
                  name="month_due"
                  onChange={props.onChange}
                  value={String(props.data.month_due)}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Delivery Confirmed</Label>
                <Checkbox
                  type="checkbox"
                  name="delivery_date_confirmed"
                  onChange={handleCheckbox}
                  checked={props.data.delivery_date_confirmed}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Delivery Date</Label>
                <Input
                  type="date"
                  name="delivery_date"
                  onChange={props.onChange}
                  value={String(props.data.delivery_date)}
                />
              </Control>
            </Field>
          </Columns.Column>


          <Columns.Column>
            <Field>
              <Label>Invoice Number</Label>
              <Control>
                <Input
                  type="text"
                  name="purchase_invoice_number"
                  onChange={props.onChange}
                  value={props.data.purchase_invoice_number}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Purchase Price</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="ordered_purchase_price"
                  onChange={props.onChange}
                  value={props.data.ordered_purchase_price}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>

          {/*<Columns.Column>*/}
          {/*  <Field>*/}
          {/*    <Label>Customer Reservation</Label>*/}
          {/*    <Control>*/}
          {/*      <Input*/}
          {/*        type="text"*/}
          {/*        name="customer_reservation"*/}
          {/*        onChange={props.onChange}*/}
          {/*        value={props.data.customer_reservation}*/}
          {/*      />*/}
          {/*    </Control>*/}
          {/*  </Field>*/}
          {/*</Columns.Column>*/}

        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Supplier</Label>
              <Control>
                <AsyncTypeahead
                  id="typeahead"
                  labelKey="display_name"
                  minLength={2}
                  name={"vehicle_supplier"}
                  onSearch={handleSupplierSearch}
                  onChange={handleVehicleSupplierSelect}
                  placeholder={"Search Supplier"}
                  options={searchData}
                  ref={typeahead => setTypeahead(typeahead)}
                  className="typeahead"
                  isLoading={isLoading}
                  selected={props.data.vehicle_supplier && props.data.vehicle_supplier.id ? [props.data.vehicle_supplier] : []}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>DEPR</Label>
              <Control>
                <Input
                  type="text"
                  name="depr"
                  onChange={props.onChange}
                  value={props.data.depr}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Status</Label>
              <Control>
                <Input
                  type="text"
                  name="status"
                  onChange={props.onChange}
                  value={props.data.status}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Reserve Customer</Label>
              <Control>
                <AsyncTypeahead
                  id="typeahead"
                  labelKey="display_name"
                  minLength={2}
                  name={"reserve_customer"}
                  onSearch={handleCustomerSearch}
                  onChange={handleReserveCustomerSelect}
                  placeholder={"Search Customer"}
                  options={searchData}
                  ref={typeahead => setTypeahead(typeahead)}
                  className="typeahead"
                  isLoading={isLoading}
                  selected={props.data.reserve_customer && props.data.reserve_customer.id ? [props.data.reserve_customer] : []}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Exchange Vehicle</Label>
              <Control>
                <Input
                  type="text"
                  name="exchange_vehicle"
                  onChange={props.onChange}
                  value={props.data.exchange_vehicle}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Extras</Label>
              <Control>
                <Input
                  type="text"
                  name="extras"
                  onChange={props.onChange}
                  value={props.data.extras}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Comments</Label>
              <Control>
                <Input
                  type="text"
                  name="ordering_comments"
                  onChange={props.onChange}
                  value={props.data.ordering_comments}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </div>
    </Collapsible>
  </Box>);
}

export default OrderingInfoSection;
