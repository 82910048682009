import {createSetPartLookupDataAction, createSetPartsDataAction} from "../actions/dataActions";
import { useGetAndSet } from "./useGetAndSet";

export const usePartLookupData = (params, dataFresh, nofetch) => {
  return useGetAndSet(
    "partlookup",
    params,
    createSetPartLookupDataAction,
    dataFresh,
    nofetch
  );
};
