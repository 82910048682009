import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import Heading from "react-bulma-components/lib/components/heading";
import Table from "react-bulma-components/lib/components/table";
import {getFinanceData, getVehiclesData} from "../../state/selectors";
import Spinner from "react-spinner-material";
import { useVehiclesData } from "../../hooks/useVehiclesData";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import VehicleSalesRow from "./VehicleSalesRow";
import {useFinanceData} from "../../hooks/useFinanceData";
import Figure from "./Figure";
import Columns from "react-bulma-components/lib/components/columns";

function VehicleSales({ dates, endpoint }) {
  const [page, setPage] = useState(1);
  const complete = useFinanceData({ start: dates.start, end: dates.end, page: page });
  const data = useSelector(getFinanceData);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Heading size={5}>Vehicle Sales</Heading>
      <Columns>
        <Figure
          label="Total Cash Flow"
          loaded={complete}
          value={complete ? data.total_cash_flow : 0}
        />
        <Figure
          label="Total Profit"
          loaded={complete}
          value={complete ? data.total_profit : 0}
        />
      </Columns>
      <Table>
        <thead>
          <tr>
            <th>Registration</th>
            <th>Make</th>
            <th>Model</th>
            <th>Sale Date</th>
            <th>Sold To</th>
            <th>Sale Price</th>
            <th>Profit</th>
            <th>Cash Flow</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((value, index) => (
            <VehicleSalesRow
              key={value.id}
              value={value}
              index={index}
              endpoint={endpoint}
            />
          ))}
        </tbody>
      </Table>
      <Pagination
        onChange={page => setPage(page)}
        current={page}
        total={Math.ceil(data.count / 25)}
      >
        {" "}
      </Pagination>
    </Box>
  );
}

export default VehicleSales;
