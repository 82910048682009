import { createSetVehicleSummaryDataAction } from "../actions/dataActions";
import { useGetAndSet } from "./useGetAndSet";

export const useVehicleSummaryData = params => {
  return useGetAndSet(
    "vehicles/summary",
    params,
    createSetVehicleSummaryDataAction
  );
};
