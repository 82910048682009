import React, { Component } from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import Heading from "react-bulma-components/lib/components/heading";

class OffHireSection extends Component {
  render() {
    return (
      <Box>
        <Heading>Off Hire</Heading>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Off Hire Date</Label>
              <Control>
                <Input
                  type="date"
                  name="off_hire_date"
                  onChange={this.props.onChange}
                  value={this.props.rental.off_hire_date || ""}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>New Mileage</Label>
              <Control>
                <Input
                  type="number"
                  name="off_hire_mileage"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_mileage)}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Mileage Excess (over allowance)</Label>
              <Control>
                <Input
                  type="number"
                  name="off_hire_excess_mileage"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_excess_mileage)}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Missing Fuel</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="off_hire_missing_fuel"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_missing_fuel)}
                />
                <Icon align="left" icon="pound-sign" />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Mileage Excess Cost</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="excess_mileage_charge"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.excess_mileage_charge)}
                  disabled
                  readOnly
                />
                <Icon align="left" icon="pound-sign" />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>New Damage</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="off_hire_new_damage"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_new_damage)}
                />
                <Icon align="left" icon="pound-sign" />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Off Hire Total Additional Charges</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="off_hire_total_additional_charges"
                  value={String(
                    this.props.rental.off_hire_total_additional_charges
                  )}
                  readOnly
                  disabled
                />
                <Icon align="left" icon="pound-sign" />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default OffHireSection;
