import React, { Component } from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import Collapsible from "react-collapsible";

class VehicleRates extends Component {
  render() {
    return (
      <Box>
        <Collapsible
          className="title"
          triggerOpenedClassName="title"
          trigger="Rates"
        >
          <Columns>
            <Columns.Column>
              <Field>
                <Label>Single Day Hire</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="first_day"
                    onChange={this.props.onChange}
                    value={String(this.props.vehicle.first_day)}
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Weekend</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="Weekend"
                    onChange={this.onChange}
                    value={String(
                      (
                        1 * this.props.vehicle.first_day +
                        1 * this.props.vehicle.two_five_days
                      ).toFixed(2)
                    )}
                    disabled
                    readOnly
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>2-5 Day Hire</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="two_five_days"
                    onChange={this.props.onChange}
                    value={String(this.props.vehicle.two_five_days)}
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>1 Week Hire</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="one_week"
                    onChange={this.onChange}
                    value={String(
                      (
                        1 * this.props.vehicle.first_day +
                        this.props.vehicle.two_five_days * 4 +
                        this.props.vehicle.six_twenty_seven_days * 2
                      ).toFixed(2)
                    )}
                    disabled
                    readOnly
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>6-27 Day Hire</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="six_twenty_seven_days"
                    onChange={this.props.onChange}
                    value={String(this.props.vehicle.six_twenty_seven_days)}
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>6-27 Days Weekly Hire</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="six_twenty_seven_week"
                    onChange={this.onChange}
                    value={String(
                      (this.props.vehicle.six_twenty_seven_days * 7).toFixed(2)
                    )}
                    readOnly
                    disabled
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>28 Day + Hire</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="twenty_eight_plus"
                    onChange={this.props.onChange}
                    value={String(this.props.vehicle.twenty_eight_plus)}
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Long Term Weekly</Label>
                <Control iconLeft>
                  <Input
                    type="number"
                    name="long_term_weekly"
                    onChange={this.props.onChange}
                    value={String(this.props.vehicle.long_term_weekly)}
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
        </Collapsible>
      </Box>
    );
  }
}

export default VehicleRates;
