import React, {useState} from "react";
import shortid from "shortid";
import Table from "react-bulma-components/lib/components/table";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Collapsible from "react-collapsible";
import {getPartCustomerCost, readableDate} from "../../utils";

import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Control,
  Input,
  Label,
  Checkbox
} from "react-bulma-components/lib/components/form";
import {Icon} from "react-bulma-components";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Heading from "react-bulma-components/lib/components/heading";
import {getPaymentSchedule, getRemainingInterest} from "../../financeUtils";
import StockingPlanSection from "./StockingPlanSection";

function FinanceSection({updateFinanceAgreements, financeAgreement, vehicle, index, defaultDepreciation, removeFinanceAgreement}) {
  const [capturedEvent, setCapturedEvent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const paymentSchedule = getPaymentSchedule(financeAgreement);
  const [modificationChecked, setModificationChecked] = useState(financeAgreement.modification);

  const onChange = e => {
    const newFinanceAgreement = getNewFinanceAgreement(e);
    updateFinanceAgreements(newFinanceAgreement, index);
  };

  const onConfirmChange = () => {
    let finance_section_reset = {
      vehicle: financeAgreement.vehicle,
      finance_type: capturedEvent.target.value,
      finance_amount_borrowed: 0,
      hp_interest_rate: 0,
      deposit_amount: 0,
      monthly_payment: 0,
      payment_term: 0,
      finance_start_date: null,
      end_date: null,
      agreement_no: "",
      finance_company: ""
    };
    updateFinanceAgreements(finance_section_reset, index);
    setModalOpen(false);
  };

  const getTotalRepayable = () => {
    if (financeAgreement.payment_term && financeAgreement.monthly_payment) {
      if (financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL") {
        return (
          paymentSchedule.reduce((a, b) => a + b.interest_accrued, 0) +
          financeAgreement.payment_term * financeAgreement.monthly_payment
        );
      } else if (financeAgreement.finance_type === "REGULAR") {
        return financeAgreement.payment_term * financeAgreement.monthly_payment;
      }
    }
    return 0;
  };

  const getProfit = () => {
    return (financeAgreement.sold_price - financeAgreement.estimated_value + ((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid)) ? (financeAgreement.sold_price - financeAgreement.estimated_value + ((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid)) : 0;
  };

  const getCashFlow = () => {
    return (financeAgreement.sold_price - financeAgreement.finance_early_settlement_amount_paid) ? (financeAgreement.sold_price - financeAgreement.finance_early_settlement_amount_paid) : 0;
  };

  const getTotalInterest = () => {
    if (
      financeAgreement.payment_term &&
      financeAgreement.monthly_payment &&
      financeAgreement.finance_amount_borrowed
    ) {
      if (financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL" || financeAgreement.finance_type === "STOCKING_PLAN") {
        return paymentSchedule.reduce((a, b) => a + b.interest_accrued, 0);
      } else if (financeAgreement.finance_type === "REGULAR") {
        return (
          financeAgreement.payment_term * financeAgreement.monthly_payment -
          financeAgreement.finance_amount_borrowed
        );
      }
    }
    return 0;
  };

  const onMonthlyInterestChange = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    const annualInterest = e.target.value * 12;
    newFinanceAgreement.hp_interest_rate = annualInterest;

    updateFinanceAgreements(newFinanceAgreement, index)
    // dispatch(createSetVehicleDataAction(newVehicle));
  };

  const onModificationChange = e => {
    const updatedModification = !modificationChecked;
    setModificationChecked(updatedModification);
    updateFinanceAgreements({...financeAgreement, modification: updatedModification}, index);
  }


  const onClick = e => {
    if (financeAgreement.finance_type !== "NONE") {
      setCapturedEvent(e);
      setModalOpen(true);
    } else {
      onChange(e);
    }
  };
  const onPurchasingChange = e => {
    let newAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    if (newAgreement.purchase_price && newAgreement.deposit_amount) {
      newAgreement.finance_amount_borrowed =
        newAgreement.purchase_price - newAgreement.deposit_amount;
    }
    updateFinanceAgreements(newAgreement, index)
  };

  const getNewFinanceAgreement = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    return newFinanceAgreement;
  };

  const onCheck = propName => {
    const newFinanceAgreement = getNewFinanceAgreement({
      target: {
        name: propName,
        value: !financeAgreement[propName]
      }
    });
    updateFinanceAgreements(newFinanceAgreement, index);
  };

  return (
    <Box>
      <Collapsible
        className="title finance-title"
        triggerOpenedClassName="title finance-title"
        trigger={`${financeAgreement.modification ? 'Conversion - ' : ''}${financeAgreement.finance_company ? financeAgreement.finance_company : "No Finance Company Specified"} - ${financeAgreement.finance_start_date ? readableDate(financeAgreement.finance_start_date) : "No Start Date Specified"}`}
        open={index === 0 || !financeAgreement.id}
      >
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Comments</Label>
              <Control>
                <Input
                  type="text"
                  name="finance_comments"
                  onChange={onChange}
                  value={financeAgreement.finance_comments}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Heading size={4}>Purchase Info</Heading>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Sage Asset ID</Label>
              <Control>
                <Input
                  type="text"
                  name="sage_asset_id"
                  onChange={onChange}
                  value={financeAgreement.sage_asset_id}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Sage Liability ID</Label>
              <Control>
                <Input
                  type="text"
                  name="sage_liability_id"
                  onChange={onChange}
                  value={financeAgreement.sage_liability_id}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Department</Label>
              <Control>
                <Input
                  type="text"
                  name="department"
                  onChange={onChange}
                  value={financeAgreement.department}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Purchase Price</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="purchase_price"
                  onChange={onPurchasingChange}
                  value={String(financeAgreement.purchase_price)}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>VAT</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="purchase_price_vat"
                  onChange={onChange}
                  value={String(financeAgreement.purchase_price_vat)}
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Invoice Date</Label>
              <Control>
                <Input
                  type="date"
                  name="initial_valuation_date"
                  onChange={onChange}
                  value={String(financeAgreement.initial_valuation_date)}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Delivery Date (depr. Start)</Label>
              <Control>
                <Input
                  type="date"
                  name="depreciation_start_date"
                  onChange={onChange}
                  value={String(financeAgreement.depreciation_start_date)}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Annual Depreciation (%)</Label>
              <Control>
                <Input
                  type="number"
                  name="annual_depreciation_rate"
                  onChange={onChange}
                  value={
                    financeAgreement.annual_depreciation_rate
                  }
                  placeholder={defaultDepreciation}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>
                Conversion / Modification Financing
              </Label>
              <Control iconLeft>
                <Checkbox 
                  name="modification"
                  onChange={onModificationChange}
                  checked={modificationChecked}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>

        <Columns>
          <Columns.Column>
            <Field className="has-addons">
              <Control>
                <Heading size={4}>Finance Agreement</Heading>
                <Button
                  color={financeAgreement.finance_type === "NONE" ? "primary" : ""}
                  onClick={() =>
                    onClick({
                      target: {name: "finance_type", value: "NONE"}
                    })
                  }
                >
                  None
                </Button>
                <Button
                  color={financeAgreement.finance_type === "REGULAR" ? "primary" : ""}
                  onClick={() =>
                    onClick({
                      target: {name: "finance_type", value: "REGULAR"}
                    })
                  }
                >
                  Fixed Rate
                </Button>
                <Button
                  color={
                    financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL"
                      ? "primary"
                      : ""
                  }
                  onClick={() =>
                    onClick({
                      target: {
                        name: "finance_type",
                        value: "FIXED_MONTHLY_CAPITAL"
                      }
                    })
                  }
                >
                  Variable Rate
                </Button>
                <Button
                  color={
                    financeAgreement.finance_type === "STOCKING_PLAN"
                      ? "primary"
                      : ""
                  }
                  onClick={() =>
                    onClick({
                      target: {
                        name: "finance_type",
                        value: "STOCKING_PLAN"
                      }
                    })
                  }
                >
                  Stocking Plan
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        {financeAgreement.finance_type === "STOCKING_PLAN" &&
        <StockingPlanSection
          updateFinanceAgreements={updateFinanceAgreements}
          financeAgreement={financeAgreement}
          index={index}
        />
        }
        {(financeAgreement.finance_type !== "NONE" && financeAgreement.finance_type != "STOCKING_PLAN") && (
          <div>
            <Columns>
              <Columns.Column>
                <Field>
                  <Label>Deposit</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="deposit_amount"
                      onChange={onPurchasingChange}
                      value={String(financeAgreement.deposit_amount)}
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>Amount Borrowed</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="finance_amount_borrowed"
                      onChange={onChange}
                      value={String(financeAgreement.finance_amount_borrowed)}
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>
                    {financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL" || financeAgreement.finance_type === "STOCKING_PLAN"
                      ? "Monthly Capital Payment"
                      : "Monthly Payment"}
                  </Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="monthly_payment"
                      onChange={onChange}
                      value={String(financeAgreement.monthly_payment)}
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>{financeAgreement.finance_type === "STOCKING_PLAN" ? "Payment Months (exc. balloon payment)" : "Payment Term (Months) "}</Label>
                  <Control>
                    <Input
                      type="number"
                      name="payment_term"
                      onChange={onChange}
                      value={String(financeAgreement.payment_term)}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              {financeAgreement.finance_type === "STOCKING_PLAN" &&
              <Columns.Column>
                <Field>
                  <Label>Agreement Start Date (Free period start)</Label>
                  <Control>
                    <Input
                      type="date"
                      name="sp_start_date"
                      onChange={onChange}
                      value={financeAgreement.sp_start_date || ""}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              }
              <Columns.Column>
                <Field>
                  <Label>{financeAgreement.finance_type === "STOCKING_PLAN" ? "Free Period End (Interest Start)" : "Finance Start Date"}</Label>
                  <Control>
                    <Input
                      type="date"
                      name="finance_start_date"
                      onChange={onChange}
                      value={financeAgreement.finance_start_date || ""}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>Finance End Date</Label>
                  <Control>
                    <Input
                      type="date"
                      name="end_date"
                      onChange={onChange}
                      value={financeAgreement.end_date || ""}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              {financeAgreement.finance_type === "STOCKING_PLAN" &&
              <Columns.Column>
                <Field>
                  <Label>Balloon Payment</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="balloon_payment"
                      onChange={onPurchasingChange}
                      value={String(financeAgreement.balloon_payment)}
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              }
              <Columns.Column>
                <Field>
                  <Label>Finance Company</Label>
                  <Control>
                    <Input
                      type="text"
                      name="finance_company"
                      onChange={onChange}
                      value={financeAgreement.finance_company}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>Agreement Number</Label>
                  <Control>
                    <Input
                      type="text"
                      name="agreement_no"
                      onChange={onChange}
                      value={String(financeAgreement.agreement_no) || ""}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Label>Total Repayable</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="total_repayable"
                      onChange={onChange}
                      value={getTotalRepayable().toFixed(2)}
                      disabled
                      readOnly
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>

              <Columns.Column>
                <Field>
                  <Label>Total Interest Repayable</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="interest_repayable"
                      onChange={onChange}
                      value={getTotalInterest().toFixed(2)}
                      disabled
                      readOnly
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              {
                financeAgreement.finance_type === "STOCKING_PLAN" &&
                <Columns.Column>
                  <Field>
                    <Label>Monthly Interest Rate (%)</Label>
                    <Control>
                      <Input
                        type="number"
                        name="sp_monthly_interest_rate"
                        onChange={onMonthlyInterestChange}
                        value={
                          financeAgreement.sp_monthly_interest_rate ? financeAgreement.sp_monthly_interest_rate : ""
                        }
                      />
                    </Control>
                  </Field>
                </Columns.Column>
              }
              <Columns.Column>
                <Field>
                  <Label>Interest Rate (%)</Label>
                  <Control>
                    <Input
                      type="number"
                      name="hp_interest_rate"
                      onChange={onChange}
                      value={
                        financeAgreement.hp_interest_rate ? financeAgreement.hp_interest_rate : ""
                      }
                      disabled={financeAgreement.finance_type === "REGULAR" || financeAgreement.finance_type === "STOCKING_PLAN"}
                      readOnly={financeAgreement.finance_type === "REGULAR" || financeAgreement.finance_type === "STOCKING_PLAN"}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Label>Finance Settlement Amount Paid</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="finance_early_settlement_amount_paid"
                      onChange={onChange}
                      value={String(
                        financeAgreement.finance_early_settlement_amount_paid || ""
                      )}
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>Finance Early Settlement Date</Label>
                  <Control>
                    <Input
                      type="date"
                      name="finance_early_settlement_date"
                      onChange={onChange}
                      value={financeAgreement.finance_early_settlement_date || ""}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              {financeAgreement.finance_type !== "REGULAR" && (
                <Columns.Column>
                  <Field>
                    <Label>This Months Payment</Label>
                    <Control iconLeft>
                      <Input
                        type="text"
                        value={
                          financeAgreement.this_months_payment
                            ? financeAgreement.this_months_payment
                            : 0.0
                        }
                        disabled
                        readOnly
                      />
                      <Icon align="left" icon="pound-sign"/>
                    </Control>
                  </Field>
                </Columns.Column>
              )}
              <Columns.Column>
                <Field>
                  <Label>Remaining Capital</Label>
                  <Control iconLeft>
                    <Input
                      type="text"
                      value={(financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest).toFixed(2) || 0.0}
                      disabled
                      readOnly
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>Remaining Interest</Label>
                  <Control iconLeft>
                    <Input
                      type="text"
                      value={financeAgreement.hp_remaining_interest.toFixed(2) || 0.0}
                      // value={getRemainingInterest(vehicle, new Date(Date.now()) || 0.0)}
                      disabled
                      readOnly
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>Outstanding Balance</Label>
                  <Control iconLeft>
                    <Input
                      type="text"
                      value={financeAgreement.remaining_balance || 0.0}
                      disabled
                      readOnly
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              {financeAgreement.finance_early_settlement_date &&
              <Columns.Column>
                <Field>
                  <Label>Early Settlement Charges</Label>
                  <Control iconLeft>
                    <Input
                      type="text"
                      value={((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid).toFixed(2) || 0.0}
                      disabled
                      readOnly
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              }
              {financeAgreement.finance_early_settlement_date &&
              <Columns.Column>
                <Field>
                  <Label>Potential Overpayment</Label>
                  <Control iconLeft>
                    <Input
                      type="text"
                      value={
                        ((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid) < (-1 * financeAgreement.monthly_payment) ? (1 * financeAgreement.monthly_payment).toFixed(2) : 0.0.toFixed(2)
                      }
                      disabled
                      readOnly
                    />
                    <Icon align="left" icon="pound-sign"/>
                  </Control>
                </Field>
              </Columns.Column>
              }
            </Columns>
          </div>
        )}
        <Columns>
          <Columns.Column>
            <Heading size={4}>Sale Info</Heading>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>
                Sale Date
              </Label>
              <Control>
                <Input
                  type="date"
                  name="internal_sale_date"
                  onChange={onChange}
                  value={financeAgreement.internal_sale_date || ""}/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>
                Depreciated Value / Cost of Sale
              </Label>
              <Control iconLeft>
                <Input
                  type="text"
                  value={
                    financeAgreement.estimated_value > 0
                      ? financeAgreement.estimated_value.toFixed(2)
                      : 0
                  }
                  disabled
                  readOnly
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Invoice Number</Label>
                <Input
                  type="text"
                  name="internal_invoice_number"
                  onChange={onChange}
                  value={financeAgreement.internal_invoice_number}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Advertised Price</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="external_list_price"
                  onChange={onChange}
                  value={financeAgreement.external_list_price}
                  required
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>

          <Columns.Column>
            <Field>
              <Label>Sold Price</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="sold_price"
                  onChange={onChange}
                  value={financeAgreement.sold_price}
                  required
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Profit</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="profit"
                  value={getProfit().toFixed(2)}
                  disabled
                  readOnly
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Cash Flow</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="sold_price"
                  onChange={onChange}
                  value={getCashFlow().toFixed(2)}
                  disabled
                  readOnly
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>
                Sold Date
              </Label>
              <Control>
                <Input
                  type="date"
                  name="sold_date"
                  onChange={onChange}
                  value={financeAgreement.sold_date || ""}/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Comments</Label>
                <Input
                  type="text"
                  name="sale_comments"
                  onChange={onChange}
                  value={financeAgreement.sale_comments}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Sold To</Label>
                <Input
                  type="text"
                  name="sold_to"
                  onChange={onChange}
                  value={financeAgreement.sold_to}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Button
              color="danger"
              onClick={() => removeFinanceAgreement(index)}
            >
              Remove Purchase/Finance Agreement/Sale Section
            </Button>
          </Columns.Column></Columns>
        <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <Section style={{backgroundColor: "grey"}}>
              <Box>
                <Columns>
                  <Columns.Column>
                    <Heading className="is-centered">Warning</Heading>
                    <Heading size={6}>
                      Are you sure you want to change the finance agreement type?
                    </Heading>
                    <p>
                      Changing the finance agreement type will overwrite the
                      current finance agreement details
                    </p>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <Button color="success" onClick={onConfirmChange}>
                      Continue
                    </Button>
                  </Columns.Column>
                  <Columns.Column>
                    <Button color="danger" onClick={() => setModalOpen(false)}>
                      Cancel
                    </Button>
                  </Columns.Column>
                </Columns>
              </Box>
            </Section>
          </Modal.Content>
        </Modal>
      </Collapsible>
    </Box>
  );
}

export default FinanceSection;
