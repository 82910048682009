import React, { useState } from "react";
import {
  Field,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createSetDataFreshAction } from "../../../actions/dataActions";
import { getDataFresh } from "../../../state/selectors";
import Icon from "react-bulma-components/lib/components/icon";

function VehicleTypeRow({
  vehicleTypeData,
  index,
  onVehicleTypeUpdate,
  endpoint
}) {
  const dispatch = useDispatch();
  const [vehicleType, setVehicleType] = useState(vehicleTypeData);
  const [editable, setEditable] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  const submitUpdate = e => {
    e.stopPropagation();
    const url = endpoint + "vehicletype/" + vehicleType.id;
    const conf = {
      method: "put",
      data: vehicleType,
      url
    };

    axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onChange = e => {
    let newVehicleType = { ...vehicleType };
    newVehicleType[e.target.name] = e.target.value || null;
    setVehicleType(newVehicleType);
    onVehicleTypeUpdate(index, newVehicleType);
  };

  const onEdit = e => {
    e.stopPropagation();
    setEditable(!editable);
  };

  const onDelete = e => {
    const url = endpoint + "vehicletype/" + vehicleType.id;
    const conf = { method: "delete", url };
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  return (
    <tr key={vehicleTypeData.id}>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control>
            <Input
              onChange={onChange}
              type="text"
              name="name"
              value={vehicleType.name}
              disabled={!editable}
            />
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control iconLeft>
            <Input
              onChange={onChange}
              type="text"
              name="special_labour_rate"
              value={
                editable
                  ? vehicleType.special_labour_rate
                  : vehicleType.special_labour_rate
                  ? parseFloat(vehicleType.special_labour_rate).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )
                  : undefined
              }
              disabled={!editable}
            />
            <Icon icon="pound-sign" align="left" />
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td className={"is-narrow"}>
        <Button color={"danger"} onClick={onDelete}>
          Delete X
        </Button>
      </td>
    </tr>
  );
}

export default VehicleTypeRow;
