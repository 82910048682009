import React, { Component, useRef, useState } from "react";

import { Link } from "react-router-dom";

import axios from "axios";
import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import Columns from "react-bulma-components/lib/components/columns";
import Table from "react-bulma-components/lib/components/table";
import Collapsible from "react-collapsible";
import { getQueryString, readableDate } from "../../../utils";
import { useWorkData } from "../../../hooks/useWorkData";
import { useSelector } from "react-redux";
import { getWorkData } from "../../../state/selectors";
import Spinner from "react-spinner-material";
import {
  Checkbox,
  Control,
  Field
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import FAIcon from "../../Icon/FAIcon";
import useSelect from "../../../hooks/useSelect";
import PrintServiceRecord from "../PrintServiceRecord";
import ReactToPrint from "react-to-print";
import WorkTotalsSection from "./WorkTotalsSection";

export default function ServiceRecord({ vehicle, registration }) {
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState({});
  const [filters, setFilters] = useState({});
  const componentRef = useRef();
  let params = { page, vehicle };

  const [chargeableView, chargeableInput] = useSelect({
    label: "View",
    options: [
      { name: "All", value: "all" },
      { name: "Chargeable", value: "chargeable" },
      { name: "Non-Chargeable", value: "nonchargeable" }
    ],
    initialValue: "all",
    testId: "servicerecord-view",
    callback: () => {
      setPage(1);
    }
  });

  if (chargeableView !== "all") {
    params.chargeable = chargeableView;
  }

  params.page_size = 15;

  const complete = useWorkData(params);
  const data = useSelector(getWorkData);

  const onSelectCheck = item => {
    let newSelection = { ...selected };
    if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
      newSelection[item.id.toString()] = item;
    } else {
      delete newSelection[item.id.toString()];
    }
    setSelected(newSelection);
  };

  const clearSelected = () => {
    setSelected({});
  };

  const toggleSelectVisible = () => {
    let newSelected = { ...selected };
    let selectedKeys = Object.keys(newSelected);
    const notPresent = data.results.find(
      part => selectedKeys.indexOf(part.id.toString()) === -1
    );

    if (notPresent) {
      data.results.forEach(item => {
        newSelected[item.id.toString()] = item;
      });
    } else {
      data.results.forEach(item => {
        delete newSelected[item.id.toString()];
      });
    }
    setSelected(newSelected);
  };

  const getRows = (value, index) => {
    let rows = [];
    const firstRow = (
      <tr className="is-selected" key={value.id}>
        <td>{readableDate(value.book_in_date)}</td>
        <td>
          <Link to={"/editjob/" + value.job}>{value.description}</Link>
        </td>
        <td>
          £
          {(value.labour_cost || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </td>
        <td>{value.workshop} </td>
        <td>{value.chargeable ? value.customer2 : value.customer} </td>
        <td></td>
        <td></td>
        <td>Parts Total:</td>
        <td>
          £
          {value.parts
            .reduce((total, part) => total + part.cost, 0)
            .toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
        </td>
        <td>{value.chargeable ? "Yes" : "No"}</td>
        <td onClick={e => e.stopPropagation()}>
          <Field>
            <Control className="table-checkbox">
              <Checkbox
                checked={
                  Object.keys(selected).indexOf(value.id.toString()) !== -1
                }
                type="checkbox"
                onClick={() => onSelectCheck(value)}
                readOnly
              />
            </Control>
          </Field>
        </td>
        <td></td>
      </tr>
    );

    rows.push(firstRow);

    const partRows = value.parts.map(part => {
      return (
        <tr key={part.name + part.id}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{part.name}</td>
          <td>{part.part_number}</td>
          <td>{part.supplier.display_name}</td>
          <td>
            £
            {(part.cost || 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    });

    rows = rows.concat(partRows);
    return rows;
  };

  if (!complete) {
    return (
      <div className="spinner-centre">
        <Spinner
          className="spinner-centre"
          radius={120}
          color={"#3273dc"}
          stroke={5}
        />
      </div>
    );
  }

  return (
    <Box>
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Service Record"
      >
        <Columns>
          <Columns.Column>{chargeableInput}</Columns.Column>
        </Columns>
        <WorkTotalsSection workData={data} />
        <Table striped={false} className="is-hoverable">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Labour Cost</th>
              <th>Workshop</th>
              <th>Customer</th>
              <th>Part Name</th>
              <th>Part Number</th>
              <th>Supplier</th>
              <th>Part Cost</th>
              <th>Chargeable</th>
              <th>
                <Button
                  onClick={toggleSelectVisible}
                  color={
                    data.results.find(
                      workItem =>
                        Object.keys(selected).indexOf(
                          workItem.id.toString()
                        ) === -1
                    )
                      ? ""
                      : "danger"
                  }
                >
                  {data.results.find(
                    workItem =>
                      Object.keys(selected).indexOf(workItem.id.toString()) ===
                      -1
                  ) ? (
                    <div>
                      <FAIcon size="small" icon={["fas", "check"]} />
                      <span>Visible</span>
                    </div>
                  ) : (
                    <div>
                      <FAIcon size="small" icon={["fas", "times"]} />
                      <span>Visible</span>
                    </div>
                  )}
                </Button>
              </th>
              {(!!Object.entries(selected).length && (
                <th>
                  <Button onClick={() => setSelected({})} color="danger">
                    <FAIcon size="small" icon={["fas", "times"]} />
                  </Button>
                </th>
              )) || <th></th>}
            </tr>
          </thead>
          <tbody>{data.results.map(getRows)}</tbody>
        </Table>
        <Pagination
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 15)}
        />
        <ReactToPrint
          trigger={() => (
            <Button color={Object.entries(selected).length ? "success" : ""}>
              Print Selected
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Collapsible>
      <div style={{ display: "none" }}>
        <PrintServiceRecord
          data={selected}
          ref={componentRef}
          registration={registration}
        />
      </div>
    </Box>
  );
}
