import { useHttp } from "./useHttp";
import { useDispatch } from "react-redux";
import {
  createSetTransactionDataAction,
  createSetWeeklyTransactionDataAction
} from "../actions/dataActions";

export const useTransactionData = (params, dataFresh) => {
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    "transactions",
    "get",
    params,
    dataFresh
  );
  if (error) {
    throw Error("There was an error");
  }

  if (!loading && data !== null) {
    if (params.view === "repeating") {
      dispatch(createSetWeeklyTransactionDataAction(data));
    } else {
      dispatch(createSetTransactionDataAction(data));
    }
  }

  return !loading;
};
