import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSetVehicleTypesDataAction } from "../../../actions/dataActions";
import { getDataFresh, getVehicleTypesData } from "../../../state/selectors";
import Spinner from "react-spinner-material";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Table from "react-bulma-components/lib/components/table";
import Pagination from "react-bulma-components/lib/components/pagination";
import { useVehicleTypeData } from "../../../hooks/useVehicleTypeData";
import NewVehicleType from "./NewVehicleType";
import VehicleTypeRow from "./VehicleTypeRow";

function VehicleTypeView(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);

  const complete = useVehicleTypeData({ page }, dataFresh);

  const onVehicleTypeUpdate = (index, newVehicleType) => {
    let newState = data;
    newState.results[index] = newVehicleType;
    dispatch(createSetVehicleTypesDataAction(newState));
  };

  const data = useSelector(getVehicleTypesData);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <div>
      <NewVehicleType />
      <Box>
        <Heading size={5}>Vehicle Types</Heading>
        <Heading size={6}>
          Note: Modifying these values will update the vehicle types of existing
          vehicles!
        </Heading>
        <Table>
          <thead>
            <tr>
              <th>Vehicle Type</th>
              <th>Special Labour Rate</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <VehicleTypeRow
                key={value.id}
                vehicleTypeData={value}
                index={index}
                endpoint={props.endpoint}
                onVehicleTypeUpdate={onVehicleTypeUpdate}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        >
          {" "}
        </Pagination>
      </Box>
    </div>
  );
}

export default VehicleTypeView;
