import TargetView from "./TargetView";
import InspectionTypeView from "./InspectionTypes/InspectionTypeView";
import VehicleTypeView from "./VehicleTypes/VehicleTypeView";
import Defaults from "./Defaults";
import React from "react";
import { useDefaultsData } from "../../hooks/useDefaultsData";
import { useSelector } from "react-redux";
import { getAppDefaultsData } from "../../state/selectors";
import TransactionsSettings from "./Transactions/TransactionsSettings";
import CostCentreView from "./CostCentres/CostCentreView";
import QuickLinkView from "./QuickLinks/QuickLinkView";

function Settings(props) {
  const appDefaultsComplete = useDefaultsData({}, 0);
  const appDefaults = useSelector(getAppDefaultsData);
  document.title = "Rentals Direct App";

  return (
    <div>
      <TargetView />
      <VehicleTypeView {...props} />
      <TransactionsSettings {...props} />
      <InspectionTypeView {...props} />
      <CostCentreView {...props} />
      <QuickLinkView {...props} />
      {appDefaultsComplete  && (
        <Defaults {...props} appDefaultsData={appDefaults} />
      )}
    </div>
  );
}

export default Settings;
