import Figure from "./Figure";
import Columns from "react-bulma-components/lib/components/columns";
import React from "react";

function TransactionsSummary({category, loaded, hideIncome, hideExpense}) {
  return (
    <React.Fragment>
      {!hideIncome &&
      <Figure
        label={`${category.name} Income`}
        loaded={loaded}
        value={
          loaded ? category.in : 0
        }
      />
      }
      {!hideExpense &&
      <Figure
        label={`${category.name} Expenses`}
        loaded={loaded}
        value={
          loaded ? category.out : 0
        }
      />
      }
    </React.Fragment>)
}

export default TransactionsSummary;
