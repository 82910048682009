import React from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import { Field } from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";

export default function ModifyQuoteModal({ open, setOpen, onAction }) {
  const onClickAction = () => {
    onAction();
    setOpen(false);
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">
            Are you sure you want to modify the original quote?
          </Heading>
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="danger" fullwidth onClick={onClickAction}>
                Confirm
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
