import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import React from "react";

export default function Customer({ contact, handleChange, handleCheckbox }) {
  return (
    <Box>
      <h1 className="title">Customer Details</h1>
      <Columns>
        {/*<Columns.Column>*/}
        {/*  <Field>*/}
        {/*    <Label className="label">Labour Rate</Label>*/}
        {/*    <Control iconLeft>*/}
        {/*      <Input*/}
        {/*        type="number"*/}
        {/*        name="labour_rate"*/}
        {/*        onChange={handleChange}*/}
        {/*        value={String(contact.labour_rate)}*/}
        {/*        required*/}
        {/*      />*/}
        {/*      <Icon align="left" icon="pound-sign" />*/}
        {/*    </Control>*/}
        {/*  </Field>*/}
        {/*</Columns.Column>*/}
        <Columns.Column>
          <Field>
            <Label className="label">Labour Markup (%)</Label>
            <Control>
              <Input
                type="number"
                name="labour_rate_markup"
                onChange={handleChange}
                value={String(contact.labour_rate_markup)}
                required
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label className="label">Parts Markup (%)</Label>
            <Control>
              <Input
                type="number"
                name="parts_markup"
                onChange={handleChange}
                value={String(contact.parts_markup)}
                required
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Weekly Rentals Target</Label>
            <Control iconLeft>
              <Input
                type="number"
                name="weekly_target"
                onChange={handleChange}
                value={String(contact.weekly_target)}
              />
              <Icon align="left" icon="pound-sign" />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Display Cost Summary on Jobs Page</Label>
            <input
              className="checkbox"
              type="checkbox"
              name="display_job_cost_summary"
              onChange={handleCheckbox}
              checked={contact.display_job_cost_summary}
            />
          </Field>
        </Columns.Column>
      </Columns>
    </Box>
  );
}
