import React from "react";
import {readableDate} from "../../../../utils";

function ForSaleRow({value, finance, activeTab}) {
  return (
    <tr
      className="clickable"
      onClick={() => window.open("/editvehicle/" + value.id, "_self")}
      key={value.id}
    >
      <td>
    <span className="registration-field">
    {value.registration}
    </span>
      </td>
      {activeTab === 'fleet' &&
      <td>{value.fleet_number}</td>
      }
      {activeTab === 'customer' &&

      <td>{value.owner ? value.owner.display_name : "None Set"}</td>
      }
      <td>{value.make}</td>
      <td>{value.model}</td>
      <td>{value.vin_number}</td>
      <td>{value.type ? value.type.name : ""}</td>
      <td>{finance.sale_comments}</td>
      <td>{finance.sold_to}</td>
      <td>{readableDate(finance.sold_date)}</td>
      <td>£{finance.sold_price ? parseFloat(finance.sold_price).toFixed(2) : 0.00}</td>
       <td>
        £
        {!finance.finance_early_settlement_date && finance.remaining_balance
          ? parseFloat(finance.remaining_balance - finance.hp_remaining_interest).toFixed(2)
          : 0.00
        }
      </td>
      <td>
        £
        {typeof finance.estimated_value === "string"
          ? finance.estimated_value
          : (finance.estimated_value || 0).toFixed(2)}
      </td>
      <td>£{finance.external_list_price ? parseFloat(finance.external_list_price).toFixed(2)
        : 0.00}</td>
    </tr>
  )
}

export default ForSaleRow;
