import Button from "react-bulma-components/lib/components/button";
import React, {useState} from "react";
import axios from "axios";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../../state/selectors";
import {Control, Field, Input} from "react-bulma-components/lib/components/form";

function InspectionTypeRow({inspectionTypeData, index, onInspectionTypeUpdate, endpoint}) {
  const dispatch = useDispatch();
  const [inspectionType, setInspectionType] = useState(inspectionTypeData);
  const [editable, setEditable] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  const submitUpdate = e => {
    e.stopPropagation();
    const url = endpoint + "inspectiontype/" + inspectionType.id;
    const conf = {
      method: "put",
      data: inspectionType,
      url
    };

    axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onDelete = e => {
    const url = endpoint + "inspectiontype/" + inspectionType.id;
    const conf = { method: "delete", url };
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  const onChange = e => {
    let newInspectionType = { ...inspectionType };
    newInspectionType[e.target.name] = e.target.value || null;
    setInspectionType(newInspectionType);
    onInspectionTypeUpdate(index, newInspectionType);
  };

  const onEdit = e => {
    e.stopPropagation();
    setEditable(!editable);
  };

  return (
    <tr style={{ fontSize: 12 }} key={inspectionTypeData.id}>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control>
            <Input
              onChange={onChange}
              type="text"
              name="inspection_type"
              value={inspectionType.inspection_type}
              disabled={!editable}
            />
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control>
            <Input
              onChange={onChange}
              type="number"
              name="reminder_days"
              value={inspectionType.reminder_days}
              disabled={!editable}
            />
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td className={"is-narrow"}>
        <Button
          color={"danger"}
          onClick={() => {
            onDelete(inspectionTypeData.id);
          }}
        >
          Delete X
        </Button>
      </td>
    </tr>
  )
}

export default InspectionTypeRow;
