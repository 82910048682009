import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import React from "react";

export default function Supplier({ contact, handleChange, handleCheckbox }) {
  return (
    <Box>
      <h1 className="title">Supplier Details</h1>
      <Columns>
        <Columns.Column>
          <Field>
            <Label>Supplier Labour Rate</Label>
            <Control iconLeft>
              <Input
                type="number"
                name="supplier_labour_charge"
                onChange={handleChange}
                value={String(contact.supplier_labour_charge)}
              />
              <Icon align="left" icon="pound-sign" />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Supplier LCV Labour Rate</Label>
            <Control iconLeft>
              <Input
                type="number"
                name="lcv_rate"
                onChange={handleChange}
                value={String(contact.lcv_rate)}
              />
              <Icon align="left" icon="pound-sign" />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Labour Rate Affected By Customer Markup %</Label>
            <input
              className="checkbox"
              type="checkbox"
              name="labour_rate_affected_by_customer_markup"
              onChange={handleCheckbox}
              checked={contact.labour_rate_affected_by_customer_markup}
            />
          </Field>
        </Columns.Column>
      </Columns>
    </Box>
  );
}
