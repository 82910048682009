import React from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import { Field } from "react-bulma-components/lib/components/form";
import useInput from "../../hooks/useInput";
import useTypeaheadInput from "../../hooks/useTypeaheadInput";

export default function MultipleEditModal({
  open,
  setOpen,
  selected,
  saveSelected
}) {
  const [paymentDate, paymentDateInput, setPaymentDate] = useInput({
    type: "date",
    label: "Payment Date"
  });
  const [dateOrdered, dateOrderedInput, setDateOrdered] = useInput({
    type: "date",
    label: "Date Ordered"
  });
  const [invoiceNumber, invoiceNumberInput, setInvoiceNumber] = useInput({
    type: "text",
    label: "Invoice Number"
  });
  const [supplier, supplierInput, setSupplier] = useTypeaheadInput({
    label: "Supplier",
    placeholder: "Search Supplier",
    searchUrl: "/api/contacts?contact_type=SP"
  });


  const onSave = () => {
    saveSelected(paymentDate, invoiceNumber, dateOrdered, supplier).then(onClose);
  };

  const onClose = () => {
    setOpen(false);
    setInvoiceNumber("");
    setDateOrdered("");
    setPaymentDate("");
    setSupplier("");
  };

  return (
    <Modal closeOnBlur show={open} onClose={onClose} size={"large"}>
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Columns>
            {supplierInput}
            {dateOrderedInput}
            {invoiceNumberInput}
            {paymentDateInput}
          </Columns>
          <Field>
            <p>You are editing {Object.keys(selected).length} parts</p>
          </Field>
          <Button color="success" onClick={onSave}>
            Save
          </Button>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
