import React, { Component } from "react";

import axios from "axios";
import Tabs from "react-bulma-components/lib/components/tabs";
import DailyTable from "./DailyTable";
import WeeklyTable from "./WeeklyTable";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Select,
  Label,
  Control,
  Field,
  Input
} from "react-bulma-components/lib/components/form";
import ContactFilter from "../Invoicing/ContactFilter";
import {
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getQueryString,
  formatDate
} from "../../utils";

export default class Rental extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      rentals_total_cost: 0,
      results: [],
      count: 0,
      next: "",
      previous: "",
      current: 1,
      search_text: "",
      current_search: "",
      rental_type: "",
      day: formatDate(new Date()),
      searching: false,
      start_date: getFirstDayOfWeek(new Date()),
      finish_date: getLastDayOfWeek(getFirstDayOfWeek(new Date()))
    };

    this.loadRentals = this.loadRentals.bind(this);
    this.updateRentals = this.updateRentals.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadRentals();
  }

  updateRentals(rentals) {
    this.setState({ results: rentals });
  }

  changeView = view => {
    this.setState({ activeView: view });
  };

  handleFilter = customer => {
    this.setState(
      {
        customer: customer ? customer.id : undefined,
        customer_target: customer ? customer.weekly_target : undefined,
        customer_name: customer ? customer.display_name : undefined
      },
      this.loadRentals
    );
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.loadRentals(this.state.day)
    );
  };

  onWeekSelect = day => {
    let start = getFirstDayOfWeek(day);
    let finish_date = getLastDayOfWeek(start);
    this.setState(
      {
        start_date: start,
        finish_date: finish_date,
        day: formatDate(day)
      },
      this.loadRentals
    );
  };

  onDaySelect = date => {
    this.setState({ day: formatDate(date) }, this.loadRentals);
  };

  handleDateChange = e => {
    let date = new Date(e.target.value);
    if (this.state.activeView === "weekly")
      return this.onWeekSelect(new Date(date));
    if (this.state.activeView === "daily")
      return this.onDaySelect(new Date(date));
  };

  getParams = () => {
    let params = {};
    if (this.state.rental_type.length)
      params.rental_type = this.state.rental_type;

    if (this.state.activeView === "weekly") {
      params.start_date = formatDate(this.state.start_date);
      params.finish_date = formatDate(this.state.finish_date);
    } else if (this.state.activeView === "daily") params.day = this.state.day;

    if (this.state.customer) params.customer = this.state.customer;

    return params;
  };

  async loadRentals() {
    const queryString = getQueryString(this.getParams());
    const promise = await axios.get(`/api/rentals${queryString}`);
    const status = promise.status;
    if (status === 200) {
      const data = promise.data;
      this.setState(data);
    }
  }

  Daily = () => (
    <div>
      <DailyTable
        rentals={this.state.results}
        current={this.state.current}
        count={this.state.count}
        onPageChange={this.onPageChange}
      />
    </div>
  );

  Weekly = () => (
    <div>
      <WeeklyTable
        rentals={this.state.results}
        current={this.state.current}
        count={this.state.count}
        startDate={this.state.start_date}
        onPageChange={this.onPageChange}
        formatDate={formatDate}
        customerTarget={this.state.customer_target}
        customerName={this.state.customer_name}
      />
    </div>
  );


  render() {
    document.title = "Rentals";
    return (
      <div>
        <Box>
          <Button
            color="success"
            fullwidth
            onClick={() => this.props.history.push("/editrental")}
          >
            New Rental +
          </Button>
        </Box>
        <Box>
          <Tabs type="toggle" fullwidth align="centered">
            <Tabs.Tab
              active={this.state.activeView === "daily"}
              onClick={() => this.changeView("daily")}
            >
              Daily
            </Tabs.Tab>
            <Tabs.Tab
              active={this.state.activeView === "weekly"}
              onClick={() => this.changeView("weekly")}
            >
              Weekly
            </Tabs.Tab>
          </Tabs>
          {!!this.state.activeView && (
            <div>
              <Columns>
                <Columns.Column>
                  <Columns>
                    <Columns.Column>
                      <Label>Select a Date</Label>
                      <Field className="contact-section has-addons">
                        <Control>
                          <Input
                            type="date"
                            value={this.state.day}
                            name="day"
                            onChange={this.handleDateChange}
                          />
                        </Control>
                      </Field>
                    </Columns.Column>
                    <Columns.Column>
                      <Label>Rental Types</Label>
                      <Select
                        onChange={this.onChange}
                        name="rental_type"
                        value={this.state.rental_type}
                      >
                        <option value="">All</option>
                        <option value="LT">Long Term</option>
                        <option value="ST">Short Term</option>
                      </Select>
                    </Columns.Column>
                    <Columns.Column>
                      <Label>Customer</Label>
                      <ContactFilter
                        type="CC"
                        contactUpdate={this.handleFilter}
                        endpoint={this.props.endpoint}
                      />
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
              </Columns>
            </div>
          )}
        </Box>
        {this.state.activeView === "weekly" && <this.Weekly />}
        {this.state.activeView === "daily" && <this.Daily />}
      </div>
    );
  }
}
