import React, { Component } from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import Icon from "react-bulma-components/lib/components/icon";

class RatesSection extends Component {
  state = { total: 0 };

  day = 1000 * 24 * 60 * 60;

  render(props) {
    return (
      <Box>
        <Heading>Rates</Heading>
        <Field className="has-addons">
          <Control>
            <Button
              color={this.props.rental.rental_type === "LT" ? "primary" : ""}
              onClick={() =>
                this.props.onChange({
                  target: { name: "rental_type", value: "LT" }
                })
              }
            >
              Long Term Hire
            </Button>
            <Button
              color={this.props.rental.rental_type === "ST" ? "primary" : ""}
              onClick={() =>
                this.props.onChange({
                  target: { name: "rental_type", value: "ST" }
                })
              }
            >
              Short Term Hire
            </Button>
          </Control>
        </Field>
        {this.props.rental.rental_type === "ST" && (
          <div>
            <Columns>
              <Columns.Column>
                <Field>
                  <Label>Single Day Hire</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="first_day"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.first_day)}
                    />
                    <Icon icon="pound-sign" align="left" />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>Weekend</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="Weekend"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.two_five_days * 2)}
                      disabled
                      readOnly
                    />
                    <Icon icon="pound-sign" align="left" />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Label>2-5 Day Hire</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="two_five_days"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.two_five_days)}
                    />
                    <Icon icon="pound-sign" align="left" />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>1 Week Hire</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="one_week"
                      onChange={this.props.onChange}
                      value={String(
                        1 * this.props.rental.first_day +
                          this.props.rental.two_five_days * 4 +
                          this.props.rental.six_twenty_seven_days * 2
                      )}
                      disabled
                      readOnly
                    />
                    <Icon icon="pound-sign" align="left" />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Label>6-27 Day Hire</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="six_twenty_seven_days"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.six_twenty_seven_days)}
                    />
                    <Icon icon="pound-sign" align="left" />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Label>6-27 Days Weekly Hire</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="six_twenty_seven_week"
                      onChange={this.props.onChange}
                      value={String(
                        this.props.rental.six_twenty_seven_days * 7
                      )}
                      readOnly
                      disabled
                    />
                    <Icon icon="pound-sign" align="left" />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Label>28 Day + Hire</Label>
                  <Control iconLeft>
                    <Input
                      type="number"
                      name="twenty_eight_plus"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.twenty_eight_plus)}
                    />
                  </Control>
                  <Icon icon="pound-sign" align="left" />
                </Field>
              </Columns.Column>
            </Columns>
          </div>
        )}
        {this.props.rental.rental_type === "LT" && (
          <div>
            <Columns>
              <Columns.Column>
                <Field>
                  <Field>
                    <Label>Long Term Weekly</Label>
                    <Control iconLeft>
                      <Input
                        type="number"
                        name="long_term_weekly"
                        onChange={this.props.onChange}
                        value={String(this.props.rental.long_term_weekly)}
                      />
                      <Icon icon="pound-sign" align="left" />
                    </Control>
                  </Field>
                </Field>
              </Columns.Column>
            </Columns>
          </div>
        )}
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Additional Costs</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="additional_cost"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.additional_cost)}
                />
                <Icon icon="pound-sign" align="left" />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Excess Mileage Cost (£ per mile)</Label>
              <Control iconLeft>
                <Input
                  name="excess_mileage_charge_per_mile"
                  type="number"
                  value={String(
                    this.props.rental.excess_mileage_charge_per_mile
                  )}
                  onChange={this.props.onChange}
                />
                <Icon icon="pound-sign" align="left" />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Total</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  value={String(this.props.rental.total)}
                  disabled
                  readOnly
                />
                <Icon icon="pound-sign" align="left" />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default RatesSection;
