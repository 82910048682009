import { NotificationManager } from "react-notifications";

/**
 * @return {Object}
 */
function Validator(job, notify) {
  const required = {department: 'Department'};

  const requiredNested = {
    vehicle: "registration",
    customer: "id"
  };

  let missing = [];
  let missingPartSuppliers = [];

  for (let field in required) {
    if (!job[field] || job[field].length === 0) {
      missing.push(required[field]);
    }
  }

  for (let field in requiredNested) {
    if (
      !job[field] ||
      !job[field][requiredNested[field]] ||
      job[field][requiredNested[field]].length === 0
    ) {
      missing.push(field);
    }
  }

  if (notify) {
    missing.forEach(name => {
      NotificationManager.warning(
        `You must specify a ${name}`,
        "Missing Data",
        10000
      );
    });

    missingPartSuppliers.forEach(name => {
      NotificationManager.warning(
        `Part ${name} is missing a supplier!`,
        "Missing Data",
        10000
      );
    });
  }

  job.estimated_work_items.forEach(item => {
    item.parts.forEach(part => {
      if (!part.supplier || !part.supplier.id) {
        missingPartSuppliers.push(part.name || "Unnamed Part");
      }
    });
  });

  return {
    missingPartSuppliers: missingPartSuppliers,
    missing: missing,
    result: missing.length === 0 && missingPartSuppliers.length === 0
  };
}

export default Validator;
