import {
  Input,
  Label,
  Control,
  Field
} from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import React, { useState } from "react";
import { formatDate, getFirstDayOfWeek } from "../utils";

function useWeekInput({ label, initialValue, weekly, disabled }) {
  const [value, setValue] = useState(formatDate(initialValue) || "");
  const setDate = date => {
    // const selected = new Date(date);
    // const value = weekly && selected ? formatDate(getFirstDayOfWeek(selected)) : date;
    // setValue(formatDate(value));
    // setValue(value);
  };
  const input = (
    <Columns.Column>
      <Field>
        <Label>{label}</Label>
        <Control>
          <Input
            value={value}
            onChange={e => setValue(e.target.value)}
            type={"date"}
            disabled={disabled}
          />
        </Control>
      </Field>
    </Columns.Column>
  );
  return [value, input, setValue];
}

export default useWeekInput;
