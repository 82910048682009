import VehicleTypeView from "../VehicleTypes/VehicleTypeView";
import React from "react";
import { useSelector } from "react-redux";
import TransactionCategoryView from "./TransactionCategories/TransactionCategoryView";
import TransactionReportingCategoryView from "./TransactionReportingCategories/TransactionReportingCategoryView";
import {useTransactionReportingCategoryData} from "../../../hooks/useTransactionReportingCategoryData";
import {getDataFresh, getTransactionReportingCategoriesData} from "../../../state/selectors";
import Box from "react-bulma-components/lib/components/box";
import Spinner from "react-spinner-material";

function TransactionsSettings(props) {
  const dataFresh = useSelector(getDataFresh);

  const complete = useTransactionReportingCategoryData({}, dataFresh);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <div>
      <TransactionReportingCategoryView {...props} />
      <TransactionCategoryView {...props} />
    </div>
  );
}

export default TransactionsSettings;
