import { useState } from "react";
import React from "react";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Label,
  Field,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Jobs from "./Jobs";
import Rentals from "./Rentals";
import Figures from "./Figures";
import { formatDate, getLastDayOfWeek, getFirstDayOfWeek, getFirstDayOfMonth, getLastDayOfMonth } from "../../utils";
import CourtesyCars from "./CourtesyCars";
import Button from "react-bulma-components/lib/components/button";
import VehicleSales from "./VehicleSales";

function Home({ endpoint }) {
  const [start, setStart] = useState(getFirstDayOfWeek(new Date()));
  const [end, setEnd] = useState(getLastDayOfWeek(start));
  const [view, setView] = useState('weekly');

  const onDateSelect = e => {
    view === 'weekly' ? onWeekSelect(e) : onMonthSelect(e)
  };

  const onViewChange = view => {
    setView(view);
    if (view === 'weekly') {
      setStart(getFirstDayOfWeek(start));
      setEnd(getLastDayOfWeek(start))
    } else if (view === 'monthly') {
      setStart(getFirstDayOfMonth(start));
      setEnd(getLastDayOfMonth(start))
    }
  };

  const onMonthSelect = e => {
    let first = getFirstDayOfMonth(new Date(e.target.value));
    let finish_date = getLastDayOfMonth(first);
    setStart(first);
    setEnd(finish_date);
  };

  const onWeekSelect = e => {
    let first = getFirstDayOfWeek(new Date(e.target.value));
    let finish_date = getLastDayOfWeek(first);
    setStart(first);
    setEnd(finish_date);
  };

  let dates = { start: formatDate(start), end: formatDate(end), type: view };

  document.title = process.env.REACT_APP_DOCUMENT_TITLE;

  return (
    <Section>
      <Box>
        <Heading>Summary</Heading>
        <Heading subtitle>
          {start.toDateString()} - {end.toDateString()}
        </Heading>
        <Field className="has-addons">
          <Control>
            <Label>Timeframe</Label>
            <Button
              color={view === 'weekly' ? "primary" : ""}
              onClick={() => onViewChange('weekly')}
            >
              Weekly
            </Button>
            <Button
              color={view ==='monthly' ? "primary" : ""}
              onClick={() => onViewChange('monthly')}
            >
              Monthly
            </Button>
          </Control>
        </Field>
        <Label>Select a Date</Label>
        <Field className="contact-section has-addons">
          <Control>
            <Input
              type="date"
              value={formatDate(start)}
              name="day"
              onChange={onDateSelect}
            />
          </Control>
        </Field>
      </Box>
      <Figures dates={dates} />
      <Columns>
        <Columns.Column>
          <Jobs />
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <VehicleSales dates={dates} endpoint={endpoint}/>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <CourtesyCars endpoint={endpoint} />
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <Rentals dates={dates} />
        </Columns.Column>
      </Columns>
    </Section>
  );
}
export default Home;
