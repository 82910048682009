import Section from "react-bulma-components/lib/components/section";
import {
  Control,
  Field,
  Input,
  Label,
  Select
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import React, { useRef, useState } from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Table from "react-bulma-components/lib/components/table";
import Box from "react-bulma-components/lib/components/box";
import { useSelector } from "react-redux";
import { getPartsData } from "../../state/selectors";
import { usePartsData } from "../../hooks/usePartsData";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import Pagination from "react-bulma-components/lib/components/pagination";
import { readableDate } from "../../utils";
import ReactToPrint from "react-to-print";
import PrintQuote from "./PrintQuote";

export default function PartSearchModal({ open, setOpen, data }) {
  const componentRef = useRef();
  const [chargeableSelected, setChargeableSelected] = useState(true);

  return (
    <Modal closeOnBlur show={open} onClose={() => setOpen(false)}>
      <Modal.Content>
        <Section style={{ backgroundColor: "grey" }}>
          <Box>
            <Heading>Print Options</Heading>
            <Columns>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Type</Label>
                    <Select
                      onChange={e =>
                        setChargeableSelected(JSON.parse(e.target.value))
                      }
                      name="type"
                      value={chargeableSelected}
                    >
                      <option value={true}>Customer 2</option>
                      <option value={false}>Customer</option>
                    </Select>
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <ReactToPrint
                  trigger={() => (
                    <Button color="warning" fullwidth>
                      Print Workshop Quote Sheet
                    </Button>
                  )}
                  content={() => componentRef.current}
                  bodyClass="print-landscape"
                  pageStyle=""
                />
              </Columns.Column>
            </Columns>
          </Box>
        </Section>
      </Modal.Content>
      <div style={{ display: "none" }}>
        <div>
          <PrintQuote
            chargeable={chargeableSelected}
            data={data}
            ref={componentRef}
          ></PrintQuote>
        </div>
      </div>
    </Modal>
  );
}
