import React, { useState } from "react";
import { useFiguresData } from "../../hooks/useFiguresData";
import { useSelector } from "react-redux";
import { getPartFiguresData, getWorkFiguresData } from "../../state/selectors";
import Heading from "react-bulma-components/lib/components/heading";
import Columns from "react-bulma-components/lib/components/columns";
import Figure from "../Homepage/Figure";
import Box from "react-bulma-components/lib/components/box";
import { formatDate, getLastDayOfWeek, getFirstDayOfWeek } from "../../utils";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";

function Figures() {
  const [start, setStart] = useState(getFirstDayOfWeek(new Date()));
  const [end, setEnd] = useState(getLastDayOfWeek(start));

  const onWeekSelect = e => {
    let first = getFirstDayOfWeek(new Date(e.target.value));
    let finish_date = getLastDayOfWeek(first);
    setStart(first);
    setEnd(finish_date);
  };

  let dates = { start: formatDate(start), end: formatDate(end) };
  let customers = []

  const complete = useFiguresData(dates.start, dates.end);

  const getTotalWorkshopProfitLoss = (part, work) => {
    return (
      work.total_customer_labour_cost +
      part.total_customer_part_cost -
      work.total_labour_cost -
      part.total_part_cost
    );
  };

  const mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    })
  }



  const data = {
    work: {
      loaded: complete.workLoading,
      data: useSelector(getWorkFiguresData)
    },
    part: {
      loaded: complete.partsLoading,
      data: useSelector(getPartFiguresData)
    }
  };

  if (data.work.loaded && data.part.loaded) {
    mergeByProperty(data.work.data.customers, data.part.data.customers, 'display_name');
  }

  return (
    <Box>
      <Heading size={5}>Workshop Weekly Figures</Heading>
      <Label>Select a Date</Label>
      <Field className="contact-section has-addons">
        <Control>
          <Input
            type="date"
            value={formatDate(start)}
            name="day"
            onChange={onWeekSelect}
          />
        </Control>
      </Field>
      <Heading size={6}>
        {start.toDateString()} - {end.toDateString()}
      </Heading>
      <Columns>
        <Figure
          label="Total Parts (All Suppliers)"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_part_cost : 0}
        />
        <Figure
          label="Total Labour (All Suppliers)"
          loaded={data.part.loaded}
          value={data.work.loaded ? data.work.data.total_labour_cost : 0}
        />
        <Figure
          label="Total Customer Part Charges"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_customer_part_cost : 0}
        />
        <Figure
          label="Total Customer Labour Charges"
          loaded={data.work.loaded}
          value={
            data.work.loaded ? data.work.data.total_customer_labour_cost : 0
          }
        />
        <Figure
          label="Total Workshop Parts/Labour Profit"
          loaded={data.work.loaded && data.part.loaded}
          value={
            data.work.loaded && data.part.loaded
              ? getTotalWorkshopProfitLoss(data.part.data, data.work.data)
              : 0
          }
        />
      </Columns>
      {data.part.loaded && data.work.loaded && data.work.data.customers.map(customer => {
        return (
          <Columns>
            <Figure
              label={`${customer.display_name} Supplier Part Cost`}
              loaded
              value={customer.part_cost}
            />
            <Figure
              label={`${customer.display_name} Supplier Labour Cost`}
              loaded
              value={ customer.labour_cost }
            />
            <Figure
              label={`${customer.display_name} Total Supplier Cost`}
              loaded
              value={
                customer.labour_cost + customer.part_cost
              }
            />
          </Columns>
        )
      })}
    </Box>
  );
}

export default Figures;
