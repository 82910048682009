import React from "react";
import { useVehicleData } from "../../hooks/useVehicleData";
import Form from "./Form";
import { getDataFresh } from "../../state/selectors";
import { useSelector } from "react-redux";
import { useInspectionTypeData } from "../../hooks/useInspectionTypeData";
import { useVehicleTypeData } from "../../hooks/useVehicleTypeData";
import { useDefaultsData } from "../../hooks/useDefaultsData";

function VehicleForm(props) {
  let dataFresh = useSelector(getDataFresh);
  let vehicle_loaded = useVehicleData(props.match.params.vehicle, dataFresh);
  let inspection_types_loaded = useInspectionTypeData({});
  let vehicle_types_loaded = useVehicleTypeData({page_size: 100});
  let app_defaults_loaded = useDefaultsData({});

  return (
    <Form
      loaded={
        vehicle_loaded &&
        inspection_types_loaded &&
        vehicle_types_loaded &&
        app_defaults_loaded
      }
      endpoint={props.endpoint}
      history={props.history}
    />
  );
}

export default VehicleForm;
