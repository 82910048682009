import { useHttp } from "./useHttp";
import { useDispatch } from "react-redux";
import { createSetInspectionTypesDataAction } from "../actions/dataActions";

export const useInspectionTypeData = (params, dataFresh) => {
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    "inspectiontype",
    "get",
    params,
    dataFresh
  );
  if (error) {
    throw Error("There was an error");
  }

  if (!loading && data !== null) {
    dispatch(createSetInspectionTypesDataAction(data));
  }

  return !loading;
};
