import React from "react";
import Box from "react-bulma-components/lib/components/box/";
import Columns from "react-bulma-components/lib/components/columns";
import useInput from "../../../hooks/useInput";
import usePostQuickLink from "../../../hooks/usePostQuickLink";
import Button from "react-bulma-components/lib/components/button";
import Collapsible from "react-collapsible";

function QuickLink() {
  let [quickLinkTitle, titleInput] = useInput({
    type: "text",
    label: "Link Title"
  });
  let [quickLinkUrl, urlInput] = useInput({
    type: "text",
    label: "URL"
  });

  const [, postQuickLink] = usePostQuickLink({
    title: quickLinkTitle,
    url: quickLinkUrl
  });

  const handleSubmit = () => {
    postQuickLink();
  };

  return (
    <Box className="settings_type">
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Add Quick Link"
      >
        <Columns>
          {titleInput}
          {urlInput}
        </Columns>
        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
    </Box>
  );
}

export default QuickLink;
