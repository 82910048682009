import React from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import Table from "react-bulma-components/lib/components/table";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import Box from "react-bulma-components/lib/components/box";
import { Link } from "react-router-dom";
import { formatCurrency, getWorkTotals } from "../../utils";
import WorkTotals from "../WorkTotals/WorkTotals";

export default function ApprovalModal({
  open,
  setOpen,
  job,
  workItems,
  onApprove,
  isJob,
  handleChange
}) {
  const onClick = () => {
    onApprove();
    setOpen(false);
  };

  const vehicle = job.vehicle ? job.vehicle : {};

  let supplierTotals = {};
  let labourCosts = 0;

  workItems.forEach(item => {
    labourCosts += item.labour_cost;
    item.parts
      .filter(part => part.supplier)
      .forEach(part => {
        if (!supplierTotals[part.supplier.display_name]) {
          supplierTotals[part.supplier.display_name] = 1 * part.cost;
        } else {
          supplierTotals[part.supplier.display_name] += 1 * part.cost;
        }
      });
  });

  const customer_label = job.customer
    ? ` - (${job.customer.display_name})`
    : "";
  const customer2_label = job.customer2
    ? ` - (${job.customer2.display_name})`
    : "";

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">
            Are you sure you want to approve?
          </Heading>
          <Columns>
            <Columns.Column>
              {(isJob && !job.mileage) &&
              <p style={{color: "red", fontWeight: "bold"}}>You cannot approve this job until you specify a mileage!</p>
              }
              <p className="is-centered">
                Verify the following details before confirming:
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label className="label">Registration</Label>
                <Control className="registration-wrapper">
                  <Input
                    className="registration-field"
                    type="text"
                    name="registration"
                    value={vehicle.registration}
                    disabled
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Control>
                  <Label>Make</Label>
                  <Input
                    type="text"
                    name="make"
                    value={vehicle.make}
                    readOnly
                    disabled
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Model</Label>
                <Input
                  type="text"
                  name="model"
                  value={vehicle.model}
                  readOnly
                  disabled
                />
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            {job.workshop && (
              <Columns.Column>
                <Field>
                  <Label>Workshop</Label>
                  <Input
                    type="text"
                    name="workshop"
                    value={job.workshop ? job.workshop.display_name : ""}
                    readOnly
                    disabled
                  />
                </Field>
              </Columns.Column>
            )}
            <Columns.Column>
              <Field>
                <Label>Total Labour Cost</Label>
                <Input
                  type="text"
                  name="labour"
                  value={formatCurrency(labourCosts)}
                  readOnly
                  disabled
                />
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                  <Label>Department</Label>
                  <Input
                    type="text"
                    name="department"
                    value={job.department}
                    readOnly
                    disabled
                  />
                </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Label>Supplier Parts Breakdown</Label>
              <Table>
                <thead>
                  <th>Supplier</th>
                  <th>Parts Cost</th>
                </thead>
                <tbody>
                  {Object.keys(supplierTotals).map(supplier => (
                    <tr>
                      <td>{supplier}</td>
                      <td>{formatCurrency(supplierTotals[supplier])}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Box>
                <Heading size={6}>Customer Total{customer_label}</Heading>
                <WorkTotals
                  workItems={workItems}
                  allItems={false}
                  chargeable={false}
                  labourFieldName="customer_labour_cost"
                  partFieldName="customer_cost"
                />
              </Box>
            </Columns.Column>
            <Columns.Column>
              <Box>
                <Heading size={6}>
                  Customer 2 Total{customer2_label}
                </Heading>
                <WorkTotals
                  workItems={workItems}
                  allItems={false}
                  chargeable={true}
                  labourFieldName="customer_labour_cost"
                  partFieldName="customer_cost"
                />
              </Box>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>Customer Invoice Number</Label>
                <Control>
                  <Input
                    type="text"
                    name="customer_invoice_number"
                    onChange={handleChange}
                    value={job.customer_invoice_number}
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Customer 2 Invoice Number</Label>
                <Control>
                  <Input
                    type="text"
                    name="customer2_invoice_number"
                    onChange={handleChange}
                    value={job.customer2_invoice_number}
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Workshop Invoice Number</Label>
                <Control>
                  <Input
                    type="text"
                    name="workshop_invoice_number"
                    onChange={handleChange}
                    value={job.workshop_invoice_number}
                  />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button color="info" fullwidth onClick={onClick}>
                Approve
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
