import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataFresh, getVehiclesData } from "../../state/selectors";
import {
  createSetDataFreshAction,
  createSetVehiclesDataAction
} from "../../actions/dataActions";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import {readableDate} from "../../utils";

export default function VehicleSalesRow({ value, index, endpoint }) {
  const [editable, setEditable] = useState(false);
  const vehicles = useSelector(getVehiclesData);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const getProfit = () => {
    return (value.sold_price - value.estimated_value + ((value.remaining_balance - value.hp_remaining_interest) - value.finance_early_settlement_amount_paid)) ? (value.sold_price - value.estimated_value + ((value.remaining_balance - value.hp_remaining_interest) - value.finance_early_settlement_amount_paid)) : 0;
  };

  const getCashFlow = () => {
    return (value.sold_price - value.finance_early_settlement_amount_paid) ? (value.sold_price - value.finance_early_settlement_amount_paid) : 0;
  };

  const onChange = e => {
    let newVehicle = vehicles.results[index];
    newVehicle[e.target.name] = e.target.value;
    let newVehicles = { ...vehicles };
    newVehicles.results[index] = newVehicle;
    dispatch(createSetVehiclesDataAction(newVehicles));
  };

  const onSave = e => {
    e.preventDefault();
    const url = endpoint + "vehicles/" + value.id;

    const conf = {
      method: "put",
      data: value,
      url
    };

    axios(conf)
      .then(response => {
        if (response) {
          dispatch(createSetDataFreshAction(dataFresh + 1));
          setEditable(false);
        }
      })
      .catch(err => {
        if (err.response.data) {
          NotificationManager.error(
            JSON.stringify(err.response.data),
            "Error!",
            10000
          );
        }
      });
  };

  return (
    <tr
      className="clickable"
      style={{ fontSize: 12 }}
      onClick={() => window.open(`/editvehicle/${value.vehicle.id}`, "_self")}
      key={value.vehicle.id}
    >
      <td>{value.vehicle.registration}</td>
      <td>{value.vehicle.make}</td>
      <td>{value.vehicle.model}</td>
      <td>{readableDate(value.internal_sale_date)}</td>
      <td>{value.sold_to}</td>
      <td>£{value.sold_price}</td>
      <td>£{getProfit().toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
      <td>£{getCashFlow().toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
      <td></td>
      <td></td>
    </tr>
  );
}
