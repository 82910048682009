import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { getQueryString } from "../utils";

export const useHttp = (
  resourceType,
  method,
  params,
  dataFresh,
  nofetch = false,
  profile = true
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const location = useLocation();
  const queryParams = getQueryString(params);

  useEffect(() => {
    const fetchData = async () => {
      if (!nofetch && profile) {
        try {
          const response = await axios({
            method,
            url: `/api/${resourceType}${queryParams}`
          });
          setData(response.data);
        } catch (e) {
          if (e.message.indexOf('401') !== -1) {
            if (location.pathname != '/login') {
                window.location.href = '/login';
            }
          } else {
            setError(true);
          }
        }
        setLoading(false);
      } else {
        setData(null);
        setLoading(false);
      }
    };
    fetchData();
  }, [resourceType, method, queryParams, dataFresh, nofetch, profile, location]);
  return [data, loading, error];
};
