import React, { useState } from "react";
import { useDiaryData } from "../../hooks/useDiaryData";
import { useDispatch, useSelector } from "react-redux";
import { getDataFresh, getDiaryData } from "../../state/selectors";
import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import {
  Field,
  Control,
  Checkbox,
  Label
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import Columns from "react-bulma-components/lib/components/columns";
import Table from "react-bulma-components/lib/components/table";
import NewDiaryEntry from "./NewDiaryEntry";
import useWeekInput from "../../hooks/useWeekInput";
import {
  formatDate,
  getLastDayOfWeek,
  getFirstDayOfWeek,
  readableDate
} from "../../utils";
import axios from "axios";
import {createSetDataFreshAction, createSetDiaryDataAction} from "../../actions/dataActions";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import DiaryRow from "./DiaryRow";

function DiaryView(props) {
  const dispatch = useDispatch();
  const [rangeType, setRangeType] = useState('all');

  const [date, dateInput, overrideDate] = useWeekInput({
    label: "Date Selection",
    initialValue: getFirstDayOfWeek(new Date()),
    weekly: rangeType === 'weekly'
  });

  const [completed, setCompleted] = useState(false);

  const [page, setPage] = useState(1);

  const dataFresh = useSelector(getDataFresh);

  const startDate = new Date(date);

  const params = {
    page, completed
  };

  if (rangeType !== 'all') {
      params.start_date = formatDate(rangeType === 'weekly' ? getFirstDayOfWeek(startDate) : startDate)
      params.end_date = formatDate(rangeType === 'weekly' ? getLastDayOfWeek(startDate) : startDate)
  }

  const complete = useDiaryData(
    params,
    dataFresh,
  );

  const onEntryUpdate = (index, newEntry) => {
    let newState = { ...data };
    newState.results[index] = newEntry;
    dispatch(createSetDiaryDataAction(newState));
  };

  const handleDelete = id => {
    const endpoint = "/api/diaryentry/" + id;
    const conf = { method: "delete", url: endpoint };
    axios(conf).then(() => dispatch(createSetDataFreshAction(dataFresh + 1)));
  };

  const updateRangeType = rangeType => {
    setPage(1);
    setRangeType(rangeType);
  };

  const handleCompleted = entry => {
    entry.completed = true;
    const endpoint = "/api/diaryentry/" + entry.id;
    const conf = { method: "put", url: endpoint, data: entry };
    axios(conf).then(() => dispatch(createSetDataFreshAction(dataFresh + 1)));
  };

  const data = useSelector(getDiaryData);

  if (!complete) return <p>Fail</p>;

  document.title = "Diary";

  return (
    <div>
      <NewDiaryEntry />
      <Box>
        <Heading>View Options</Heading>
        <Columns>
          <Columns.Column>
            <Field className="has-addons">
              <Control>
                <Label>View</Label>
                <Button
                  color={rangeType === 'all' ? "primary" : ""}
                  onClick={() => updateRangeType('all')}
                >
                  All
                </Button>
                <Button
                  color={rangeType === 'weekly' ? "primary" : ""}
                  onClick={() => {
                    updateRangeType('weekly');
                    overrideDate(formatDate(new Date()));
                  }}
                >
                  Weekly
                </Button>
                <Button
                  color={rangeType === 'daily' ? "primary" : ""}
                  onClick={() => updateRangeType('daily')}
                >
                  Daily
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          {dateInput}
          <Columns.Column>
            <Field>
              <Label>Show Completed</Label>
              <Control>
                <Checkbox
                  checked={completed}
                  type="checkbox"
                  name="completed"
                  onChange={() => setCompleted(!completed)}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>Diary</Heading>
        <Table>
          <thead>
            <tr>
              <th>Start</th>
              <th>Time</th>
              <th>Task For</th>
              <th>Comments</th>
              <th>End</th>
              {completed &&
                <th>Completed</th>
              }
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((entry, index) =>
              <DiaryRow
                entry={entry}
                index={index}
                showCompleted={completed}
                endpoint={props.endpoint}
                handleDelete={handleDelete}
                handleCompleted={handleCompleted}
                onEntryUpdate={onEntryUpdate}
              />
            )}


          </tbody>
        </Table>
        <Pagination
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        >
          {" "}
        </Pagination>
      </Box>
    </div>
  );
}

export default DiaryView;
