import React, {Component} from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import Button from "react-bulma-components/lib/components/button";
import {formatDate} from "../../utils";
import Collapsible from "react-collapsible";
import ContactSection from "../Contact/ContactSection";

function CustomerOwnerSection(props) {
  return (
    <Box>
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Customer Owner Info"
      >
        <ContactSection
          title="Customer"
          contactUpdate={customer => props.onChange({target: {name: 'owner', value: customer}})}
          endpoint={props.endpoint + "contacts"}
          contact={props.data.owner || {}}
          type="CC"
        />
      </Collapsible>
    </Box>
  );
}

export default CustomerOwnerSection;
