import React, { Component } from "react";
import Button from "react-bulma-components/lib/components/button";
import { Field, Control } from "react-bulma-components/lib/components/form";
import { asyncContainer, Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";

const AsyncTypeahead = asyncContainer(Typeahead);

export default class ContactFilter extends Component {
  state = {
    searchData: [],
    isLoading: false
  };

  types = {
    CC: "Customer",
    SP: "Supplier"
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContactSelect = e => {
    // Trigger filter
    this.setState({ contact: e[0] });
    this.props.contactUpdate(e[0]);
  };

  handleClear = () => {
    this.setState({ contact: {} });
    this.typeahead.getInstance().clear();
    this.props.contactUpdate();
  };

  handleSearch = query => {
    this.setState({ isLoading: true }, () => {
      axios
        .get(
          this.props.endpoint +
            `contacts?contact_type=${this.props.type}&search=${query}`
        )
        .then(resp =>
          this.setState({
            isLoading: false,
            searchData: resp.data.results
          })
        );
    });
  };

  render() {
    return (
      <Field className="contact-section has-addons">
        <AsyncTypeahead
          id="typeahead"
          labelKey="display_name"
          minLength={2}
          name={"display_name"}
          isLoading={false}
          onSearch={this.handleSearch}
          onChange={this.handleContactSelect}
          placeholder={`Search ${this.types[this.props.type]}`}
          options={this.state.searchData}
          ref={typeahead => (this.typeahead = typeahead)}
          className="typeahead"
        />
        <Control>
          <Button onClick={this.handleClear} color="warning">
            Clear
          </Button>
        </Control>
      </Field>
    );
  }
}
