import React, { useState } from "react";
import Box from "react-bulma-components/lib/components/box";
import {
  Input,
  Label,
  Control,
  Field, Textarea
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import Pagination from "react-bulma-components/lib/components/pagination";
import Columns from "react-bulma-components/lib/components/columns";
import { getQuotesData } from "../../state/selectors";
import { useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import { useQuotesData } from "../../hooks/useQuotesData";
import useSelect from "../../hooks/useSelect";
import { readableDate } from "../../utils";

function Quote(props) {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState();
  const [currentSearch, setCurrentSearch] = useState();

  const [view, viewInput] = useSelect({
    label: "View",
    options: [
      { name: "Active", value: "active"},
      { name: "Quotes", value: "quotes" },
      { name: "Approved", value: "approved" },
      { name: "All", value: "all"}
    ],
    initialValue: "active",
    testId: "part-view",
    callback: () => {
      setPage(1);
    }
  });

  let params = { page };

  if (currentSearch) {
    params.search = currentSearch;
  }

  if (view !== "all") {
    params.view = view;
  }

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const complete = useQuotesData(params);
  const data = useSelector(getQuotesData);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Quotes";

  return (
    <div>
      <Box>
        <Columns>
          {viewInput}

          <Columns.Column>
            <Label>Search for a Quote</Label>
            <Field className="has-addons">
              <Control>
                <Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                ></Input>
              </Control>
              <Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Control>
              <Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Button
                  onClick={() => props.history.push("/editquote")}
                  color="success"
                  className="is-pulled-right"
                >
                  New Quote+
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Quotes</h1>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr className="small-row-black">
              <th>Quote No.</th>
              <th>Registration</th>
              <th>Description</th>
              <th>Provisional Start</th>
              <th>Items Not Started</th>
              <th>Comments</th>
              <th>Customer</th>
              <th>Customer 2</th>
              <th>Make</th>
              <th>Model</th>
              {view === 'approved' &&
                <th>Approver</th>
              }
            </tr>
          </thead>
          <tbody>
            {data.results.map(value => {
              let highlightClass = "";
              if (value.has_jobs) {
                if (value.ready_for_approval) {
                  highlightClass = "table-highlight-green";
                } else {
                  highlightClass = "table-highlight-orange";
                }
              }
              return (
                <tr
                  className={`clickable small-row-black ${highlightClass}`}
                  onClick={() =>
                    window.open(`/editquote/${value.id}`, "_self")
                  }
                  key={value.id}
                >
                  <td>{value.id}</td>
                  <td>{value.vehicle.registration}</td>
                  <td>{value.description}</td>
                  <td>{readableDate(value.provisional_start_date)}</td>
                  <td>{value.not_started_work_items}</td>
                  <Field className="has-addons">
                    <Control>
                      <Textarea
                        rows={2}
                        className="small-row-black min-column-width"
                        type="text"
                        value={value.comments_list.length > 0 ? value.comments_list[value.comments_list.length -1].text : ""}
                        disabled
                        readOnly
                      />
                    </Control>
                  </Field>
                  <td>{value.customer.display_name}</td>
                  <td>
                    {value.customer2
                      ? value.customer2.display_name
                      : ""}
                  </td>
                  <td>{value.vehicle.make}</td>
                  <td>{value.vehicle.model}</td>
                  {view === 'approved' &&
                  <td>{value.approving_user ? `${value.approving_user.first_name} ${value.approving_user.last_name}` : ""}</td>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
    </div>
  );
}

export default Quote;
