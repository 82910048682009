import React from "react";
import {readableDate} from "../../../../utils";

function SoldRow({value, finance, activeTab}) {
  return (
    <tr
      className="clickable"
      onClick={() => window.open("/editvehicle/" + value.id, "_self")}
      key={value.id}
    >
      <td>
    <span className="registration-field">
    {value.registration}
    </span>
      </td>
      {activeTab === 'fleet' &&
      <td>{value.fleet_number}</td>
      }
      <td>{value.make}</td>
      <td>{value.model}</td>
      <td>{value.vin_number}</td>
      <td>{value.type ? value.type.name : ""}</td>
      <td>{finance.sold_to}</td>
      <td>{readableDate(finance.sold_date)}</td>
      <td>
        £{parseFloat(finance.sold_price).toFixed(2)}
      </td>
      <td>{finance.sale_comments}</td>
      <td>{value.owner ? value.owner.display_name : "None Set"}</td>
      <td>{finance.finance_comments}</td>
    </tr>
  )
}

export default SoldRow;
