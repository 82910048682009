import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { readableDate } from "../../utils";

export default function FilterHeading(props) {
  let options = props.options
    ? props.options.filter(option => {return !!option[props.heading.field] || option[props.heading.field] === 0})
    : [];

  let labelKey = (props.heading.date || props.heading.plusBlanks)
    ? obj => {
        return obj[props.heading.field] === "BLANK"
          ? "Only Blanks"
          : props.heading.date ? readableDate(obj[props.heading.field]) : obj[props.heading.field];
      }
    : obj => '' + obj[props.heading.labelField];

  if (props.heading.date || props.heading.plusBlanks) {
    options.unshift({
      [labelKey]: "Only Blanks",
      [props.heading.field]: "BLANK"
    });
  }

  return (
    <th key={props.heading.field}>
      <Typeahead
        id={props.heading.field}
        labelKey={labelKey}
        onChange={sel =>
          props.setFilters({ ...props.filters, [props.heading.field]: sel })
        }
        multiple={false}
        clearButton
        options={options}
        placeholder={props.heading.label}
        selected={props.filters[props.heading.field] || []}
        className="typeahead"
      />
    </th>
  );
}
