import React, { Component } from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";

class LicenceSection extends Component {
  render() {
    return (
      <Box>
        <Heading>Licence</Heading>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>N.I. Number</Label>
              <Control>
                <Input
                  type="text"
                  name="ni_number"
                  onChange={this.props.onChange}
                  value={this.props.rental.ni_number}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Licence Number</Label>
              <Control>
                <Input
                  type="text"
                  name="licence_number"
                  onChange={this.props.onChange}
                  value={this.props.rental.licence_number}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Licence Held (Years)</Label>
              <Control>
                <Input
                  type="number"
                  name="licence_held"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.licence_held)}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default LicenceSection;
