import React from "react";
import { useJobData } from "../../hooks/useJobData";
import Form from "./Form";
import { getDataFresh } from "../../state/selectors";
import { useSelector } from "react-redux";
import {useDefaultsData} from "../../hooks/useDefaultsData";

function JobForm(props) {
  let dataFresh = useSelector(getDataFresh);
  let app_defaults_loaded = useDefaultsData({});
  let job_loaded = useJobData(props.match.params.job, dataFresh);
  return (
    <Form
      loaded={job_loaded}
      endpoint={props.endpoint}
      history={props.history}
    />
  );
}

export default JobForm;
