import {createSetVehicleTrackersDataAction} from "../actions/dataActions";
import {useGetAndSet} from "./useGetAndSet";

export const useVehicleTrackerData = (params, dataFresh, nofetch) => {
  return useGetAndSet(
      "vehicletracker",
      params,
      createSetVehicleTrackersDataAction,
      dataFresh,
      nofetch
  );
};
