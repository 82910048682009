import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import React, { Component } from "react";

export default class DailyTable extends Component {
  render() {
    return (
      <Box>
        <h1 className="title">Rentals</h1>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Registration</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Customer</th>
              <th>Registration</th>
              <th>Make</th>
              <th>Model</th>
              <th>Type</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {this.props.rentals.map((value, index) => (
              <tr
                className="clickable"
                onClick={() => window.open(`/editrental/${value.id}`, "_self")}
                key={value.id}
              >
                <td>{value.vehicle.registration}</td>
                <td> {value.start_date}</td>
                <td> {value.finish_date}</td>
                <td> {value.customer.display_name}</td>
                <td> {value.vehicle.registration}</td>
                <td> {value.vehicle.make}</td>
                <td> {value.vehicle.model}</td>
                <td> {value.vehicle.type}</td>
                <td> {value.comments} </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          onChange={page => this.props.onPageChange(page)}
          current={this.props.current}
          total={Math.ceil(this.props.count / 25)}
        />
      </Box>
    );
  }
}
