import React from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Heading from "react-bulma-components/lib/components/heading";

export default function CreateModal({
  open,
  setOpen,
  id,
}) {

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal closeOnBlur show={open} onClose={onClose}>
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading>Transaction Created</Heading>
          <p>Transaction created with id: <b>{id}</b></p>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
