import React, {useRef, useState} from "react";
import Box from "react-bulma-components/lib/components/box";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import Pagination from "react-bulma-components/lib/components/pagination";
import Tabs from "react-bulma-components/lib/components/tabs";
import Columns from "react-bulma-components/lib/components/columns";
import { readableDate } from "../../utils";
import { getDataFresh, getJobsData } from "../../state/selectors";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import { useJobsData } from "../../hooks/useJobsData";
import Figures from "./Figures";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useSafeSave from "../../hooks/useSafeSave";
import axios from "axios";
import {
  createSetDataFreshAction,
  createSetJobsDataAction,
  createSetPartsDataAction
} from "../../actions/dataActions";
import JobRow from "./JobRow";
import PrintJob from "./PrintJob";

function Job(props) {
  const [page, setPage] = useState(1);
  const [activeView, setView] = useState("started");
  const [activeTab, setTab] = useState("all");
  const [searchText, setSearchText] = useState();
  const [currentSearch, setCurrentSearch] = useState();
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);

  let params = { page, view: activeView };

  if (activeTab) {
    params.ws = activeTab;
  }

  if (currentSearch) {
    params.search = currentSearch;
  }

  const complete = useJobsData(params, dataFresh);
  const data = useSelector(getJobsData);

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
  };

  const setNewView = (view) => {
    setPage(1)
    setView(view)
  };

  const setNewTab = (tab) => {
    setPage(1)
    setTab(tab)
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const onJobUpdate = (index, newJob) => {
    let newState = { ...data };
    newState.results[index] = newJob;
    dispatch(createSetJobsDataAction(newState));
  };

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Jobs";

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Label>Search for a Job</Label>
            <Field className="has-addons">
              <Control>
                <Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                ></Input>
              </Control>
              <Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Control>
              <Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Button
                  onClick={() => props.history.push("/editjob")}
                  color="success"
                  className="is-pulled-right"
                >
                  New Job +
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Figures />
      <Box>
        <h1 className="title">Jobs</h1>
        <Tabs type="boxed" fullwidth align="centered">
          <Tabs.Tab active={activeTab === "all"} onClick={() => setNewTab("all")}>
            All
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "quotes"}
            onClick={() => setNewTab("quotes")}
          >
            Quote Jobs
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "NR AUTOS WORKSHOP"}
            onClick={() => setNewTab("NR AUTOS WORKSHOP")}
          >
            N & R Workshop
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "NR AUTOS BODYSHOP"}
            onClick={() => setNewTab("NR AUTOS BODYSHOP")}
          >
            N & R Bodywork
          </Tabs.Tab>
          <Tabs.Tab active={!activeTab.length} onClick={() => setNewTab("")}>
            External
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "LCV JOBS"}
            onClick={() => setNewTab("LCV JOBS")}
          >
            LCV Jobs
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "RENTALS DIRECT"}
            onClick={() => setNewTab("RENTALS DIRECT")}
          >
            Rentals Direct
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "RENTALS DIRECT BODYSHOP"}
            onClick={() => setNewTab("RENTALS DIRECT BODYSHOP")}
          >
            Rentals Direct Bodywork
          </Tabs.Tab>
        </Tabs>
        <Tabs type="toggle" align="centered">
          <Tabs.Tab
            active={activeView === "yesterday"}
            onClick={() => setNewView("yesterday")}
          >
            Yesterday
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "today"}
            onClick={() => setNewView("today")}
          >
            Today
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "started"}
            onClick={() => setNewView("started")}
          >
            Started
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "upcoming"}
            onClick={() => setNewView("upcoming")}
          >
            Upcoming
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "finished"}
            onClick={() => setNewView("finished")}
          >
            Finished
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "all"}
            onClick={() => setNewView("all")}
          >
            All
          </Tabs.Tab>
        </Tabs>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr className="small-row-black">
              <th>Job No.</th>
              <th>Registration</th>
              <th>Description</th>
              <th>Comments</th>
              <th>Customer</th>
              <th>Customer 2</th>
              <th>Make</th>
              <th>Model</th>
              <th>Workshop Date</th>
              <th>Booked In Date</th>
              <th>Workshop</th>
              {(activeView === 'finished' || activeView === 'all') &&
                <th>Approver</th>
              }
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <JobRow
                job={value}
                onJobUpdate={onJobUpdate}
                index={index}
                endpoint={props.endpoint}
                showApprover={activeView === 'finished' || activeView === 'all'}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
    </div>
  );
}

export default Job;
