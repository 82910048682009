
export const intervalTypes = [
    {
        id: 'WEEKLY',
        name: 'Weekly',
    },
    {
        id: 'MONTHLY',
        name: 'Monthly',
    },
    {
        id: 'YEARLY',
        name: 'Yearly',
    },
    {
        id: 'FIXED_DURATION',
        name: 'Fixed Duration',
    },
];

export const paymentTypes = [
    {
        id: 'BACS',
        name: 'BACS',
    },
    {
        id: 'CASH',
        name: 'Cash',
    },
    {
        id: 'CARD',
        name: 'Card',
    },
    {
        id: 'DIRECT_DEBIT',
        name: 'Direct Debit',
    },
];
