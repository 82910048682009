import React, { useState } from "react";
import { usePartsData } from "../../hooks/usePartsData";
import {
  Label,
  Field,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Table from "react-bulma-components/lib/components/table";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getPartLookupData, getPartsData, getVehicleTypesData} from "../../state/selectors";
import Pagination from "react-bulma-components/lib/components/pagination";
import PartLookupRow from "./PartLookupRow";
import useSelect from "../../hooks/useSelect";
import {
  createSetDataFreshAction,
  createSetPartLookupDataAction,
  createSetPartsDataAction,
  createSetWorkDataAction
} from "../../actions/dataActions";
import {usePartLookupData} from "../../hooks/usePartLookupData";
import CreatePartModal from "./CreatePartModal";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import {useVehicleTypeData} from "../../hooks/useVehicleTypeData";
import axios from "axios";
import MultiplePartStockEditModal from "./MultiplePartStockEditModal";
import FAIcon from "../Icon/FAIcon";

function PartLookup(props) {
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();
  const vehicle_types_loaded = useVehicleTypeData({});

  const [createPartModalOpen, setCreatePartModalOpen] = useState(false);

  const headings = [
    {
      label: "Make",
      field: "make",
      labelField: "make"
    },
    { label: "Model",
      field: "model",
      labelField: "model"
    },
    {
      label: "Type",
      field: 'vehicle_type',
      labelField: "vehicle_type__name"
    },

    {
      label: "Part Name",
      field: "name",
      labelField: "name"
    },
    {
      label: "Part Number",
      field: "part_number",
      labelField: "part_number"
    },
    {
      label: "Cost",
    },
    {
      label: "Supplier",
      field: "supplier",
      labelField: "supplier__display_name"
    },
    {
      label: "Stock volume",
      field: "stock_volume",
      labelField: 'stock_volume'
    },
    {
      label: "Comments"
    },
  ];

  let params = {
    page,
    search,
  };

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter] || (filters[filter][0] && filters[filter][0][filter] === 0)) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const toggleSelectVisible = () => {
    let newSelected = { ...selected };
    let selectedKeys = Object.keys(newSelected);
    const notPresent = data.results.find(
      part => selectedKeys.indexOf(part.id.toString()) === -1
    );

    if (notPresent) {
      data.results.forEach(item => {
        newSelected[item.id.toString()] = item;
      });
    } else {
      data.results.forEach(item => {
        delete newSelected[item.id.toString()];
      });
    }
    setSelected(newSelected);
  };
  const onSelectCheck = item => {
    let newSelection = { ...selected };
    if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
      newSelection[item.id.toString()] = item;
    } else {
      delete newSelection[item.id.toString()];
    }
    setSelected(newSelection);
  };

  const clearSelected = () => {
    setSelected({});
  };

  const updateMultiple = newData => {
    const url = props.endpoint + "partlookups";
    const conf = {
      method: "put",
      data: newData,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setSelected({});
    });
  };

  const saveSelected = (partName, partCost, partNumber, stockVolume) => {
    const newData = Object.values(selected).map(item => {
      let toSave = { ...item };
      if (partName) {
        toSave.name = partName;
      }
      if (partCost || partCost === 0) {
        toSave.cost = partCost;
      }
      if (partNumber) {
        toSave.part_number = partNumber;
      }
      if (stockVolume || stockVolume === 0) {
        toSave.stock_volume = stockVolume;
      }
      return toSave;
    });
    return updateMultiple(newData);
  };

  const complete = usePartLookupData(params, dataFresh);
  const data = useSelector(getPartLookupData);
  const vehicle_types = useSelector(getVehicleTypesData);

  const updateValue = (index, newPart) => {
    let currentData = { ...data };
    currentData.results[index] = newPart;
    dispatch(createSetPartLookupDataAction(currentData));
  };

  if (!complete || !vehicle_types_loaded) return <div>Loading</div>;

  document.title = "Part Stock";

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Label>Part Search</Label>
            <Field className="has-addons">
              <Control>
                <Input
                  onChange={e => {
                    setSearchText(e.target.value);
                  }}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && setSearch(searchText)}
                  data-testid="part-search"
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                />
              </Control>
              <Control>
                <Button
                  data-testid="search-button"
                  color="success"
                  onClick={() => {
                    setSearch(searchText);
                  }}
                >
                  Search
                </Button>
              </Control>
              <Control>
                <Button
                  color="warning"
                  onClick={() => {
                    setSearch("");
                    setSearchText("");
                  }}
                >
                  Clear
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Button
                  onClick={() => setCreatePartModalOpen(true)}
                  color="success"
                  className="is-pulled-right"
                >
                  New Part +
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Table>
          <thead>
            <tr>
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
              <th>
                <Button
                  onClick={toggleSelectVisible}
                  color={
                    data.results.find(
                      part =>
                        Object.keys(selected).indexOf(part.id.toString()) === -1
                    )
                      ? ""
                      : "danger"
                  }
                >
                  {data.results.find(
                    part =>
                      Object.keys(selected).indexOf(part.id.toString()) === -1
                  ) ? (
                    <div>
                      <FAIcon size="small" icon={["fas", "check"]} />
                      <span>Visible</span>
                    </div>
                  ) : (
                    <div>
                      <FAIcon size="small" icon={["fas", "times"]} />
                      <span>Visible</span>
                    </div>
                  )}
                </Button>
              </th>
              {!!Object.entries(selected).length && (
                <th>
                  <Button onClick={() => setSelected({})} color="danger">
                    <FAIcon size="small" icon={["fas", "times"]} />
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.results.map((part, index) => (
              <PartLookupRow
                key={part.id}
                partLookup={part}
                endpoint={props.endpoint}
                updateValue={newPart => updateValue(index, newPart)}
                vehicleTypes={vehicle_types.results}
                onSelectCheck={onSelectCheck}
                selected={
                  Object.keys(selected).indexOf(part.id.toString()) !== -1
                }
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
      <Box>
        <Columns>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setEditModalOpen(true)}
              color={Object.entries(selected).length ? "primary" : ""}
              disabled={!Object.entries(selected).length}
            >
              Edit Selected
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <MultiplePartStockEditModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        selected={selected}
        saveSelected={saveSelected}
      />
      <CreatePartModal
        open={createPartModalOpen}
        setOpen={setCreatePartModalOpen}
        endpoint={props.endpoint}
        vehicleTypes={vehicle_types.results}
      />
    </div>
  );
}

export default PartLookup;
