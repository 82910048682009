import {
    Control,
    Field,
    Input, Label
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import Button from "react-bulma-components/lib/components/button";
import React, {useEffect, useState} from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import axios from "axios";

function useEditableTableTypeaheadCell({
    propName,
    value,
    onChange,
    onSave,
    editable,
    setEditable,
    disabled,
    inlineEdit,
    labelKey,
    searchUrl,
    placeholder
}) {
    const [isLoading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState(value ? [value] : []);
    const [, setTypeahead] = useState();
    const handleSearch = e => {
        const url = searchUrl.indexOf('?') === -1 ? `${searchUrl}?` : searchUrl;
        setLoading(true);
        axios.get(`${url}&search=${e}`).then(resp => {
            const results = resp.data.results;
            setSearchData(resp.data.results);
            setLoading(false);
        });
    };

    const handleValueSelect = inputValue => {
        const selectedValue = inputValue[0];
        onChange({target: {name: propName, value: selectedValue}});
    };

    return <td onClick={e => e.stopPropagation()}>
        <Field className="has-addons">
            <Control>
                <AsyncTypeahead
                    id="typeahead"
                    labelKey={labelKey}
                    minLength={2}
                    name={propName}
                    onSearch={handleSearch}
                    onChange={handleValueSelect}
                    placeholder={placeholder}
                    options={searchData ? searchData : [value]}
                    ref={typeahead => setTypeahead(typeahead)}
                    className="typeahead"
                    isLoading={isLoading}
                    defaultSelected={
                        value ? [value] : []
                    }
                    disabled={!editable}
                />
            </Control>
            {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
        </Field>
    </td>;
}

export default useEditableTableTypeaheadCell;
