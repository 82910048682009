import React, { useState } from "react";
import {
  Field,
  Control,
  Input, Label, Select
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createSetDataFreshAction } from "../../../../actions/dataActions";
import {getDataFresh, getTransactionReportingCategoriesData} from "../../../../state/selectors";
import Icon from "react-bulma-components/lib/components/icon";
import {useTransactionReportingCategoryData} from "../../../../hooks/useTransactionReportingCategoryData";

function TransactionCategoryRow({
  transactionCategoryData,
  index,
  onTransactionCategoryUpdate,
  endpoint,
  reportingCategories
}) {
  const dispatch = useDispatch();
  const [transactionCategory, setTransactionCategory] = useState(transactionCategoryData);
  const [editable, setEditable] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  const reportingCategoryObjects = reportingCategories.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const submitUpdate = e => {
    e.stopPropagation();
    const url = endpoint + "transactioncategory/" + transactionCategory.id;
    const conf = {
      method: "put",
      data: transactionCategory,
      url
    };

    axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onChange = e => {
    let newTransactionCategory = { ...transactionCategory };
    newTransactionCategory[e.target.name] = e.target.value || null;
    setTransactionCategory(newTransactionCategory);
    onTransactionCategoryUpdate(index, newTransactionCategory);
  };

  const handleReportingCategoryUpdate = e => {
    const toUpdate = {
      target: {
        name: "transaction_reporting_category",
        value: e.target.value ? reportingCategoryObjects[e.target.value] : ''
      }
    };
    onChange(toUpdate);
  };

  const onEdit = e => {
    e.stopPropagation();
    setEditable(!editable);
  };

  const onDelete = e => {
    const url = endpoint + "transactioncategory/" + transactionCategory.id;
    const conf = { method: "delete", url };
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  return (
    <tr key={transactionCategoryData.id}>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control>
            <Input
              onChange={onChange}
              category="text"
              name="name"
              value={transactionCategory.name}
              disabled={!editable}
            />
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control>
            <Select
              onChange={handleReportingCategoryUpdate}
              name="type"
              value={transactionCategory.transaction_reporting_category ? transactionCategory.transaction_reporting_category.id : ''}
              disabled={!editable}
            >
              {reportingCategories.map(value => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
              <option value={''}>
                None
              </option>
            </Select>
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td className={"is-narrow"}>
        <Button color={"danger"} onClick={onDelete}>
          Delete X
        </Button>
      </td>
    </tr>
  );
}

export default TransactionCategoryRow;
