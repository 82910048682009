import {rate, getMonthsBetween} from "./utils";

const addMonths = (date, months) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
};

export const getRegularPaymentSchedule = vehicle => {
  let months = [];

  let currentBalance = vehicle.finance_amount_borrowed;

  const monthlyInterestRate = rate(
    1 * vehicle.payment_term,
    -1 * vehicle.monthly_payment,
    1 * vehicle.finance_amount_borrowed || 0
  );
  for (let i = 0; i < vehicle.payment_term; i++) {

    let thisMonthsInterest = (monthlyInterestRate * currentBalance);
    let remainingBalance = currentBalance + thisMonthsInterest;
    remainingBalance = remainingBalance - vehicle.monthly_payment;
    currentBalance = remainingBalance;

    months.push({month: i, interest: thisMonthsInterest, remainingBalance: remainingBalance})
  }

  return months;
};

const getRegRemainingInterest = (vehicle, date) => {
  let schedule = getRegularPaymentSchedule(vehicle);
  let elapsedMonths = getMonthsBetween(new Date(vehicle.finance_start_date), date);
  schedule.splice(0, elapsedMonths - 1);

  return schedule.reduce((prev, next) => ({interest: prev.interest + next.interest}), {interest: 0}).interest;
};

const getAltRemainingInterest = (vehicle, date) => {
  let schedule = getPaymentSchedule(vehicle);
  let elapsedMonths = getMonthsBetween(new Date(vehicle.finance_start_date), date);

  schedule.splice(0, elapsedMonths - 1);

  return schedule.reduce((prev, next) => ({interest_accrued: prev.interest_accrued + next.interest_accrued}), {interest_accrued: 0}).interest_accrued || 0
};

const getRegLastMonthInterest = (vehicle, date) => {
  let schedule = getRegularPaymentSchedule(vehicle);
  let elapsedMonths = getMonthsBetween(new Date(vehicle.finance_start_date), date);
  schedule.splice(0, elapsedMonths - 1);


  return schedule.length ? schedule[schedule.length - 1].interest : 0;
};

const getAltLastMonthInterest = (vehicle, date) => {
  let schedule = getPaymentSchedule(vehicle);
  let elapsedMonths = getMonthsBetween(new Date(vehicle.finance_start_date), date);

  schedule.splice(0, elapsedMonths - 1);

  return schedule.length ? schedule[schedule.length - 1].interest_accrued : 0;
};

export const getRemainingInterest = (vehicle, date) => {
  if (vehicle.finance_early_settlement_date) {
    date = new Date(vehicle.finance_early_settlement_date)
  }
  if (vehicle.finance_type === 'REGULAR') {
    return getRegRemainingInterest(vehicle, date);
  } else if (vehicle.finance_type === "FIXED_MONTHLY_CAPITAL" || vehicle.finance_type === "STOCKING_PLAN") {
    return getAltRemainingInterest(vehicle, date);
  }
};

export const getLastMonthInterest = (vehicle, date) => {
  if (vehicle.finance_early_settlement_date) {
    date = new Date(vehicle.finance_early_settlement_date)
  }
  if (vehicle.finance_type === 'REGULAR') {
    return getRegLastMonthInterest(vehicle, date);
  } else if (vehicle.finance_type === "FIXED_MONTHLY_CAPITAL" || vehicle.finance_type === "STOCKING_PLAN") {
    return getAltLastMonthInterest(vehicle, date);
  }
};

export const getStockingPlanPaymentSchedule = vehicle => {
  let payments = [];
  if (
    (vehicle.finance_type === "STOCKING_PLAN") &&
    vehicle.finance_start_date &&
    vehicle.finance_amount_borrowed &&
    vehicle.monthly_payment &&
    vehicle.sp_capital_repayment_start_date &&
    vehicle.sp_capital_repayment_start_date &&
    vehicle.sp_interest_repayment_end_date &&
    vehicle.sp_interest_repayment_end_date
  ) {
    const finance_start_date = new Date(vehicle.finance_start_date);
    const interest_start_date = new Date(vehicle.sp_interest_repayment_start_date)
    const capital_start_date = new Date(vehicle.sp_capital_repayment_start_date)
    capital_start_date.setHours(0 , 0, 0, 0, )
    interest_start_date.setHours(0 , 0, 0, 0, )
    const interest_end_date = new Date(vehicle.sp_interest_repayment_end_date)
    const capital_end_date = new Date(vehicle.sp_capital_repayment_end_date)
    const months = getMonthsBetween(finance_start_date, new Date(Math.max(capital_end_date, interest_end_date)))
    capital_start_date.setHours(0 , 0, 0, 0, )
    interest_start_date.setHours(0 , 0, 0, 0, )
    // const months = vehicle.payment_term;
    for (let month = 0; month < months; month++) {
      let payment_date = addMonths(new Date(finance_start_date), month + 1);

      let interest_accrued = interest_start_date <= payment_date && payment_date <= interest_end_date ? (vehicle.sp_monthly_interest_rate * 0.01) * vehicle.finance_amount_borrowed : 0;
      let capital_payment = capital_start_date <= payment_date && payment_date <= capital_end_date ? vehicle.monthly_payment : 0;

      payments.push({capital_payment, interest_accrued, payment_date});
    }
  }
  return payments;
};

export const getFixedMonthlyCapitalPaymentSchedule = vehicle => {
  let payments = [];
  if (
    vehicle.finance_start_date &&
    vehicle.finance_amount_borrowed &&
    vehicle.monthly_payment
  ) {
    const finance_start_date = new Date(vehicle.finance_start_date);
    const months = vehicle.payment_term;
    for (let month = 0; month < months; month++) {
      let payment_date = addMonths(new Date(finance_start_date), month + 1);
      let previous_payment_date = addMonths(
        new Date(finance_start_date),
        month
      );
      const days = Math.round(
        Math.abs((payment_date - previous_payment_date) / (24 * 60 * 60 * 1000))
      );
      let balance_remaining =
        vehicle.finance_amount_borrowed - month * vehicle.monthly_payment;

      let interest_accrued =
        balance_remaining * ((vehicle.hp_interest_rate / 100 / 365) * days);
      payments.push({capital_payment: vehicle.monthly_payment, interest_accrued, payment_date});
    }
  }

  return payments;
};

export const getPaymentSchedule = vehicle => {
  if (
    vehicle.finance_start_date &&
    vehicle.finance_amount_borrowed &&
    vehicle.monthly_payment
  ) {
    if (vehicle.finance_type === "FIXED_MONTHLY_CAPITAL") {
      return getFixedMonthlyCapitalPaymentSchedule(vehicle);
    } else if (vehicle.finance_type === "STOCKING_PLAN") {
      return getStockingPlanPaymentSchedule(vehicle);
    }
  }
  return [];
}

const getPaymentScheduleOverlap = (paymentSchedule, now, finalPayment) => {
  return paymentSchedule.filter(payment => {
    return now <= payment.payment_date && payment.payment_date <= finalPayment;
  });
};

export const getRemainingPayments = (paymentSchedule, now) => {
  return paymentSchedule.filter(payment => {
    return (
      now <= payment.payment_date
    );
  });
};

export const getFlatInterestRemaining = (vehicle, now) => {
  const paymentSchedule = getPaymentSchedule(vehicle);
  const overlappingPayments = getRemainingPayments(paymentSchedule, now);
  return overlappingPayments.reduce((a, b) => a + b.interest_accrued, 0);
};

export const getThisMonthsPayment = (vehicle, date) => {
  if (
    (vehicle.finance_type === "FIXED_MONTHLY_CAPITAL" || vehicle.finance_type === "STOCKING_PLAN") &&
    vehicle.finance_start_date &&
    vehicle.finance_amount_borrowed &&
    vehicle.monthly_payment
  ) {
    if (!vehicle.finance_early_settlement_date) {
      const paymentSchedule = getPaymentSchedule(vehicle);
      const remaining = getRemainingPayments(paymentSchedule, date);
      return remaining.length
        ? (remaining[0].interest_accrued + 1 * remaining[0].capital_payment).toFixed(
          2
        )
        : "SETTLED";
    } else {
      return "SETTLED";
    }
  } else {
    return 0;
  }
};
