import React from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import { Field } from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";

export default function DeleteWarningModal({ open, setOpen, onDelete }) {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">Are you sure?</Heading>
          <Columns>
            <Columns.Column>
              <p className="is-centered">
                Deleting this item is irreversible, and any data associated with
                this item will also be deleted
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="danger" fullwidth onClick={onDelete}>
                Delete
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
