import React from "react";
import Navbar from "react-bulma-components/lib/components/navbar";

function QuickLinksNav({quickLinks}) {
  return <Navbar.Dropdown>
    {quickLinks.map(quickLink => <Navbar.Item onClick={() => window.open(`${quickLink.url}`, '_blank')}>
      {quickLink.title}
    </Navbar.Item>)}
  </Navbar.Dropdown>
}

export default QuickLinksNav;
