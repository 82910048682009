import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSetTransactionCategoriesDataAction,
} from "../../../../actions/dataActions";
import {getDataFresh, getTransactionReportingCategoriesData} from "../../../../state/selectors";
import Spinner from "react-spinner-material";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Table from "react-bulma-components/lib/components/table";
import Pagination from "react-bulma-components/lib/components/pagination";
import { useTransactionReportingCategoryData } from "../../../../hooks/useTransactionReportingCategoryData";
import NewTransactionReportingCategory from "./NewTransactionReportingCategory";
import TransactionReportingCategoryRow from "./TransactionReportingCategoryRow";

function TransactionReportingCategoryView(props) {
  const dispatch = useDispatch();
  // const dataFresh = useSelector(getDataFresh);


  const onTransactionReportingCategoryUpdate = (index, newTransactionReportingCategory) => {
    let newState = data;
    newState.results[index] = newTransactionReportingCategory;
    dispatch(createSetTransactionCategoriesDataAction(newState));
  };

  // const complete = useTransactionReportingCategoryData({ page }, dataFresh);
  const data = useSelector(getTransactionReportingCategoriesData);

  // if (!complete) {
  //   return (
  //     <Box>
  //       <Spinner
  //         className="spinner-centre"
  //         size={120}
  //         spinnerColor={"#3273dc"}
  //         spinnerWidth={2}
  //       />
  //     </Box>
  //   );
  // }

  return (
    <div>
      <NewTransactionReportingCategory />
      <Box>
        <Heading size={5}>Transaction Reporting Categories</Heading>
        <Heading size={6}>
          Note: Modifying these values will update the transaction categories of existing
          transactions!
        </Heading>
        <Table>
          <thead>
            <tr>
              <th>Transaction Category</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <TransactionReportingCategoryRow
                key={value.id}
                transactionReportingCategoryData={value}
                index={index}
                endpoint={props.endpoint} onTransactionReportingCategoryUpdate={onTransactionReportingCategoryUpdate}
              />
            ))}
          </tbody>
        </Table>
      </Box>
    </div>
  );
}

export default TransactionReportingCategoryView;
