import FilterHeading from "../../../Invoicing/FilterHeading";
import React from "react";

function ActiveHeadings({data, activeTab, vehicleStatus, filters, setPage, setFilters}) {

  return (<tr>
    <th>Registration</th>
    {activeTab === 'fleet' &&
    <th>Fleet Number</th>
    }
    {activeTab === 'customer' &&
    <FilterHeading
      key={"owner__display_name"}
      heading={{label: "Owner", field: "owner", labelField: "owner__display_name"}}
      setFilters={f => {
        setPage(1);
        setFilters(f);
      }}
      filters={filters}
      options={data.filter_list['owner']}
    />
    }
    <FilterHeading
      key={"make"}
      heading={{label: "Make", field: "make", labelField: "make"}}
      setFilters={f => {
        setPage(1);
        setFilters(f);
      }}
      filters={filters}
      options={data.filter_list['make']}
    />
    <FilterHeading
      key={"model"}
      heading={{label: "Model", field: "model", labelField: "model"}}
      setFilters={f => {
        setPage(1);
        setFilters(f);
      }}
      filters={filters}
      options={data.filter_list['model']}
    />
    <th>Chassis No.</th>
    <th>Type</th>
    <th>Remaining Capital</th>
    <th>Depreciated Value</th>
    <th>Tax Cost</th>
    <th>Purchase Date</th>
  </tr>)
}

export default ActiveHeadings;
