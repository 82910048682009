import React from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import { Field } from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import { createSetContactDataAction } from "../../actions/dataActions";
import { useDispatch } from "react-redux";

export default function TypeChangeModal({ open, setOpen, targetType }) {
  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    dispatch(createSetContactDataAction({ contact_type: targetType }));
    setOpen(false);
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">Are you sure?</Heading>
          <Columns>
            <Columns.Column>
              <p className="is-centered">
                You are updating an existing contact - Are you sure you want to
                change the contact type?
              </p>
              <p>
                {" "}
                If a contact is both a supplier and a customer, you should
                create two separate contacts.
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="danger"
                fullwidth
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="success" fullwidth onClick={onConfirm}>
                Confirm
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
