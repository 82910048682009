import { createSetPartsDataAction } from "../actions/dataActions";
import { useGetAndSet } from "./useGetAndSet";

export const usePartsData = (params, dataFresh, nofetch) => {
  return useGetAndSet(
    "parts",
    params,
    createSetPartsDataAction,
    dataFresh,
    nofetch
  );
};
