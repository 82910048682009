import {
  Input,
  Label,
  Control,
  Field
} from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import React, { useState } from "react";
import Icon from "react-bulma-components/lib/components/icon";

function useInput({ type, label, initialValue, currency, onChange, size, ...inputProps}) {
  initialValue = initialValue || (type === "date" ? undefined : "");
  const onChangeFunc = onChange ? function(e) { setValue(e.target.value); onChange(e)} : (e => setValue(e.target.value))
  const [value, setValue] = useState(initialValue);
  const input = (
    <Columns.Column size={size}>
      <Field>
        <Label>{label}</Label>
        <Control iconLeft={!!currency}>
          <Input
            value={value}
            onChange={onChangeFunc}
            type={type}
            {...inputProps}
          />
          {currency && <Icon align="left" icon="pound-sign" />}
        </Control>
      </Field>
    </Columns.Column>
  );
  return [value, input, setValue];
}

export default useInput;
