import {createSetRecurringPaymentsDataAction} from "../actions/dataActions";
import {useGetAndSet} from "./useGetAndSet";

export const useRecurringPaymentData = (params, dataFresh, nofetch) => {
  return useGetAndSet(
      "recurringpayments",
      params,
      createSetRecurringPaymentsDataAction,
      dataFresh,
      nofetch
  );
};
