import React, { useState } from "react";
import shortid from "shortid";
import Table from "react-bulma-components/lib/components/table";
import Box from "react-bulma-components/lib/components/box";
import Part from "./Part";
import Button from "react-bulma-components/lib/components/button";
import NotificationManager from "react-notifications/lib/NotificationManager";

import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Control,
  Input,
  Label,
  Checkbox
} from "react-bulma-components/lib/components/form";
import { Icon } from "react-bulma-components";
import { Link } from "react-router-dom";
import {getPartCustomerCost, getWorkItemEstimateCustomerCost} from "../../utils";
import ModifyQuoteModal from "./ModifyQuoteModal";

function WorkSection(props) {
  const [modifyQuoteModalOpen, setModifyQuoteModalOpen] = useState(false);
  const handleChange = e => {
    const newWorkItem = getNewWorkItem(e);
    props.update_work(newWorkItem, props.index);
  };

  const getNewWorkItem = e => {
    let chargeable =
      e.target.name === "chargeable"
        ? e.target.value
        : props.workItem.chargeable;
    let customer = chargeable ? props.hireCustomer : props.customer;

    let newWorkItem = { ...props.workItem, [e.target.name]: e.target.value };
    if (
      e.target.name === "estimated_labour_hours" ||
      e.target.name === "chargeable"
    ) {
      newWorkItem = getWorkItemEstimateCustomerCost(props.vehicle, props.supplier, newWorkItem, customer, props.appDefaults.default_labour_estimate_rate)
    }
    return newWorkItem;
  };

  const readyForInvoicing = () => {
    if (
      !props.workItem.job_workshop_invoice_number ||
      !props.workItem.job_approved
    ) {
      return false;
    }
    for (let i = 0; i < props.workItem.parts.length; i++) {
      if (
        !props.workItem.parts[i].part_invoice_number &&
        !props.workItem.parts[i].from_stock &&
        !props.workItem.parts[i].backorder
      ) {
        return false;
      }
    }
    return true;
  };

  const onToggleOriginalQuote= () => {
    let newWorkItem = {
      ...props.workItem,
      added_after_quote: !props.workItem.added_after_quote
    };
    props.update_work(newWorkItem, props.index);
  };

  const onCheck = () => {
    const newWorkItem = getNewWorkItem({
      target: {
        name: "chargeable",
        value: !props.workItem.chargeable
      }
    });
    newWorkItem.parts = props.workItem.parts.map(part => {
      return {
        ...part,
        customer_cost: getPartCustomerCost(
          part,
          newWorkItem.chargeable ? props.hireCustomer : props.customer,
          props.appDefaults
        )
      };
    });
    props.update_work(newWorkItem, props.index);
  };

  const addPart = e => {
    props.update_work(
      {
        ...props.workItem,
        parts: props.workItem.parts.concat({
          key: shortid.generate(),
          name: "",
          cost: 0,
          estimated_cost: 0,
          supplier: {},
          date_ordered: "",
          part_invoice_number: "",
          part_number: "",
          from_stock: false,
          backorder:false,
          to_be_ordered: false,
          cost_override: false,
          customer_cost: 0,
          estimated_customer_cost: 0
        })
      },
      props.index
    );
  };

  const updateParts = (part, index) => {
    let newParts = [...props.workItem.parts];
    newParts.splice(index, 1, part);
    const newWorkItem = {
      ...props.workItem,
      parts: newParts
    };
    props.update_work(newWorkItem, props.index);
  };

  const removePart = (index, part) => {
    if (!part.part) {
      let newParts = [...props.workItem.parts];
      newParts.splice(index, 1);
      const newWorkItem = {
        ...props.workItem,
        parts: newParts
      };
      props.update_work(newWorkItem, props.index);
    } else {
      NotificationManager.error(
        "This Part is linked to a Job Card. You must delete the Part on the Job Card first!"
      );
    }
  };

  let allMatches = props.workItem.estimate_matches_job;

  let className = props.workItem.work ? "box-border-amber" : "";
  className = readyForInvoicing() ? "box-border-green" : className;
  className = props.workItem.work && !allMatches ? "box-border-red" : className;

  return (
    <Box className={className}>
      <Columns>
        <Columns.Column>
          {props.workItem.job ? (
            <Link to={`/editjob/${props.workItem.job}`}>
              <Button>Job Card - {props.workItem.workshop}</Button>
            </Link>
          ) : (
            <p>No Job Card has been created for this item!</p>
          )}
        </Columns.Column>
        <Columns.Column>
          {readyForInvoicing() ? (
            <p>This item is ready for invoicing</p>
          ) : (
            <p>This item is not ready for invoicing</p>
          )}
        </Columns.Column>
        <Columns.Column>
          {allMatches ? (
            <p>The job card associated with this item matches the estimate</p>
          ) : props.workItem.work ? (
            <p>
              The job card associated with this item does not match the
              estimate!
            </p>
          ) : (
            ""
          )}
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <Field>
            <Control>
              <Label>Description</Label>
              <Input
                data-testid="description"
                type="text"
                name="description"
                onChange={handleChange}
                value={props.workItem.description}
                required
              />
            </Control>
          </Field>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <Field>
            <Control>
              <Label>Charge Customer 2</Label>
              <Checkbox
                type="checkbox"
                name="chargeable"
                onChange={onCheck}
                checked={props.workItem.chargeable}
                disabled={!props.hireCustomer}
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Control>
              <Label>Est. Labour Hours</Label>
              <Input
                data-testid="estimated_labour_hours"
                className="input"
                type="number"
                name="estimated_labour_hours"
                onChange={handleChange}
                value={String(props.workItem.estimated_labour_hours)}
                disabled={props.finalized}
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Est. Labour Cost</Label>
            <Control iconLeft>
              <Input
                className="input"
                type="number"
                name="estimated_labour_cost"
                onChange={handleChange}
                value={String(props.workItem.estimated_labour_cost)}
                disabled={props.finalized}
              />
              <Icon icon="pound-sign" align="left" />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Est. Labour Charge</Label>
            <Control iconLeft>
              <Input
                className="input"
                type="number"
                name="estimated_customer_labour_cost"
                onChange={handleChange}
                value={String(props.workItem.estimated_customer_labour_cost)}
                disabled={props.finalized}
              />
              <Icon icon="pound-sign" align="left" />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Control>
              <Label>Actual Labour Hours</Label>
              <Input
                data-testid="labour_hours"
                className="input"
                type="number"
                name="labour_hours"
                value={String(props.workItem.labour_hours)}
                disabled={true}
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Actual Labour Cost</Label>
            <Control iconLeft>
              <Input
                className="input"
                type="number"
                name="labour_cost"
                onChange={handleChange}
                value={String(props.workItem.labour_cost)}
                disabled={true}
              />
              <Icon icon="pound-sign" align="left" />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Actual Labour Charge</Label>
            <Control iconLeft>
              <Input
                className="input"
                type="number"
                name="customer_labour_cost"
                value={String(props.workItem.customer_labour_cost)}
                onChange={handleChange}
                disabled={true}
              />
              <Icon icon="pound-sign" align="left" />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Workshop Invoice No</Label>
            <Control>
              <Input
                className="input"
                type="text"
                name="job_workshop_invoice_number"
                value={props.workItem.job_workshop_invoice_number}
                disabled
              />
            </Control>
          </Field>
        </Columns.Column>
      </Columns>
      <Table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Estimated Cost</td>
            <td>Estimated Charge</td>
            <td>Supplier</td>
            <td>Part Number</td>
            <td>Invoice No</td>
            <td>Actual Cost</td>
            <td>Actual Charge</td>
            <td>Stock Accrual</td>
            <td>Backorder</td>
            <td>To Be Ordered</td>
            <td>Remove</td>
          </tr>
        </thead>
        <tbody data-testid="parts">
          {props.workItem.parts.map((part, index) => (
            <Part
              key={part.id || part.key}
              index={index}
              part={part}
              job={props.job}
              updateParts={updateParts}
              removePart={removePart}
              appDefaults={props.appDefaults}
              customer={
                props.workItem.chargeable ? props.hireCustomer : props.customer
              }
              onSearchPartClick={(part, partIndex) =>
                props.onSearchPartClick(
                  part,
                  partIndex,
                  props.index,
                  props.customer
                )
              }
              finalized={props.finalized}
            />
          ))}
        </tbody>
      </Table>
      <Button color="primary" onClick={addPart} data-testid="add-part">
        Add Part
      </Button>
      <Columns className="is-pulled-right">
        <Columns.Column>
          <Button
            color="warning"
            data-testid="add-to-quote"
            className="is-pulled-right"
            onClick={setModifyQuoteModalOpen}
            disabled={props.finalized}
          >
            {props.additional ? "Add to Quote" : "Remove From Quote"}
          </Button>
        </Columns.Column>
        <Columns.Column>
          <Button
            color="danger"
            data-testid="remove-work"
            className="is-pulled-right remove-work"
            onClick={() => props.remove_work_item(props.index, props.workItem)}
            disabled={!props.additional && props.finalized}
          >
            Remove Work
          </Button>
        </Columns.Column>
      </Columns>
      <ModifyQuoteModal
        open={modifyQuoteModalOpen}
        setOpen={setModifyQuoteModalOpen}
        onAction={onToggleOriginalQuote}
      />
    </Box>
  );
}

export default WorkSection;
