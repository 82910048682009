import { useDispatch } from "react-redux";
import { useHttp } from "./useHttp";
import { createSetJobAction } from "../actions/dataActions";

export const useJobData = (id, dataFresh) => {
  let nofetch = !id;
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    `jobs/${id}`,
    "get",
    {},
    dataFresh,
    nofetch
  );
  if (error) {
    throw Error("There was an error");
  }
  if (!loading && data !== null) {
    dispatch(createSetJobAction(data));
  } else {
    dispatch(
      createSetJobAction({
        vehicle: {},
        comments: "",
        workshop: {},
        customer: {},
        start_date: "",
        book_in_date: "",
        work_items: [],
        comments_list: [],
        customer_vehicle: false
      })
    );
  }
  return !loading;
};
