import React, { useState } from "react";
import {
  Field,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createSetDataFreshAction } from "../../../actions/dataActions";
import { getDataFresh } from "../../../state/selectors";
import Icon from "react-bulma-components/lib/components/icon";

function QuickLinkRow({
  quickLinkData,
  index,
  onQuickLinkUpdate,
  endpoint
}) {
  const dispatch = useDispatch();
  const [quickLink, setQuickLink] = useState(quickLinkData);
  const [editable, setEditable] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  const submitUpdate = e => {
    e.stopPropagation();
    const url = endpoint + "quicklink/" + quickLink.id;
    const conf = {
      method: "put",
      data: quickLink,
      url
    };

    axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onChange = e => {
    let newQuickLink = { ...quickLink };
    newQuickLink[e.target.name] = e.target.value || null;
    setQuickLink(newQuickLink);
    onQuickLinkUpdate(index, newQuickLink);
  };

  const onEdit = e => {
    e.stopPropagation();
    setEditable(!editable);
  };

  const onDelete = e => {
    const url = endpoint + "quicklink/" + quickLink.id;
    const conf = { method: "delete", url };
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  return (
    <tr key={quickLinkData.id}>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control>
            <Input
              onChange={onChange}
              type="text"
              name="title"
              value={quickLink.title}
              disabled={!editable}
            />
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td>
        <Field onClick={e => e.stopPropagation()} className="has-addons">
          <Control iconLeft>
            <Input
              onChange={onChange}
              type="text"
              name="url"
              value={
                editable
                  ? quickLink.url
                  : quickLink.url
              }
              disabled={!editable}
            />
          </Control>
          {editable ? (
            <Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Control>
          ) : (
            <Control>
              <Button onClick={onEdit}>Edit</Button>
            </Control>
          )}
        </Field>
      </td>
      <td className={"is-narrow"}>
        <Button color={"danger"} onClick={onDelete}>
          Delete X
        </Button>
      </td>
    </tr>
  );
}

export default QuickLinkRow;
