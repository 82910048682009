import { useHttp } from "./useHttp";
import { useDispatch } from "react-redux";
import { createSetTransactionCategoriesDataAction } from "../actions/dataActions";

export const useTransactionCategoryData = (params, dataFresh) => {
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    "transactioncategory",
    "get",
    params,
    dataFresh
  );
  if (error) {
    throw Error("There was an error");
  }

  if (!loading && data !== null) {
    dispatch(createSetTransactionCategoriesDataAction(data));
  }

  return !loading;
};
