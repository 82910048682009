import axios from "axios";

export const updateHireCustomerInvoice = (endpoint, job, invoiceNumber) => {
  // get existing job
  const url = endpoint + "jobs/" + job;
  const conf = {
    method: "get",
    url
  };

  return axios(conf).then(resp => {
    // update invoice number
    let jobData = resp.data;
    jobData.customer2_invoice_number =
      invoiceNumber || jobData.customer2_invoice_number;
    const putConf = {
      method: "put",
      url,
      data: jobData
    };
    return axios(putConf);
  });
};

export const updateBothInvoices = (endpoint, job, invoiceNumber) => {
  // get existing job
  const url = endpoint + "jobs/" + job;
  const conf = {
    method: "get",
    url
  };

  return axios(conf).then(resp => {
    // update invoice number
    let jobData = resp.data;
    jobData.customer2_invoice_number =
      invoiceNumber || jobData.customer2_invoice_number;
    jobData.customer_invoice_number =
      invoiceNumber || jobData.customer_invoice_number;
    const putConf = {
      method: "put",
      url,
      data: jobData
    };
    return axios(putConf);
  });
};

export const updateCustomerInvoice = (endpoint, job, invoiceNumber) => {
  // get existing job
  const url = endpoint + "jobs/" + job;
  const conf = {
    method: "get",
    url
  };

  return axios(conf).then(resp => {
    // update invoice number
    let jobData = resp.data;
    jobData.customer_invoice_number =
      invoiceNumber || jobData.customer_invoice_number;
    const putConf = {
      method: "put",
      url,
      data: jobData
    };
    return axios(putConf);
  });
};

export const updateWorkshopInvoice = (
  endpoint,
  job,
  invoiceNumber,
  paymentDate
) => {
  // get existing job
  const url = endpoint + "jobs/" + job;
  const conf = {
    method: "get",
    url
  };

  return axios(conf).then(resp => {
    // update invoice number
    let jobData = resp.data;
    jobData.workshop_invoice_number =
      invoiceNumber || jobData.workshop_invoice_number;
    jobData.workshop_invoice_payment_date =
      paymentDate === "" ? jobData.workshop_invoice_payment_date : paymentDate;

    jobData.work_items = resp.data.work_items.map(work_item => {
      let toSave = { ...work_item };
      toSave.parts = work_item.parts.map(part => {
        let partToSave = { ...part };
        if (part.supplier.id === jobData.workshop.id) {
          partToSave.part_invoice_number =
            invoiceNumber || partToSave.part_invoice_number;
        }
        return partToSave;
      });
      return toSave;
    });

    const putConf = {
      method: "put",
      url,
      data: jobData
    };
    return axios(putConf);
  });
};
