import React, { Component } from "react";
import Panel from "react-bulma-components/lib/components/panel";
import Button from "react-bulma-components/lib/components/button";

class AvailablePanel extends Component {
  state = {
    vehicles: this.props.vehicles || []
  };

  goToVehiclePage = id => {
    window.location.href = `/editvehicle/${id}`;
  };
  render() {
    return (
      <Panel className="scrollable-panel">
        <Panel.Header>Vehicles Available</Panel.Header>
        {this.props.vehicles.map(vehicle => (
          <Panel.Block key={vehicle.id}>
            <Button onClick={() => this.props.selectVehicle(vehicle)}>
              Select
            </Button>
            <div>
              {vehicle.registration} - {vehicle.make} - {vehicle.model} -{" "}
              {vehicle.type}
            </div>
            <Button
              className="is-pulled-right"
              onClick={() => this.goToVehiclePage(vehicle.id)}
            >
              Vehicle Page
            </Button>
          </Panel.Block>
        ))}
      </Panel>
    );
  }
}

export default AvailablePanel;
