import React from "react";
import {useQuoteData} from "../../hooks/useQuoteData";
import Form from "./Form";
import { getDataFresh } from "../../state/selectors";
import { useSelector } from "react-redux";
import { useDefaultsData } from "../../hooks/useDefaultsData";

function Quote(props) {
  let dataFresh = useSelector(getDataFresh);
  let quote_loaded = useQuoteData(props.match.params.quote, dataFresh);
  let app_defaults_loaded = useDefaultsData({});
  return (
    <Form
      loaded={quote_loaded && app_defaults_loaded}
      endpoint={props.endpoint}
      history={props.history}
    />
  );
}

export default Quote;
