import {
  createSetTransactionFiguresDataAction,
} from "../actions/dataActions";
import {useGetAndSet} from "./useGetAndSet";

export const useTransactionFiguresData = (params, dataFresh) => {
 const transactionsLoading = useGetAndSet(
    "transactions/figures",
    params,
    createSetTransactionFiguresDataAction,
    dataFresh
  );

  return transactionsLoading;
};
