import React from "react";
import Table from "react-bulma-components/lib/components/table";
import { readableDate } from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import Columns from "react-bulma-components/lib/components/columns";

class PrintJob extends React.Component {
  PartRow(part) {
    return (
      <tr key={`p${part}`}>
        <td>{part}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }

  WorkRow(work, partName) {
    let rows = [];
    rows.push(
      <tr key={work.id} className="table-print-highlight">
        {/*<td>{partName}</td>*/}
        <td>{work.description}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
    return rows;
  }

  render() {
    const parts = [];
    const totals = this.props.data.work_items.reduce(
      (sum, curr) => {
        return {
          labour_cost: 1 * sum.labour_cost + 1 * curr.labour_cost,
          cost:
            1 * sum.cost +
            curr.parts.reduce((partsSum, currentPart) => {
              parts.push(currentPart.name);
              return 1 * partsSum + 1 * currentPart.cost;
            }, 0)
        };
      },
      { labour_cost: 0, cost: 0 }
    );

    parts.sort().reverse();

    let rows = Object.entries(this.props.data.work_items)
      .sort((a, b) => a[0].description < b[0].description)
      .map(entry => this.WorkRow(entry[1], parts.pop()));

    // if (parts.length) {
    //   parts.forEach(part => {
    //     rows.push(this.PartRow(part));
    //   });
    // }

    const job = this.props.data;
    const totalRows =
      this.props.data.work_items.length;
      // this.props.data.work_items.reduce((total, next) => {
      //   return total + next.parts.length;
      // }, 0);

    const noAdditionalRows = 10 - totalRows;

    const additionalRows = [];

    for (let i = 0; i < noAdditionalRows; i++) {
      additionalRows.push(
        <tr key={`a${i}`}>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }

    return (
      <div>
        <Columns>
          <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
          <h2 className="job-card-print-title">
            {job.workshop && job.workshop.display_name} -{" "}
            {job.vehicle.registration}{job.vehicle.vin_number ? '/' + job.vehicle.vin_number : ""} - Workshop Date:{" "}
            {job.book_in_date && readableDate(job.book_in_date)}
          </h2>
        </Columns>

        <Columns>
          <Columns.Column className="is-one-quarter">
            <Table bordered>
              <tbody>
                <tr>
                  <td className="header-row">Booked In Date</td>
                  <td>{readableDate(job.start_date)}</td>
                </tr>
                <tr>
                  <td className="header-row">Make</td>
                  <td>{job.vehicle.make}</td>
                </tr>
                <tr>
                  <td className="header-row">Model</td>
                  <td>{job.vehicle.model}</td>
                </tr>
                <tr>
                  <td className="header-row">Customer</td>
                  <td>{job.customer ? job.customer.display_name : ""}</td>
                </tr>
                <tr>
                  <td className="header-row">Customer 2</td>
                  <td>
                    {job.customer2 ? job.customer2.display_name : ""}
                  </td>
                </tr>
                <tr>
                  <td className="header-row">Labour Cost</td>
                  <td>
                    £
                    {totals.labour_cost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="header-row">Part Cost</td>
                  <td>
                    £
                    {totals.cost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="header-row">Total Cost</td>
                  <td>
                    £
                    {(totals.labour_cost + totals.cost).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="header-row">Workshop Charge</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="header-row">Workshop Material Charge</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="header-row">Workshop Sundries</td>
                  <td></td>
                </tr>
              <tr>
                <td className="header-row">Job No.</td>
                <td>{job.id}</td>
              </tr>
              </tbody>
            </Table>
          </Columns.Column>
          <Columns.Column>
            <div>
              <Table bordered>
                <thead>
                  <tr className="header-row">
                    <td>Job Description</td>
                    <td>Comments</td>
                    <td style={{fontSize: "small"}}>Work Supervisor<br/>Signature</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{job.job_description}</td>
                    <td colSpan={2}>{job.comments_list.length > 0 ? job.comments_list[job.comments_list.length -1].text : ""}</td>
                  </tr>
                </tbody>
              </Table>
              <Table bordered>
                <tbody>
                  <tr>
                    <th className="narrow-column">Fitter Name</th>
                    <td></td>
                    <th className="narrow-column">Mileage</th>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
              <Table bordered>
                <thead>
                  <tr className="header-row">
                    <td className="double-wide-column">Items</td>
                    <td className="narrow-column">Item Done</td>
                    <td className="narrow-column">Item Not Done</td>
                    <td className="narrow-column">QC</td>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(this.props.data.work_items).length > 0 &&
                    rows}
                  {additionalRows.map(row => row)}
                </tbody>
              </Table>
            </div>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}

export default PrintJob;
