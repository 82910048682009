import React, {useState} from "react";
import {
  Field,
  Control,
  Checkbox
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {getDataFresh} from "../../state/selectors";
import {NotificationManager} from "react-notifications";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useSafeSave from "../../hooks/useSafeSave";
import FAIcon from "../Icon/FAIcon";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import CreatePartModal from "../PartLookup/CreatePartModal";
import {readableDate} from "../../utils";
import useEditableTableTimeCell from "../../hooks/useEditableTableTimeCell";

const AsyncTypeahead = asyncContainer(Typeahead);

function DiaryRow({
                    entry,
                    index,
                    onEntryUpdate,
                    endpoint,
                    handleDelete,
                    handleCompleted,
                    showCompleted
                  }) {
  const [editable, setEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  const dateFields = ["start_date", "end_date"];

  const fixEmptyDates = entryData => {
    dateFields.forEach(function (date) {
      if (entryData[date] === "") {
        entryData[date] = null;
      }
    });
    return entryData;
  };

  const submitUpdate = () => {
    const url = endpoint + "diaryentry/" + entry.id;
    const data = fixEmptyDates(entry);
    const conf = {
      method: "put",
      data,
      url
    };
    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });

  };

  const checkUpdated = () => {
    return axios.get(endpoint + "diaryentry/" + entry.id).then(newEntry => {
      return newEntry.data.last_saved === entry.last_saved;
    });
  };

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newEntry = {...entry};
    newEntry[e.target.name] = e.target.value;
    onEntryUpdate(index, newEntry);
  };

  const [makeDirty, safeSave] = useSafeSave(
    submitUpdate,
    lastEdited,
    undefined,
    checkUpdated
  );

  return (
    <tr key={entry.id}>
      {useEditableTableDateCell({
        propName: "start_date",
        value: entry.start_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableTimeCell({
        propName: "time",
        value: entry.time,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableTextAreaCell({
        propName: "task_for",
        value: entry.task_for,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableTextAreaCell({
        propName: "comments",
        value: entry.comments,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true,
        doubleWide: true
      })}
      {useEditableTableDateCell({
        propName: "end_date",
        value: entry.end_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {showCompleted &&
      <td>
        <Field>
          <Control>
            <Checkbox
              checked={entry.completed}
              type="checkbox"
              name="completed"
              disabled
              readOnly
            />
          </Control>
        </Field>
      </td>
      }
      <td className="float-right">
        {!entry.completed && (
          <Button
            color="success"
            className="is-pulled-right"
            onClick={() => {
              handleCompleted(entry);
            }}
          >
            Mark As Completed
          </Button>
        )}
      </td>
      <td className="float-right">
        <Button
          color="danger"
          className="is-pulled-right"
          onClick={() => {
            handleDelete(entry.id);
          }}
        >
          Delete X
        </Button>
      </td>
    </tr>
  );
}

export default DiaryRow;
