import { useDispatch } from "react-redux";
import { useHttp } from "./useHttp";
import { createSetQuoteAction } from "../actions/dataActions";

export const useQuoteData = (id, dataFresh) => {
  let nofetch = !id;
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    `quotes/${id}`,
    "get",
    {},
    dataFresh,
    nofetch
  );
  if (error) {
    throw Error("There was an error");
  }
  if (!loading && data !== null) {
    dispatch(createSetQuoteAction(data));
  } else {
    dispatch(
      createSetQuoteAction({
        vehicle: {},
        comments: "",
        workshop: {},
        customer: {},
        need_date: "",
        start_date: "",
        estimated_work_items: [],
        comments_list: [],
        customer_vehicle: false
      })
    );
  }
  return !loading;
};
