import { createSetDiaryDataAction } from "../actions/dataActions";
import { useGetAndSet } from "./useGetAndSet";

export const useDiaryData = (params, dataFresh) => {
  return useGetAndSet(
    "diaryentries",
    params,
    createSetDiaryDataAction,
    dataFresh
  );
};
