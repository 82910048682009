import React from "react";
import Figure from "../Homepage/Figure";
import Columns from "react-bulma-components/lib/components/columns";
import { getWorkTotals } from "../../utils";

function WorkTotals({
  workItems,
  allItems,
  chargeable,
  labourFieldName,
  partFieldName
}) {
  const work = allItems
    ? workItems
    : workItems.filter(item => item.chargeable === chargeable);

  const totals = getWorkTotals(work, labourFieldName, partFieldName);

  return (
    <div>
    <Columns className="small-figure">
      <Figure label="Labour" loaded={true} value={totals[labourFieldName]} />
    </Columns>
      <Columns className="small-figure">
      <Figure label="Parts" loaded={true} value={totals[partFieldName]} />
    </Columns>
      <Columns className="small-figure">
      <Figure
        label="Total"
        loaded={true}
        value={totals[labourFieldName] + totals[partFieldName]}
      />
    </Columns>
      <Columns className="small-figure">
        <Figure
          label="Total Inc. VAT"
          loaded={true}
          value={(totals[labourFieldName] + totals[partFieldName]) * 1.2}
        />
      </Columns>
    </div>
  );
}

export default WorkTotals;
