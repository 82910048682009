import React, {useState} from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Table from "react-bulma-components/lib/components/table";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import {
  Control,
  Field,
  Input,
  Label,
  Select
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import {Icon} from "react-bulma-components";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import {NotificationManager} from "react-notifications";
import {getQueryString} from "../../utils";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function CreatePartModal({open, setOpen, endpoint, historicPart, vehicleTypes}) {
  const prefilledPart = historicPart ? {
    make: historicPart.job ? historicPart.job.vehicle.make : historicPart.quote.vehicle.make,
    model: historicPart.job ? historicPart.job.vehicle.model : historicPart.quote.vehicle.model,
    name: historicPart.name,
    part_number: historicPart.part_number,
    supplier: historicPart.supplier,
    cost: historicPart.cost,
    vehicle_type: historicPart.job ? historicPart.job.vehicle.type : historicPart.quote.vehicle.type

  } : {vehicle_type: vehicleTypes[0]}

  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  let [part, setPart] = useState(prefilledPart || {});
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const typeFields = vehicleTypes;
  const typeObjects = typeFields.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const handleTypeUpdate = (e, index) => {
    const toUpdate = {
      target: {
        name: "vehicle_type",
        value: typeObjects[e.target.value]
      }
    };
    handleChange(toUpdate, index);
  };


  const onClickAction = () => {
    if (checkAllFieldsFilled()) {
      save();
      setPart({})
      setOpen(false);
    } else {
      NotificationManager.error("Please fill in all fields", "Error!", 10000);
    }
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  }

  const handlePartSupplierSelect = supplier => {
    let newPart = {...part}
    newPart.supplier = supplier[0];
    setPart(newPart);
  };

  const handleChange = (e, index) => {
    let newPart = {...part};
    newPart[e.target.name] = e.target.value;
    setPart(newPart);
  };

  const checkAllFieldsFilled = () => {
    if (
      false
    ) {
      return false;
    }
    return true;
  };

  const save = () => {
    const url = endpoint + "partlookup";
    const queryString = getQueryString({
      name: part.name,
      part_number: part.part_number,
      make: part.make,
      model: part.model,
      vehicle_type: part.vehicle_type.id
    });
    return axios.get(`${endpoint}partlookup${queryString}`).then((response) => {
      if(response.data.results.length === 0) {
        const conf = {
          method: "post",
          data: part,
          url
        };
        //
        return axios(conf).then(() => {
          dispatch(createSetDataFreshAction(dataFresh + 1));
          setOpen(false);
        });
      } else {
        NotificationManager.warning("This part already exists!")
      }
    });

  };

  return (
    <Modal
      closeOnBlur
      show={open}
      nClick={e => {e.stopPropagation()}}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content className="wider-modal">
        <Section style={{backgroundColor: "white"}}>
          <Heading className="is-centered">Create New Part Lookup</Heading>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>Make</Label>
                <Control>
                  <Input
                    type="text"
                    name="make"
                    onChange={handleChange}
                    value={part.make || ""}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Model</Label>
                <Control>
                  <Input
                    type="text"
                    name="model"
                    onChange={handleChange}
                    value={part.model || ""}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Control>
                  <Label>Type</Label>
                  <Select
                    onChange={handleTypeUpdate}
                    name="vehicle_type"
                    value={part.vehicle_type ? part.vehicle_type.id : typeFields[0].id}
                  >
                    {typeFields.map(value =>
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    )}
                  </Select>
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>Part Name</Label>
                <Control>
                  <Input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={part.name || ""}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Part Number</Label>
                <Control>
                  <Input
                    type="text"
                    name="part_number"
                    onChange={handleChange}
                    value={part.part_number || ""}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Supplier</Label>
                <Control>
                  <AsyncTypeahead
                    id="typeahead"
                    labelKey="display_name"
                    minLength={2}
                    name={"supplier"}
                    onSearch={handleSearch}
                    onChange={handlePartSupplierSelect}
                    placeholder={"Search Supplier"}
                    options={searchData}
                    ref={typeahead => setTypeahead(typeahead)}
                    className="typeahead"
                    isLoading={isLoading}
                    selected={part.supplier && part.supplier.id ? [part.supplier] : []}
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Cost</Label>
                <Control className="has-icons-left">
                  <Input
                    type="text"
                    name="cost"
                    onChange={handleChange}
                    value={part.cost || ""}
                    required
                  />
                  <Icon align="left" icon="pound-sign" />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  setPart({});
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="danger" fullwidth onClick={onClickAction}>
                Save Part Lookup
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
