import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import AvailablePanel from "./AvailablePanel";
import RentalsPanel from "./RentalsPanel";
import VehicleFinder from "./VehicleFinder";

export default class VehicleFinderSection extends Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired
  };
  state = {
    vehicle: this.props.vehicle,
    searchData: [],
    isLoading: false,
    type: "",
    availableVehicles: []
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = e => {};

  handleVehicleSelect = e => {
    this.props.selectVehicle(e[0]);
    this.typeahead.getInstance().clear();
    // this.props.vehicleUpdate(e[0]);
  };

  updateAvailableVehicles = vehicles => {
    this.setState({ availableVehicles: vehicles });
  };

  render() {
    return (
      <Box>
        <Columns>
          <Columns.Column size="one-fifth">
            <Heading>Vehicle Finder</Heading>
            <VehicleFinder
              vehicle={{}}
              endpoint={`${this.props.endpoint}vehicles`}
              updateAvailableVehicles={this.updateAvailableVehicles}
              selectVehicle={this.props.selectVehicle}
              startDate={this.props.startDate}
              finishDate={this.props.finishDate}
            />
          </Columns.Column>
          <Columns.Column>
            <Heading>Available</Heading>
            <AvailablePanel
              vehicles={this.state.availableVehicles}
              selectVehicle={this.props.selectVehicle}
            />
          </Columns.Column>
          <Columns.Column>
            <Heading>Other Rentals</Heading>
            <RentalsPanel
              startDate={this.props.startDate}
              finishDate={this.props.finishDate}
              endpoint={`${this.props.endpoint}rentals`}
            />
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}
