import { useDispatch } from "react-redux";
import { useHttp } from "./useHttp";
import { createSetVehicleDataAction } from "../actions/dataActions";

export const useVehicleData = (id, dataFresh) => {
  let nofetch = !id;
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    `vehicles/${id}`,
    "get",
    {},
    dataFresh,
    nofetch
  );
  if (error) {
    throw Error("There was an error");
  }
  if (!loading && data !== null) {
    dispatch(createSetVehicleDataAction(data));
  } else {
    dispatch(
      createSetVehicleDataAction({ inspections: [], finance_agreements: [], finance_type: "NONE" })
    );
  }

  return !loading;
};
