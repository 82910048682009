import React from "react";
import Box from "react-bulma-components/lib/components/box/";
import Columns from "react-bulma-components/lib/components/columns";
import useInput from "../../../hooks/useInput";
import usePostInspectionType from "../../../hooks/usePostInspectionType";
import Button from "react-bulma-components/lib/components/button";
import Collapsible from "react-collapsible";

function InspectionType() {
  let [inspectionType, nameInput] = useInput({
    type: "text",
    label: "Inspection Name"
  });
  let [reminderDays, reminderInput] = useInput({
    type: "number",
    label: "Reminder (days in advance)"
  });

  reminderDays = parseFloat(reminderDays);

  const [, postInspectionType] = usePostInspectionType({
    inspection_type: inspectionType,
    reminder_days: reminderDays
  });

  const handleSubmit = () => {
    postInspectionType();
  };

  return (
    <Box className="settings_type">
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Add Inspection Type"
      >
        <Columns>
          {nameInput}
          {reminderInput}
        </Columns>
        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
    </Box>
  );
}

export default InspectionType;
