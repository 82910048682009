import {useEffect, useState} from "react";
import React from "react";
import Navbar from "react-bulma-components/lib/components/navbar";
import {useGoogleAuth} from "../GoogleContext";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import logo from "./img/Logo.jpg";
import {useCostCentreData} from "../../hooks/useCostCentreData";
import {useSelector} from "react-redux";
import {getCostCentresData, getDataFresh, getQuickLinksData} from "../../state/selectors";
import TransactionsNav from "./TransactionsNav";
import QuickLinksNav from "./QuickLinksNav";
import {useQuickLinkData} from "../../hooks/useQuickLinkData";

function Nav() {
  const {googleUser, signOut} = useGoogleAuth();
  const {push} = useHistory();
  const location = useLocation();
  const [burgerActive, setBurgerActive] = useState(false);
  const profile = googleUser ? googleUser.profileObj : null;
  const datafresh = useSelector(getDataFresh);
  const cost_centres_loaded = useCostCentreData({}, datafresh, profile);
  const quickLinksLoaded = useQuickLinkData({}, datafresh, profile);
  const cost_centre_data = useSelector(getCostCentresData);
  const quickLinksData = useSelector(getQuickLinksData);

  if (!cost_centres_loaded && !quickLinksLoaded) {
    return null;
  }

  const cost_centres =  cost_centre_data ? cost_centre_data.results : [];
  const quickLinks = quickLinksData ? quickLinksData.results : [];

  const path = location.pathname.substring(1, location.pathname.length);

  return (
    <Navbar fixed="top" className="hide-print">
      <Navbar.Brand>
        <Navbar.Item className="navbar-item">
          <img src={logo} width="110" height="74" alt="Rentals Direct Ltd"/>
        </Navbar.Item>

        <Navbar.Burger
          active={burgerActive.toString()}
          onClick={() => setBurgerActive(!burgerActive)}
        />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container>
          <Navbar.Item
            className={path === "" ? "active-tab" : ""}
            onClick={() => push("/")}
          >
            Home
          </Navbar.Item>
          <Navbar.Item
            className={path.includes("job") ? "active-tab" : ""}
            onClick={() => push("/jobs")}
          >
            Jobs
          </Navbar.Item>
          <Navbar.Item
            className={path.includes("quote") ? "active-tab" : ""}
            onClick={() => push("/quotes")}
          >
            Quotes
          </Navbar.Item>
          <Navbar.Item
            className={path === "inspections" ? "active-tab" : ""}
            onClick={() => push("/inspections")}
          >
            Inspections
          </Navbar.Item>
          <Navbar.Item
            className={path.indexOf("part") !== -1 ? "active-tab" : ""}
            dropdown
            hoverable
          >
            <Navbar.Link>Parts</Navbar.Link>
            <Navbar.Dropdown>
              <Navbar.Item
                className={path === "parthistory" ? "active-tab" : ""}
                onClick={() => push("/parthistory")}
              >
                Part History
              </Navbar.Item>
              <Navbar.Item
                className={path === "partLookup" ? "active-tab" : ""}
                onClick={() => push("/partLookup")}
              >
                Part Stock
              </Navbar.Item>
              <Navbar.Item
                className={path === "parts" ? "active-tab" : ""}
                onClick={() => push("/parts")}
              >
                Parts Invoicing
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item
            className={path === "workLookup" ? "active-tab" : ""}
            onClick={() => push("/workLookup")}
          >
            Work Lookups
          </Navbar.Item>
          <Navbar.Item
            className={(path === "invoicing" || path === "po")  ? "active-tab" : ""}
            dropdown
            hoverable
          >
            <Navbar.Link>Invoicing</Navbar.Link>
            <Navbar.Dropdown>
              <Navbar.Item
                className={path === "invoicing" ? "active-tab" : ""}
                onClick={() => push("/invoicing")}
              >
                Customer Invoicing
              </Navbar.Item>
              <Navbar.Item
                className={path === "po" ? "active-tab" : ""}
                onClick={() => push("/po")}
              >
                Workshop Accounting
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item
            className={
              path === "rentals" || path === "editrental" ? "active-tab" : ""
            }
            onClick={() => push("/rentals")}
          >
            Rentals
          </Navbar.Item>
          <Navbar.Item
            className={path.includes("vehicle") || path.includes('trackers') ? "active-tab" : ""}
            hoverable
            dropdown
          >
            <Navbar.Link>Vehicles</Navbar.Link>
            <Navbar.Dropdown>
              <Navbar.Item onClick={() => push("/vehicles")}>
                Vehicles
              </Navbar.Item>
              <Navbar.Item onClick={() => push("/trackers")}>
                Vehicle Trackers
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item
            className={path.includes("contacts") ? "active-tab" : ""}
            dropdown
            hoverable
          >
            <Navbar.Link>Contacts</Navbar.Link>
            <Navbar.Dropdown>
              <Navbar.Item onClick={() => push("/contacts")}>
                All Contacts
              </Navbar.Item>
              <Navbar.Item onClick={() => push("/contacts?type=SP")}>
                Suppliers
              </Navbar.Item>
              <Navbar.Item onClick={() => push("/contacts?type=CC")}>
                Customers
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item
            className={path === "income" ? "active-tab" : ""}
            dropdown
            hoverable
          >
            <Navbar.Link>Income</Navbar.Link>
            {profile &&
              <TransactionsNav profile={profile} transactionType='income' cost_centres={cost_centres}/>
            }
          </Navbar.Item>
          <Navbar.Item
            className={path === "expenses" ? "active-tab" : ""}
            dropdown
            hoverable
          >
            <Navbar.Link>Expenses</Navbar.Link>
            {profile &&
            <TransactionsNav profile={profile} transactionType='expenses' cost_centres={cost_centres}/>
            }
          </Navbar.Item>
          <Navbar.Item
              className={(path === "payments" || path === "recurringpayments")  ? "active-tab" : ""}
              dropdown
              hoverable
          >
            <Navbar.Link>Payments</Navbar.Link>
            <Navbar.Dropdown>
              <Navbar.Item
                  className={path === "payments" ? "active-tab" : ""}
                  onClick={() => push("/payments")}
              >
                Payments
              </Navbar.Item>
              <Navbar.Item
                  className={path === "recurringpayments" ? "active-tab" : ""}
                  onClick={() => push("/recurringpayments")}
              >
                Recurring Payments
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item
            className={path === "diary" ? "active-tab" : ""}
            onClick={() => push("/diary")}
          >
            Diary
          </Navbar.Item>
          <Navbar.Item
            className={path === "reports" ? "active-tab" : ""}
            onClick={() => push("/reports")}
          >
            Reports
          </Navbar.Item>
          <Navbar.Item
              className={path === "quicklinks" ? "active-tab" : ""}
              dropdown
              hoverable
          >
            <Navbar.Link>Quick Links</Navbar.Link>
            <QuickLinksNav quickLinks={quickLinks}/>
          </Navbar.Item>
          <Navbar.Item
            className={path === "settings" ? "active-tab" : ""}
            onClick={() => push("/settings")}
          >
            Settings
          </Navbar.Item>
        </Navbar.Container>
        <Navbar.Container position="end">
          {profile && <Navbar.Item>{profile.givenName}</Navbar.Item>}
          <Navbar.Item
            onClick={() => {
              signOut();
              localStorage.clear();
              window.location.href = "/login";
            }}
          >
            Log out
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
  // }
}

export default Nav;
