import {
  Control,
  Field,
  Input
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableNumberCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  inlineEdit,
  replaceInput,
}) {
  return (
    <td onClick={e => e.stopPropagation()}>
      <Field className="has-addons">
        <Control>
          {replaceInput ? replaceInput :
            <Input
              className="small-row-black"
              onChange={onChange}
              type="number"
              name={propName}
              value={value}
              disabled={!editable}
            />
          }
        </Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable})}
      </Field>
    </td>
  );
}

export default useEditableTableNumberCell;
