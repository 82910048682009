import { useCallback, useState } from "react";
import axios from "axios";
import { getQueryString } from "../utils";
import { createSetDataFreshAction } from "../actions/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { getDataFresh } from "../state/selectors";

function useHttpPost(resourceType, method, params, payload) {
  const dispatch = useDispatch();
  const count = useSelector(getDataFresh);

  const [res, setRes] = useState({ data: null, error: null, isLoading: false });
  const queryParams = getQueryString(params);
  const callAPI = useCallback(() => {
    setRes(prevState => ({ ...prevState, isLoading: true }));
    return axios({ method, url: `/api/${resourceType}${queryParams}`, data: payload })
      .then(res => {
        setRes({ data: res.data, isLoading: false, error: null });
        dispatch(createSetDataFreshAction(count + 1));
      })
      .catch(error => {
        setRes({ data: null, isLoading: false, error });
      });
  }, [resourceType, method, payload, dispatch, queryParams, count]);
  return [res, callAPI];
}

export default useHttpPost;
