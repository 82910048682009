import React, {useState} from "react";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Control,
  Field,
  Input,
  Label,
  Select
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import {NotificationManager} from "react-notifications";
import Button from "react-bulma-components/lib/components/button";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function CreateTrackerModal({open, setOpen, endpoint}) {
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  let [tracker, setTracker] = useState({});
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const onClickAction = () => {
    if (checkAllFieldsFilled()) {
      save();
      setTracker({})
      setOpen(false);
    } else {
      NotificationManager.error("Please fill in all fields", "Error!", 10000);
    }
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/vehicles?search=${e}&tracker=BLANK`).then(resp => {
      setSearchData(resp.data.results.map(vehicle => ({...vehicle, display_name: vehicle.registration})));
      setLoading(false);
    });
  }

  const handleVehicleSelect = vehicles => {
    let newTracker = {...tracker}
    newTracker.vehicle = vehicles[0];
    setTracker(newTracker);
  };

  const handleChange = (e, index) => {
    let newTracker = {...tracker};
    newTracker[e.target.name] = e.target.value;
    setTracker(newTracker);
  };

  const checkAllFieldsFilled = () => {
    return !(!tracker.hasOwnProperty('imei') || tracker.imei.length < 1);
  };


  // Save an existing tracker
  const save = () => {
    const url = endpoint + "vehicletracker";
    const data = {
      ...tracker
    };
    if(data.vehicle){
      const {id, registration} = tracker.vehicle;
      data.vehicle = {id, registration};
    }
    const conf = {
      method: "post",
      data: tracker,
      url
    };
    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setOpen(false);
    });
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      nClick={e => {e.stopPropagation()}}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content className="wider-modal">
        <Section style={{backgroundColor: "white"}}>
          <Heading className="is-centered">Create New Tracker</Heading>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>IMEI</Label>
                <Control>
                  <Input
                    type="text"
                    name="imei"
                    onChange={handleChange}
                    value={tracker.imei || ""}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Vehicle</Label>
                <Control>
                  <AsyncTypeahead
                      id="typeahead"
                      labelKey="display_name"
                      minLength={2}
                      name={"vehicle"}
                      onSearch={handleSearch}
                      onChange={handleVehicleSelect}
                      placeholder={"Search Vehicle Registration"}
                      options={searchData}
                      ref={typeahead => setTypeahead(typeahead)}
                      className="typeahead"
                      isLoading={isLoading}
                      selected={tracker.vehicle && tracker.vehicle.id ? [tracker.vehicle] : []}
                  />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                  color="info"
                  fullwidth
                  onClick={() => {
                    setTracker({});
                    setOpen(false);
                  }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="danger" fullwidth onClick={onClickAction}>
                Save Tracker
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
