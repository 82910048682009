import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

export default function FilterBlankHeading(props) {
  let options = [{ label: "Only Blanks", [props.heading.field]: "BLANK" }];

  return (
    <th key={props.heading.field}>
      <Typeahead
        id={props.heading.field}
        labelKey={"label"}
        onChange={sel =>
          props.setFilters({ ...props.filters, [props.heading.field]: sel })
        }
        multiple={false}
        clearButton
        options={options}
        placeholder={props.heading.label}
        selected={props.filters[props.heading.field] || []}
        className="typeahead"
      />
    </th>
  );
}
