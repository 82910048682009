import { useHttp } from "./useHttp";
import { useDispatch } from "react-redux";
import { createSetCostCentresDataAction } from "../actions/dataActions";

export const useCostCentreData = (params, dataFresh, profile) => {
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    "costcentre",
    "get",
    params,
    dataFresh,
    false,
    localStorage.getItem("token")
  );
  if (error) {
    throw Error("There was an error");
  }

  if (!loading && data !== null) {
    dispatch(createSetCostCentresDataAction(data));
  }

  return !loading;
};
