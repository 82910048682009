import { createSetAppDefaultsDataAction } from "../actions/dataActions";
import { useGetAndSet } from "./useGetAndSet";

export const useDefaultsData = (params, dataFresh, nofetch) => {
  return useGetAndSet(
    "appdefaults",
    params,
    createSetAppDefaultsDataAction,
    dataFresh,
    nofetch
  );
};
