import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import Heading from "react-bulma-components/lib/components/heading";
import Table from "react-bulma-components/lib/components/table";
import { getVehiclesData } from "../../state/selectors";
import Spinner from "react-spinner-material";
import { useVehiclesData } from "../../hooks/useVehiclesData";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import CourtesyCarRow from "./CourtesyCarRow";

function CourtesyCars({ endpoint }) {
  const [page, setPage] = useState(1);
  const complete = useVehiclesData({ courtesy_car: "true", page: page });
  const data = useSelector(getVehiclesData);
  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Heading size={5}>Courtesy Cars</Heading>
      <Table>
        <thead>
          <tr>
            <th>Registration</th>
            <th>Make</th>
            <th>Model</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((value, index) => (
            <CourtesyCarRow
              key={value.id}
              value={value}
              index={index}
              endpoint={endpoint}
            />
          ))}
        </tbody>
      </Table>
      <Pagination
        onChange={page => setPage(page)}
        current={page}
        total={Math.ceil(data.count / 25)}
      >
        {" "}
      </Pagination>
    </Box>
  );
}

export default CourtesyCars;
