import React from "react";
import shortid from "shortid";
import Table from "react-bulma-components/lib/components/table";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";

import {
  Field,
  Control,
  Input,
  Label,
  Checkbox,
  Textarea
} from "react-bulma-components/lib/components/form";
import Collapsible from "react-collapsible";
import { useDispatch } from "react-redux";
import { createSetContactDataAction } from "../../actions/dataActions";

function ContactInfoSection({ contactList, propertyName, includeAddress }) {
  const dispatch = useDispatch();

  const handleChange = (e, index) => {
    let contact = contactList[index];
    contact[e.target.name] = e.target.value;
    updateContacts(contact, index);
  };

  const addContact = e => {
    let newContacts = [...contactList];
    newContacts.push({
      key: shortid.generate(),
      name: "",
      email: "",
      role: "",
      comments: "",
      address: ""
    });
    dispatch(createSetContactDataAction({ [propertyName]: newContacts }));
  };

  const updateContacts = (contact, index) => {
    let newContacts = [...contactList];
    newContacts.splice(index, 1, contact);
    dispatch(createSetContactDataAction({ [propertyName]: newContacts }));
  };

  const removeContact = index => {
    let newContacts = [...contactList];
    newContacts.splice(index, 1);
    dispatch(createSetContactDataAction({ [propertyName]: newContacts }));
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Phone Number</th>
            <th>Email</th>
            {includeAddress && <th>Address</th>}
            <th>Comments</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contactList
            .sort((a, b) => a.id > b.id)
            .map((item, index) => (
              <tr>
                <td>
                  <Control>
                    <Input
                      type="text"
                      value={item.name || ""}
                      name="name"
                      onChange={e => handleChange(e, index)}
                    />
                  </Control>
                </td>
                <td>
                  <Control>
                    <Input
                      type="text"
                      value={item.role}
                      name="role"
                      onChange={e => handleChange(e, index)}
                    />
                  </Control>
                </td>
                <td>
                  <Control>
                    <Input
                      type="text"
                      value={item.contact_number}
                      name="contact_number"
                      onChange={e => handleChange(e, index)}
                    />
                  </Control>
                </td>
                <td>
                  <Control>
                    <Input
                      type="email"
                      value={item.email}
                      name="email"
                      onChange={e => handleChange(e, index)}
                    />
                  </Control>
                </td>
                {includeAddress && (
                  <td>
                    <Control>
                      <Textarea
                        rows={2}
                        type="text"
                        value={item.address}
                        name="address"
                        onChange={e => handleChange(e, index)}
                      />
                    </Control>
                  </td>
                )}
                <td>
                  <Control>
                    <Textarea
                      rows={2}
                      type="text"
                      value={item.comments}
                      name="comments"
                      onChange={e => handleChange(e, index)}
                    />
                  </Control>
                </td>
                <td>
                  <Button
                    remove
                    onClick={() => removeContact(index)}
                    data-testid="remove-contact"
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button color="primary" onClick={addContact} data-testid="add-contact">
        Add Additional Contact
      </Button>
    </div>
  );
}

export default ContactInfoSection;
