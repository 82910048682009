import { useEffect, useState } from "react";
import axios from "axios";
import { getQueryString } from "../utils";
import { useDispatch } from "react-redux";

export const useHttpGetAndSet = (
  resourceType,
  method,
  params,
  dataFresh,
  nofetch = false,
  dataAction,
  def
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const queryParams = getQueryString(params);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (!nofetch) {
        try {
          const response = await axios({
            method,
            url: `/api/${resourceType}${queryParams}`
          });
          dispatch(dataAction(response.data));
        } catch (e) {
          if (e.message.indexOf("401") !== -1) {
            window.location.href = "/login";
          } else {
            setError(true);
          }
        }
        setLoading(false);
      } else {
        dispatch(dataAction(def));
        setLoading(false);
      }
    };
    fetchData();
  }, [resourceType, method, dataFresh, nofetch]);
  return [loading, error];
};
