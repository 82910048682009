import React from "react";
import axios from "axios";
import {getQueryString, readableDate} from "../../utils";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import useInput from "../../hooks/useInput";
import Heading from "react-bulma-components/lib/components/heading";

export default function GenerateFleetModal({
                                             modalOpen,
                                             setModalState,
                                             endpoint,
                                             onChange,
                                             initDate
                                           }) {
  const init = initDate || new Date().toISOString().substr(0, 10)
  const [date, dateInput] = useInput({
    type: "date",
    label: "Delivery Date",
    initialValue: init
  });

  const onGenerate = () => {
    const method = "get";
    const url =
      endpoint + `vehicles/generatefleetnumber${getQueryString({date})}`
    const conf = {
      method,
      url
    };
    axios(conf).then(({data}) => {
      onChange({target: {name: "fleet_number", value: data.fleet_number}});
      onChange({target: {name: "delivery_date", value: date}});
      setModalState({modalOpen: false})
    });
  };

  return     <Modal
    closeOnBlur
    show={modalOpen}
    onClose={() => setModalState({ modalOpen: false })}
  >
    <Modal.Content>
      <Section style={{ backgroundColor: "grey" }}>
        <Box>
          <Section>
          <Heading size={6}>Select a Delivery Date to use for Fleet Number generation</Heading>
          <p className="is-size-7">Fleet Numbers consist of 2 numbers for the year, followed by a 3 digit id e.g. 21001</p>
          </Section>
          <Section>
          <Columns>
            {dateInput}
          </Columns>
          <Columns>
          <p >Warning: Generating a new Fleet Number will overwrite any existing Fleet Number as well as any existing Delivery Date.</p>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="success"
                onClick={onGenerate}
              >
                Generate
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button
                color="danger"
                onClick={() => setModalState({modalOpen: false})}
              >
                Cancel
              </Button>
            </Columns.Column>
          </Columns>
          </Section>
        </Box>
      </Section>
    </Modal.Content>
  </Modal>
}
