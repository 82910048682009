import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWorkData } from "../../hooks/useWorkData";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Table from "react-bulma-components/lib/components/table";
import Pagination from "react-bulma-components/lib/components/pagination";
import {getCostCentresData, getDataFresh, getWorkData} from "../../state/selectors";
import { PORows, InvoiceRows } from "./TableRows";
import {
  createSetDataFreshAction,
  createSetWorkDataAction
} from "../../actions/dataActions";
import axios from "axios";
import { FiguresSection } from "./FiguresSection";
import ReactToPrint from "react-to-print";
import PrintPO from "./PrintPO";
import PrintInvoice from "./PrintInvoice";
import { NotificationManager } from "react-notifications";
import Modal from "react-bulma-components/lib/components/modal";
import Section from "react-bulma-components/lib/components/section";
import FilterHeading from "./FilterHeading";
import Columns from "react-bulma-components/lib/components/columns";
import Spinner from "react-spinner-material";
import useSelect from "../../hooks/useSelect";
import FilterBlankHeading from "./FilterBlankHeading";
import FAIcon from "../Icon/FAIcon";
import MultipleEditModalWorkshop from "./MultipleEditModalWorkshop";
import {
  updateBothInvoices,
  updateCustomerInvoice,
  updateHireCustomerInvoice,
  updateWorkshopInvoice
} from "./updateInvoice";
import MultipleEditModalCustomer from "./MultipleEditModalCustomer";
import ApproveModal from "./ApproveModal";
import {useCostCentreData} from "../../hooks/useCostCentreData";

function InvoicePO(props) {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [printDate, setPrintDate] = useState(new Date().toISOString());
  const [approvedCustomer, setApprovedCustomer] = useState();
  const [approvedInvoiceNumber, setApprovedInvoiceNumber] = useState();

  const [filters, setFilters] = useState({});

  const [selected, setSelected] = useState({});

  let data = useSelector(getWorkData);
  let costCentres = useSelector(getCostCentresData)
  
  const costCentreObjects = costCentres ? costCentres.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {}) : [];

  const saveMultiple = (invoiceNumber, paymentDate) => {
    const toSave = Object.values(selected).filter((item, pos, arr) => {
      return (
        arr
          .map(mapObj => mapObj.job.toString() + mapObj.chargeable.toString())
          .indexOf(item.job.toString() + item.chargeable.toString()) === pos
      );
    });

    let promises = [];

    let chargeableAndNonChargeable = [];

    toSave.forEach(work => {
      if (props.view === "po") {
        promises.push(
          updateWorkshopInvoice(
            props.endpoint,
            work.job,
            invoiceNumber,
            paymentDate
          )
        );
      } else {
        // If we are only updating one or the other
        if (toSave.filter(item => item.job === work.job).length === 1) {
          if (work.chargeable) {
            promises.push(
              updateHireCustomerInvoice(props.endpoint, work.job, invoiceNumber)
            );
          } else {
            promises.push(
              updateCustomerInvoice(props.endpoint, work.job, invoiceNumber)
            );
          }
        } else {
          // If we need to update both we don't want to overwrite one, so store them
          chargeableAndNonChargeable.push(work);
        }
      }
    });

    // Condense the ones where we update both
    const toSaveBothValues = chargeableAndNonChargeable.filter(
      (item, pos, arr) => {
        return arr.map(mapObj => mapObj.job).indexOf(item.job) === pos;
      }
    );

    toSaveBothValues.forEach(item => {
      promises.push(
        updateBothInvoices(props.endpoint, item.job, invoiceNumber)
      );
    });

    return Promise.all(promises).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setSelected({});
    });
  };

  let mapping = {
    po: {
      title: "Workshop Accounting",
      contact: "supplier",
      contactFinder: "SP",
      figures: [
        { name: "Labour Total", prop: "total_labour" },
        {
          name: "Workshop Supplied Parts Total",
          prop: "total_workshop_supplied_part_cost"
        }
      ],
      headings: [
        {
          label: "Registration",
          field: "job__vehicle",
          labelField: "job__vehicle__registration"
        },
        {
          label: "Workshop",
          field: "job__workshop",
          labelField: "job__workshop__display_name"
        },
        {
          label: "Customer",
          field: "customer",
          labelField: "customer_display_name"
        },
        {
          label: "Date",
          field: "job__finish_date",
          labelField: "job__finish_date",
          date: true
        },
        { label: "Description" },
        { label: "Labour Cost" },
        {
          label: "Workshop Invoice",
          plusBlanks: true,
          field: "job__workshop_invoice_number",
          labelField: "job__workshop_invoice_number",
        },
        {
          label: "Payment Date",
          field: "job__workshop_invoice_payment_date",
          labelField: "job__workshop_invoice_payment_date",
          date: true
        },
        { label: "Part Name" /*field: "parts__name"*/ },
        { label: "Part Number" /*, field: "parts__number"*/ },
        { label: "Supplier" },
        { label: "Part Cost" }
      ],
      approvalField: "po_approved",
      invoiceField: "job_workshop_invoice_number",
      rows: (value, index) => (
        <PORows
          key={value.id}
          value={value}
          onApprove={handleApprove}
          printCheck={printCheck}
          selected={Object.keys(selected).indexOf(value.id.toString()) !== -1}
          endpoint={props.endpoint}
          refresh={refreshData}
          setInvoiceNo={invoice_value =>
            updateWorkshopInvoiceValue(index, invoice_value)
          }
          updateValue={newWork => updateValue(index, newWork)}
          approvedView={approved === 'approved'}
        />
      ),
      print: (
        <PrintPO paymentDate={printDate} data={selected} ref={componentRef} />
      ),
      multipleEditModal: (
        <MultipleEditModalWorkshop
          open={editModalOpen}
          setOpen={setEditModalOpen}
          selected={selected}
          saveSelected={saveMultiple}
        />
      )
    },
    invoice: {
      title: "Customer Invoicing",
      contact: "customer",
      contactFinder: "CC",
      headings: [
        {
          label: "Job #"
        },
        {
          label: "Registration",
          field: "job__vehicle",
          labelField: "job__vehicle__registration"
        },
        {
          label: "Workshop",
          field: "job__workshop",
          labelField: "job__workshop__display_name"
        },
        {
          label: "Customer",
          field: "customer",
          labelField: "customer_display_name"
        },
        {
          label: "Date",
          field: "job__finish_date",
          labelField: "job__finish_date",
          date: true
        },
        { label: "Description" },
        { label: "Customer Labour Charge" },
        { label: "Workshop Invoice" },
        {
          label: "Invoice Number",
          plusBlanks: true,
          field: "job__invoice_number",
          labelField: "job__invoice_number"
        },
        { label: "Part Name" },
        { label: "Part Number" },
        { label: "Part Invoice No" },
        { label: "Supplier" },
        { label: "Customer Charge" },
        { label: "Comments" }
      ],
      figures: [
        { name: "Labour Total", prop: "total_customer_labour" },
        { name: "Parts Total", prop: "total_customer_part_cost" }
      ],
      approvalField: "invoice_approved",
      invoiceField: "job_customer_invoice_number",
      rows: (value, index) => {
        return (
          <InvoiceRows
            key={value.id}
            value={value}
            onApprove={handleApprove}
            printCheck={printCheck}
            selected={Object.keys(selected).indexOf(value.id.toString()) !== -1}
            endpoint={props.endpoint}
            refresh={refreshData}
            updateValue={newWork => updateValue(index, newWork)}
            setInvoiceNo={
              value.chargeable
                ? invoice_value =>
                    updateHireCustomerInvoiceValue(index, invoice_value)
                : invoice_value =>
                    updateCustomerInvoiceValue(index, invoice_value)
            }
            approvedView={approved === 'approved'}
          />
        );
      },
      print: (
        <PrintInvoice
          paymentDate={printDate}
          data={selected}
          ref={componentRef}
        />
      ),
      multipleEditModal: (
        <MultipleEditModalCustomer
          open={editModalOpen}
          setOpen={setEditModalOpen}
          selected={selected}
          saveSelected={saveMultiple}
        />
      )
    }
  };

  const [page, setPage] = useState(1);

  const [approved, approvedInput] = useSelect({
    label: "View",
    options: [
      { name: "Current", value: "all" },
      { name: "Approved", value: "approved" }
    ],
    initialValue: "all",
    testId: "invoice-view",
    callback: () => {
      setPage(1);
    }
  });

const [department, departmentInput] = useSelect({
    label: "Department",
    options: [
      { name: "All", value: "" },
      { name: "Rentals Direct", value: "Rentals Direct" },
      { name: "LCV Centre", value: "LCV Centre" }
    ],
    initialValue: "Rentals Direct",
    testId: "department-view",
    callback: () => {
      setPage(1);
    }
  });

  let params = {
    page,
    view: props.view
  };

  if (approved === "approved") {
    params.approved = true;
  }

  if(department) {
    params.department = department;
  }

  if (currentSearch) {
    params.search = currentSearch;
  }

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const dataFresh = useSelector(getDataFresh);
  const complete = useWorkData(params, dataFresh)

  const updateHireCustomerInvoiceValue = (index, invoiceNumber) => {
    let currentData = { ...data };
    currentData.results[index].job_customer2_invoice_number = invoiceNumber;
    dispatch(createSetWorkDataAction(currentData));
  };

  const updateCustomerInvoiceValue = (index, invoiceNumber) => {
    let currentData = { ...data };
    currentData.results[index].job_customer_invoice_number = invoiceNumber;
    dispatch(createSetWorkDataAction(currentData));
  };

  const updateWorkshopInvoiceValue = (index, invoiceNumber) => {
    let currentData = { ...data };
    currentData.results[index].job_workshop_invoice_number = invoiceNumber;
    dispatch(createSetWorkDataAction(currentData));
  };

  const updateValue = (index, newWork) => {
    let currentData = { ...data };
    currentData.results[index] = newWork;
    dispatch(createSetWorkDataAction(currentData));
  };

  // TODO: REFACTOR THIS
  const validateBeforeApproval = (work, view) => {
    if (view === "invoice") {
      if (work.chargeable && work.job_customer2_invoice_number.length > 1 && !/[A-Za-z]+/.test(work.job_customer2_invoice_number)) {
        return true;
      } else if (
        !work.chargeable &&
        work.job_customer_invoice_number.length > 1
      ) {
        return true;
      }
    } else if (view === "po") {
      if (
        work.job_workshop_invoice_number.length > 1 &&
        !/[A-Za-z]+/.test(work.job_workshop_invoice_number) &&
        work.job_workshop_payment_date
      ) {
        return true;
      }
    }
    return false;
  };

  const handleApprove = work => {
    if (validateBeforeApproval(work, props.view)) {
      work[mapping[props.view].approvalField] = true;
      const endpoint = props.endpoint + "work/approve";
      const conf = {
        method: "put",
        data: [work],
        url: endpoint
      };

      axios(conf).then(() => {
        refreshData();
        setSelected({});
        if (props.view === "invoice") {
          getCustomerAndSetApproved(getApplicableCustomer(work));
          setApprovedInvoiceNumber(getApplicableInvoiceNumber(work));
          setApproveModalOpen(true);
        }
      });
    } else {
      NotificationManager.warning(
        props.view === "po"
          ? "You must specify an invoice number (numbers only) and a payment date to continue!"
          : "You must specify an invoice number (numbers only) before approving!",
        "Invoice Number Required",
        10000
      );
    }
  };

  const getApplicableInvoiceNumber = work => {
    return work.chargeable
      ? work.job_customer2_invoice_number
      : work.job_customer_invoice_number;
  };

  const getApplicableCustomer = work => {
    return work.chargeable ? work.customer2 : work.customer;
  };

  const approveSelected = () => {
    let passValidation = true;
    const data = Object.values(selected).map(item => {
      if (validateBeforeApproval(item, props.view)) {
        return { ...item, [mapping[props.view].approvalField]: true };
      } else {
        passValidation = false;
        return {};
      }
    });

    if (passValidation) {
      const url = props.endpoint + "work/approve";
      const conf = {
        method: "put",
        data,
        url
      };

      axios(conf).then(() => {
        dispatch(createSetDataFreshAction(dataFresh + 1));
        if (props.view === "invoice") {
          const customerMatches = arr =>
            arr.every(
              item =>
                getApplicableCustomer(item) === getApplicableCustomer(arr[0])
            );
          const invoiceNumberMatches = arr =>
            arr.every(
              item =>
                getApplicableInvoiceNumber(item) ===
                getApplicableInvoiceNumber(arr[0])
            );

          const selectedArray = Object.values(selected);

          if (customerMatches(selectedArray)) {
            getCustomerAndSetApproved(getApplicableCustomer(selectedArray[0]));
          } else {
            setApprovedCustomer(null);
          }
          if (invoiceNumberMatches(selectedArray)) {
            setApprovedInvoiceNumber(
              getApplicableInvoiceNumber(selectedArray[0])
            );
          } else {
            setApprovedInvoiceNumber(null);
          }
          setApproveModalOpen(true);
        }

        setSelected({});
      });
    } else {
      NotificationManager.warning(
        props.view === "po"
          ? "You must specify an invoice number (numbers only) and a payment date to continue!"
          : "You must specify an invoice number (numbers only) before approving!",
        "Invoice Number Required",
        10000
      );
    }
  };

  const getCustomerAndSetApproved = displayName => {
    axios.get(`/api/contacts?display_name=${displayName}`).then(resp => {
      setApprovedCustomer(resp.data.results[0]);
    });
  };

  const toggleSelectVisible = () => {
    let newSelected = { ...selected };
    let selectedKeys = Object.keys(newSelected);
    const notPresent = data.results.find(
      workItem => selectedKeys.indexOf(workItem.id.toString()) === -1
    );

    if (notPresent) {
      data.results.forEach(item => {
        newSelected[item.id.toString()] = item;
      });
    } else {
      data.results.forEach(item => {
        delete newSelected[item.id.toString()];
      });
    }
    setSelected(newSelected);
  };

  const refreshData = () => {
    dispatch(createSetDataFreshAction(dataFresh + 1));
    setSelected({});
  };

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const printCheck = item => {
    let newPrintSelection = { ...selected };
    if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
      newPrintSelection[item.id.toString()] = item;
    } else {
      delete newPrintSelection[item.id.toString()];
    }
    setSelected(newPrintSelection);
  };

  if (!complete || !data) {
    return (
      <div className="spinner-centre">
        <Spinner
          className="spinner-centre"
          radius={120}
          color={"#3273dc"}
          stroke={5}
        />
      </div>
    );
  }

  document.title = mapping[props.view].title;

  return (
    <div>
      <Box>
        <h1 className="title">{mapping[props.view].title}</h1>
        <Columns>
          {approvedInput}
          {departmentInput}
          <Columns.Column size={8}>
            <Label>Search</Label>
            <Field className="has-addons">
              <Control>
                <Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                ></Input>
              </Control>
              <Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Control>
              <Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <div style={{ display: "none" }}>{mapping[props.view].print}</div>
      <FiguresSection data={data} figures={mapping[props.view].figures} />
      <Box>
        <Table striped={false} className="is-hoverable">
          <thead>
            <tr className="small-row-black">
              {mapping[props.view].headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
              {approved === 'approved' && <th>Approver</th>}
              <th>
                <Button
                  onClick={toggleSelectVisible}
                  color={
                    data.results.find(
                      workItem =>
                        Object.keys(selected).indexOf(
                          workItem.id.toString()
                        ) === -1
                    )
                      ? ""
                      : "danger"
                  }
                >
                  {data.results.find(
                    workItem =>
                      Object.keys(selected).indexOf(workItem.id.toString()) ===
                      -1
                  ) ? (
                    <div>
                      <FAIcon size="small" icon={["fas", "check"]} />
                      <span>Visible</span>
                    </div>
                  ) : (
                    <div>
                      <FAIcon size="small" icon={["fas", "times"]} />
                      <span>Visible</span>
                    </div>
                  )}
                </Button>
              </th>
              {!!Object.entries(selected).length && (
                <th>
                  <Button onClick={() => setSelected({})} color="danger">
                    <FAIcon size="small" icon={["fas", "times"]} />
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>{data.results.map(mapping[props.view].rows)}</tbody>
        </Table>
        <Pagination
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
      <Box>
        <Columns>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setEditModalOpen(true)}
              disabled={!Object.entries(selected).length}
              color={Object.entries(selected).length ? "primary" : ""}
            >
              Edit Selected
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setModalOpen(true)}
              color={Object.entries(selected).length ? "warning" : ""}
              disabled={!Object.entries(selected).length}
            >
              Print Selected
            </Button>
          </Columns.Column>
          {approved === "all" && (
            <Columns.Column>
              <Button
                fullwidth
                onClick={() => approveSelected()}
                color={Object.entries(selected).length ? "success" : ""}
                disabled={!Object.entries(selected).length}
              >
                Approve Selected
              </Button>
            </Columns.Column>
          )}
        </Columns>
      </Box>
      <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content>
          <Section style={{ backgroundColor: "white" }}>
            {props.view === "invoice" && (
              <Field>
                <Control>
                  <Label>Select an Invoice Date</Label>
                  <Input
                    type="date"
                    value={printDate || ""}
                    onChange={e => setPrintDate(e.target.value)}
                  />
                </Control>
              </Field>
            )}
            <Field>
              <p>
                You have selected {Object.keys(selected).length} out of total of{" "}
                {data.count} items for printing
              </p>
            </Field>
            <ReactToPrint
              trigger={() => (
                <Button
                  color={Object.entries(selected).length ? "success" : ""}
                >
                  Print Selected
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Section>
        </Modal.Content>
      </Modal>
      {mapping[props.view].multipleEditModal}
      <ApproveModal
        endpoint={props.endpoint}
        open={approveModalOpen}
        setOpen={setApproveModalOpen}
        items={selected}
        invoiceNumber={approvedInvoiceNumber}
        customer={approvedCustomer}
        costCentres={costCentres.results}
        costCentreObjects={costCentreObjects}
      />
    </div>
  );
}

export default InvoicePO;
