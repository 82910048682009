import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Label,
  Field,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";

import axios from "axios";
import { getQueryString } from "../../utils";
import VehicleFinderSection from "./VehicleFinderSection";
import ContactSection from "../Contact/ContactSection";
import InsuranceSection from "./InsuranceSection";
import LicenceSection from "./LicenceSection";
import RatesSection from "./RatesSection";
import OffHireSection from "./OffHireSection";
import { NotificationManager } from "react-notifications";

class Form extends Component {
  dateFields = [
    "tax_expiry",
    "date_sold",
    "finish_date",
    "depreciation_start_date",
    "off_hire_date"
  ];
  typeFields = ["L1", "L2", "L3", "L4", "Car"];

  rates = [
    "first_day",
    "two_five_days",
    "six_twenty_seven_days",
    "twenty_eight_plus",
    "one_week",
    "six_twenty_seven_week",
    "long_term_weekly",
    "weekend"
  ];

  day = 1000 * 60 * 60 * 24;

  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      vehicle: {},
      start_date: "",
      finish_date: "",
      start_mileage: 0
    };
  }

  static propTypes = {
    endpoint: PropTypes.string.isRequired
  };

  state = {
    customer: {},
    start_date: "",
    finish_date: ""
  };

  onRatesChange = e => {
    if (e.target.name === "excess_mileage_charge_per_mile")
      return this.onOffHireChange(e);
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.rental_type === "LT") return this.calculateLTH();
      if (this.state.rental_type === "ST") return this.calculateSTH();
    });
  };

  onOffHireChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const excess_mileage_charge =
        parseFloat(this.state.off_hire_excess_mileage) *
        this.state.excess_mileage_charge_per_mile;

      const off_hire_total_additional_charges =
        parseFloat(this.state.off_hire_new_damage) +
        parseFloat(this.state.off_hire_missing_fuel) +
        excess_mileage_charge;

      this.setState({
        excess_mileage_charge: excess_mileage_charge.toFixed(2),
        off_hire_total_additional_charges: off_hire_total_additional_charges.toFixed(
          2
        )
      });
    });
  };

  calculateLTH = () => {
    return this.setState({
      total:
        Math.round(this.state.days / 7) * this.state.long_term_weekly +
        parseFloat(this.state.additional_cost || 0)
    });
  };

  calculateSTH = () => {
    const days = this.state.days;
    let total = 0;

    const rates = {
      first_day: days > 0 ? 1 : 0,
      two_five_days: days - 1 > 0 ? Math.min(4, days - 1) : 0,
      six_twenty_seven_days: days - 6 > 0 ? Math.min(22, days - 6) : 0,
      twenty_eight_plus: days - 27 > 0 ? days - 27 : 0
    };

    for (var rate in rates) {
      total += rates[rate] * parseFloat(this.state[rate] || 0);
    }

    return this.setState({
      total: total + parseFloat(this.state.additional_cost || 0)
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const endpoint = this.props.endpoint + "rentals/" + this.state.id;
    const conf = { method: "delete", url: endpoint };
    axios(conf).then(response => this.props.history.push("/rentals"));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const start = new Date(this.state.start_date);
      const end = new Date(this.state.finish_date);
      const days =
        Math.round(Math.abs((start.getTime() - end.getTime()) / this.day)) + 1;
      this.setState({ days: days }, () => {
        if (this.state.rental_type === "LT") return this.calculateLTH();
        if (this.state.rental_type === "ST") return this.calculateSTH();
      });
    });
  };

  selectVehicle = vehicle => {
    let queryString = getQueryString({
      vehicle: vehicle.id,
      start_date: this.state.start_date,
      finish_date: this.state.finish_date
    });
    axios.get(`/api/rentals${queryString}`).then(resp => {
      if (resp.data.results.length === 0) {
        let newState = { vehicle: vehicle, start_mileage: vehicle.mileage };
        for (let rate of this.rates) {
          newState[rate] = vehicle[rate];
        }
        this.setState(newState);
      } else {
        NotificationManager.warning(
          `This vehicle is already booked out to ${resp.data.results[0].customer.display_name}!`,
          "Warning!",
          10000
        );
      }
    });
  };

  handleUpdateCustomer = e => {
    this.setState({ customer: e });
  };

  handleCheckbox = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  fixEmptyDates = vehicle => {
    this.dateFields.forEach(function(date) {
      if (vehicle[date] === "") {
        vehicle[date] = null;
      }
    });
    return vehicle;
  };

  handleSubmit = e => {
    e.preventDefault();
    let rental = this.state;
    rental = this.fixEmptyDates(rental);
    const endpoint = rental.id
      ? this.props.endpoint + "rentals/" + rental.id
      : this.props.endpoint + "rentals";
    const method = rental.id ? "put" : "post";

    const conf = {
      method: method,
      data: rental,
      url: endpoint
    };

    axios(conf).then(response =>
      this.props.history.push("/editrental/" + response.data.id)
    );
  };
  async UNSAFE_componentWillMount() {
    const { rental } = this.props.match.params;

    if (rental) {
      const promise = await axios.get("/api/rentals/" + rental);
      const status = promise.status;
      if (status === 200) {
        let data = promise.data;
        this.setState(data);
      }
    }
  }
  render() {
    return (
      <Container>
        <Box>
          <Heading>New Booking</Heading>
          <Columns>
            <Columns.Column>
              <Control>
                <Field>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    name="start_date"
                    onChange={this.handleDateChange}
                    value={this.state.start_date}
                    required
                  />
                </Field>
              </Control>
            </Columns.Column>
            <Columns.Column>
              <Control>
                <Field>
                  <Label>Return Date</Label>
                  <Input
                    type="date"
                    name="finish_date"
                    onChange={this.handleDateChange}
                    value={this.state.finish_date}
                    required
                  />
                </Field>
              </Control>
            </Columns.Column>
          </Columns>
        </Box>
        <VehicleFinderSection
          selectVehicle={this.selectVehicle}
          endpoint={this.props.endpoint}
          startDate={this.state.start_date}
          finishDate={this.state.finish_date}
        />
        <Box>
          <Heading>Vehicle</Heading>
          <Columns>
            <Columns.Column>
              <Field>
                <label className="label">Registration</label>
                <Control className="registration-wrapper">
                  <Input
                    className="registration-field"
                    type="text"
                    name="registration"
                    onChange={this.handleChange}
                    value={this.state.vehicle.registration}
                    required
                    readOnly
                  />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Field>
                <Label>Rental Start Mileage</Label>
                <Control>
                  <Input
                    type="number"
                    name="start_mileage"
                    onChange={this.handleChange}
                    value={String(
                      this.state.start_mileage || this.state.vehicle.mileage
                    )}
                    required
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Make</Label>
                <Control>
                  <Input
                    type="text"
                    name="make"
                    onChange={this.handleChange}
                    value={this.state.vehicle.make}
                    required
                    readOnly
                    disabled
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column>
              <Field>
                <Label>Model</Label>
                <Control>
                  <Input
                    // className="input"
                    type="text"
                    name="model"
                    onChange={this.handleChange}
                    value={this.state.vehicle.model}
                    required
                    readOnly
                    disabled
                  />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
        </Box>
        <RatesSection onChange={this.onRatesChange} rental={this.state} />
        <ContactSection
          title="Customer"
          contactUpdate={this.handleUpdateCustomer}
          endpoint={this.props.endpoint + "contacts"}
          contact={this.state.customer || {}}
          type="CC"
        />
        <LicenceSection onChange={this.handleChange} rental={this.state} />
        <InsuranceSection onChange={this.handleChange} rental={this.state} />
        <OffHireSection onChange={this.onOffHireChange} rental={this.state} />
        <Box>
          <Field>
            <Control>
              <Button
                onClick={this.handleSubmit}
                type="submit"
                fullwidth
                color="info"
              >
                Save Rental
              </Button>
            </Control>
          </Field>
          {this.state.id && (
            <Field>
              <Control>
                <Button
                  onClick={this.handleDelete}
                  type="submit"
                  fullwidth
                  color="danger"
                >
                  Delete Rental
                </Button>
              </Control>
            </Field>
          )}
          {this.state.id && (
            <Field>
              <Control>
                <Button
                  onClick={() =>
                    window.open(`/printrental/${this.state.id}`, "_self")
                  }
                  type="submit"
                  fullwidth
                  color="warning"
                >
                  Print Rental Agreement
                </Button>
              </Control>
            </Field>
          )}
        </Box>
      </Container>
    );
  }
}
export default Form;
