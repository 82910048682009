import React, {useState} from "react";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import Button from "react-bulma-components/lib/components/button";
import {readableDate} from "../../utils";
import useEditableTableNumberCell from "../../hooks/useEditableTableNumberCell";
import {updateWorkshopInvoice} from "../Invoicing/updateInvoice";
import useSafeSave from "../../hooks/useSafeSave";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import useEditableTableDropdownCell from "../../hooks/useEditableTableDropdownCell";
import {Checkbox, Control, Field} from "react-bulma-components/lib/components/form";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import FAIcon from "../Icon/FAIcon";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function PartLookupRow({partLookup, endpoint, updateValue, vehicleTypes, selected, onSelectCheck}) {
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [lastEdited, setLastEdited] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);


  const onSave = e => {
    const url = endpoint + "partlookup/update/" + partLookup.id;
    const conf = {
      method: "put",
      data: partLookup,
      url
    };
    setEditable(false);

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onCheck = e => {
    e.stopPropagation();
    onSelectCheck(partLookup);
  };


  const handleDelete = e => {
    const url = endpoint + "partlookup/update/" + partLookup.id;
    const conf = {
      method: "delete",
      url
    };
    setEditable(false);

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  }

  const checkUpdated = () => {
    return axios
      .get(endpoint + "partlookup/update/" + partLookup.id)
      .then(
        checkPartLookup =>
          (new Date(checkPartLookup.data.last_saved).getTime() -
            new Date(partLookup.last_saved).getTime()) /
          1000 <
          1
      );
  };


  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const typeFields = vehicleTypes;
  const typeObjects = typeFields.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const onTypeChange = (e, index) => {
    const toUpdate = {
      target: {
        name: "vehicle_type",
        value: typeObjects[e.target.value]
      }
    };
    onChange(toUpdate, index);
  };

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newPartLookup = {...partLookup};
    newPartLookup[e.target.name] = e.target.value;
    updateValue(newPartLookup);
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  }

  const handlePartSupplierSelect = supplier => {
    let newPartLookup = {...partLookup}
    newPartLookup.supplier = supplier[0];
    updateValue(newPartLookup);
  };


  const saveAfterButtonClick = () => {
    const url = endpoint + "partlookup/update/" + partLookup.id;
    const conf = {
      method: "put",
      data: partLookup,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const buttonClick = prop => {
    partLookup[prop] = !partLookup[prop];
    saveAfterButtonClick();
  };

  return (
    <tr key={partLookup.id}>
      {useEditableTableTextCell({
          propName: "make",
          value: partLookup.make,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        }
      )}
      {useEditableTableTextCell({
          propName: "model",
          value: partLookup.model,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        }
      )}
      {useEditableTableDropdownCell({
          propName: "vehicle_type",
          value: partLookup.vehicle_type,
          onChange: onTypeChange,
          onSave: safeSave,
          options: vehicleTypes,
          editable,
          setEditable,
          inlineEdit: true
        }
      )}
      {useEditableTableTextCell({
          propName: "name",
          value: partLookup.name,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        }
      )}
      {useEditableTableTextCell({
          propName: "part_number",
          value: partLookup.part_number,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        }
      )}
      {useEditableTableCostCell({
        propName: "cost",
        value: partLookup.cost,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      <td>
        <Field className="has-addons">
          <Control>
            <AsyncTypeahead
              id="typeahead"
              labelKey="display_name"
              minLength={2}
              name={"supplier"}
              onSearch={handleSearch}
              onChange={handlePartSupplierSelect}
              placeholder={"Search Supplier"}
              disabled={!editable}
              options={searchData}
              ref={typeahead => setTypeahead(typeahead)}
              className="typeahead"
              isLoading={isLoading}
              selected={partLookup.supplier && partLookup.supplier.id ? [partLookup.supplier] : []}
            />
          </Control>
          {editable ? (
            <Control>
              <Button
                className="small-row-white"
                color="success"
                onClick={onSave}
              >
                <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
              </Button>
            </Control>
          ) : (
            <Control>
              <Button
                className="small-row-black"
                onClick={() => setEditable(!editable)}
              >
                <FAIcon size="small" icon={["fas", "edit"]}/>
              </Button>
            </Control>
          )}
        </Field>
      </td>
      {useEditableTableNumberCell({
        propName: "stock_volume",
        value: partLookup.stock_volume,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableTextAreaCell({
          propName: "comments",
          value: partLookup.comments,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
        inlineEdit: true
        }
      )}
      <td onClick={e => e.stopPropagation()}>
        <Field>
          <Control className="table-checkbox">
            <Checkbox
              checked={selected}
              type="checkbox"
              onClick={onCheck}
              readOnly
            />
          </Control>
        </Field>
      </td>
      <td className="float-right">
        <Button
          color="danger"
          data-testid={`submit-part-delete${partLookup.id}`}
          onClick={setDeleteModalOpen}
        >
          Delete
        </Button>
      </td>
      <DeleteWarningModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onDelete={handleDelete}
      />
    </tr>
  );
}
