import React, { Component } from "react";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";

import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Control,
  Input,
  Label,
  Select,
  Textarea
} from "react-bulma-components/lib/components/form";
import ContactSection from "../Contact/ContactSection";

class Inspection extends Component {
  state = {
    ...this.props.inspection,

    vehicle: this.props.vehicle || undefined
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.props.update_inspection(this.state, this.props.index);
    });
  };

  render() {
    return (
      <Box>
        <Columns>
          <Columns.Column>
            <Columns>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Inspection Type</Label>
                    <Select
                      onChange={this.handleChange}
                      name="inspection_type"
                      value={this.state.inspection_type}
                      disabled={this.state.booked_in_date}
                    >
                      {this.props.types.map(value => (
                        <option
                          key={value.inspection_type}
                          value={value.inspection_type}
                        >
                          {value.inspection_type}
                        </option>
                      ))}
                    </Select>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Expiry Date</Label>
                    <Input
                      className="input"
                      type="date"
                      name="expiry_date"
                      onChange={this.handleChange}
                      value={this.state.expiry_date || ""}
                      disabled={this.state.booked_in_date}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Last Completed</Label>
                    <Input
                      type="date"
                      name="date_completed"
                      onChange={this.handleChange}
                      value={this.state.date_completed || ""}
                      disabled={this.state.booked_in_date}
                      required
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Comments</Label>
                    <Textarea
                      type="textarea"
                      name="comments"
                      onChange={this.handleChange}
                      value={this.state.comments}
                      required
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>

            <Button
              color="danger"
              onClick={() => this.props.remove_inspection(this.props.index)}
            >
              Remove Inspection
            </Button>
          </Columns.Column>

          <Columns.Column>
            <ContactSection
              title="Inspection Workshop"
              contactUpdate={ws =>
                this.handleChange({
                  target: { name: "inspection_workshop", value: ws }
                })
              }
              endpoint={this.props.endpoint + "contacts"}
              contact={this.state.inspection_workshop || {}}
              type="SP"
            />
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default Inspection;
