import React, {Component, useState} from "react";
import axios from "axios";
import Box from "react-bulma-components/lib/components/box";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import Pagination from "react-bulma-components/lib/components/pagination";
import Table from "react-bulma-components/lib/components/table";
import Columns from "react-bulma-components/lib/components/columns";
import qs from "qs";
import {getQueryString} from "../../utils";
import {useContactsData} from "../../hooks/useContactsData";
import Spinner from "react-spinner-material";
import {useSelector} from "react-redux";
import {getContactsData} from "../../state/selectors";

function Contacts({history, endpoint, location}) {
  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState();
  const [currentSearch, setCurrentSearch] = useState();

  const types = {
    CC: "Customer",
    SP: "Supplier"
  };


  let view;
  let viewName = "Contacts";
  let newContactUrl = "/editcontact"

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  let params = {page};

  if (queryParams.type) {
    view = queryParams.type;
    params.contact_type = view;
    newContactUrl += "?type=" + view;
    viewName = types[view] + "s";
  }

  if (currentSearch) {
    params.search = currentSearch;
  }

  const complete = useContactsData(params);
  const data = useSelector(getContactsData);

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = viewName;

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Label>Search</Label>
            <Field className="has-addons">
              <Control>
                <Input
                  onChange={e => setSearchText(e.target.value)}
                  name="search_text"
                  type="text"
                  placeholder={viewName}
                  value={searchText}
                />
              </Control>
              <Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Control>
              <Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Button
                  onClick={() => history.push(newContactUrl)}
                  color="success"
                  className="is-pulled-right"
                >
                  New {view ? types[view] : "Contact" } +
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">{viewName}</h1>
        <Table striped={false} className="is-hoverable">
          <thead>
          <tr>
            <th>Display Name</th>
            <th>Contact Number</th>
            <th>Email</th>
            <th>Address</th>
            <th>Type</th>
          </tr>
          </thead>
          <tbody>{data.results.map(value => (
            <tr
              className="clickable"
              onClick={() => window.open(`/editcontact/${value.id}`, "_self")}
              key={value.id}
            >
              <td>{value.display_name}</td>
              <td>{value.company_phone}</td>
              <td>{value.email}</td>
              <td onClick={(e) => e.stopPropagation()}>
          <pre>
            {`${value.address_line1}\n ${value.town} \n ${value.postcode}`}{" "}
          </pre>
              </td>
              <td>{types[value.contact_type]} </td>
            </tr>
          ))}</tbody>
        </Table>
        <Pagination
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
    </div>
  );
}

export default Contacts;
