import React, { useState } from "react";
import Button from "react-bulma-components/lib/components/button";

import {
  Checkbox,
  Control,
  Input,
  Field
} from "react-bulma-components/lib/components/form";


function Instruction({
  instruction,
  index,
  updateInstructions,
  removeInstruction,
}) {

  const handleChange = e => {
    const newInstruction = getNewInstruction(e);
    updateInstructions(newInstruction, index);
  };


  const getNewInstruction = e => {
    let newInstruction = { ...instruction, [e.target.name]: e.target.value };

    return newInstruction;
  };

  return (
    <tr>
      <td className={"comments-wrapper"}>
        <Field className="has-addons">
          <Control className="comments-wrapper">
            <Input
              type="text"
              value={instruction.text}
              name="text"
              onChange={handleChange}
              data-testid="part-name"
              classname={"comments-box"}
            />
          </Control>
        </Field>
      </td>
      <td className="instructions-remove-button">
        <Button
          remove
          onClick={() => removeInstruction(index)}
          data-testid="remove-part"
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}
export default Instruction;
