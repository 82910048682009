import { useHttp } from "./useHttp";
import { useDispatch } from "react-redux";
import { createSetQuickLinksDataAction } from "../actions/dataActions";

export const useQuickLinkData = (params, dataFresh) => {
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    "quicklink",
    "get",
    params,
    dataFresh
  );

  if (error) {
    throw Error("There was an error");
  }

  if (!loading && data !== null) {
    dispatch(createSetQuickLinksDataAction(data));
  }

  return !loading;
};
