import {
  createSetBlankContactDataAction,
  createSetContactDataAction
} from "../actions/dataActions";
import { useHttpGetAndSet } from "./useHttpGetAndSet";

export const useContactData = (id, dataFresh, contact_type) => {
  const nofetch = !id;
  const [loading, error] = useHttpGetAndSet(
    `contacts/${id}`,
    "get",
    {},
    dataFresh,
    nofetch,
    createSetBlankContactDataAction,
    {
      contact_type: contact_type || "CC",
      labour_rate: 40,
      labour_rate_markup: 0,
      additional_contacts: [],
      part_contacts: [],
      workshop_contacts: [],
      workshop_instructions: [],
      rental_instructions: []
    }
  );
  if (error) {
    throw Error("There was an error");
  }

  return !loading;
};
