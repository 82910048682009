import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./App.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import configureStore, { history } from "./state/configureStore";
import { GoogleAuthProvider } from "./components/GoogleContext";

const store = configureStore();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleAuthProvider>
          <App></App>
        </GoogleAuthProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
};

render();
