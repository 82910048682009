import React from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input,
  Select
} from "react-bulma-components/lib/components/form";
import { formatDate } from "../../utils";
import Button from "react-bulma-components/lib/components/button";
import Collapsible from "react-collapsible";
import Icon from "react-bulma-components/lib/components/icon";

function CAPSection(props) {

  return (<Box>
    <Collapsible
      className="title"
      triggerOpenedClassName="title"
      trigger="CAP"
    >
      <div>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>CAP Retail</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="cap_retail"
                  onChange={props.onChange}
                  value={props.data.cap_retail}
                  required
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>CAP Clean</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="cap_clean"
                  onChange={props.onChange}
                  value={props.data.cap_clean}
                  required
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
          <Field>
            <Label>CAP Average</Label>
            <Control iconLeft>
              <Input
                type="text"
                name="cap_average"
                onChange={props.onChange}
                value={props.data.cap_average}
                required
              />
              <Icon align="left" icon="pound-sign"/>
            </Control>
          </Field>
        </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>CAP Below</Label>
              <Control iconLeft>
                <Input
                  type="text"
                  name="cap_below"
                  onChange={props.onChange}
                  value={props.data.cap_below}
                  required
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Date Valued</Label>
              <Control>
                <Input
                  type="date"
                  name="cap_date"
                  onChange={props.onChange}
                  value={String(props.data.cap_date)}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </div>
    </Collapsible>
  </Box>);
}

export default CAPSection;
