import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTransactionData } from "../../hooks/useTransactionData";
import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import Heading from "react-bulma-components/lib/components/heading";
import Table from "react-bulma-components/lib/components/table";
import { getDataFresh } from "../../state/selectors";
import Spinner from "react-spinner-material";
import Button from "react-bulma-components/lib/components/button";
import axios from "axios";
import { createSetDataFreshAction } from "../../actions/dataActions";
import { getWeeklyTransactionData } from "../../state/selectors";
import Collapsible from "react-collapsible";

function WeeklyTransactionView({cc}) {
  const dispatch = useDispatch();

  const typeMapping = {
    'IN': 'Income',
    'OUT': 'Expense'
  };

  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);
  const complete = useTransactionData(
    {
      view: "repeating",
      cost_centre: cc,
      page
    },
    dataFresh
  );

  const handleDelete = id => {
    const endpoint = "/api/transactions/" + id;
    const conf = { method: "delete", url: endpoint };
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  const data = useSelector(getWeeklyTransactionData);
  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Repeating Transactions"
      >
      <Table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Frequency</th>
            <td>Contact</td>
            <th>Cost Centre</th>
            <th>Type</th>
            <th>Category</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.results.map(value => (
            <tr style={{ fontSize: 12 }} key={value.id}>
              <td>{value.description}</td>
              <td>{value.frequency}</td>
              <td>{value.contact ? value.contact.display_name : ""}</td>
              <td>{value.cost_centre ? value.cost_centre.name : ""}</td>
              <td>{typeMapping[value.type]}</td>
              <td>{value.category.name}</td>
              <td>£{value.amount.toFixed(2)}</td>
              <td className={"is-narrow"}>
                <Button
                  color={"danger"}
                  onClick={() => {
                    handleDelete(value.id);
                  }}
                >
                  Delete X
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        onChange={page => setPage(page)}
        current={page}
        total={Math.ceil(data.count / 25)}
      >
        {" "}
      </Pagination>
      </Collapsible>
    </Box>
  );
}

export default WeeklyTransactionView;
