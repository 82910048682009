import React, {useState} from "react";
import Box from "react-bulma-components/lib/components/box/";
import {
  Field,
  Control,
  Label, Input, Select
} from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import useInput from "../../hooks/useInput";
import Button from "react-bulma-components/lib/components/button";
import Collapsible from "react-collapsible";
import axios from "axios";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import Icon from "react-bulma-components/lib/components/icon";
import {NotificationManager} from "react-notifications";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import CreatedModal from "./CreatedModal";

const AsyncTypeahead = asyncContainer(Typeahead);

function NewTransaction({transaction_categories, costCentres, cc, endpoint, type}) {
  const dataFresh = useSelector(getDataFresh);
  const [createdId, setCreatedId] = useState();
  const [createdModalOpen, setCreatedModalOpen] = useState(false);
  const dispatch = useDispatch();
  const categoryFields = transaction_categories;
  const categoryObjects = categoryFields.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});


  const costCentreObjects = costCentres.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const inout_mapping = {"OUT": "SP", "IN": "CC"};
  const type_mapping = {"OUT": "Outgoing", "IN": "Incoming"};

  const handleCategoryUpdate = e => {
    setCategory(categoryObjects[e.target.value])
  };

  const handleCostCentreUpdate = e => {
    setCostCentre(costCentreObjects[e.target.value])
  };

  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [contact, setContact] = useState({});
  const [, setTypeahead] = useState();

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=${inout_mapping[type]}&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handleContactSelect = contact => {
    setContact(contact[0]);
  };


  const onChangeInvoiceAmount = e => {
    setInvoiceVat((e.target.value * 0.2).toFixed(2))
  };

  const [description, descriptionInput, setDesc] = useInput({
    type: "text",
    label: "Description"
  });
  const [invoiceNumber, invoiceNumberInput, setInvoiceNumber] = useInput({
    type: "text",
    label: "Invoice Number"
  });
  const [category, setCategory] = useState(categoryFields[0]);
  const [costCentre, setCostCentre] = useState(cc ? costCentreObjects[cc] : costCentres[0]);
  if (cc && cc != costCentre.id) {
    setCostCentre(costCentreObjects[cc])
  }
  const [repeating, setRepeating] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [amount, amountInput, setAmount] = useInput({
    type: "number",
    label: "Amount",
    currency: true
  });

  const [invoice_amount, invoiceAmountInput, setInvoiceAmount] = useInput({
    type: "number",
    label: "Invoice Amount",
    currency: true,
    onChange: onChangeInvoiceAmount
  });
  const [invoice_vat, invoiceVatInput, setInvoiceVat] = useInput({
    type: "number",
    label: "Invoice VAT",
    currency: true,
  });
  const [invoice_date, invoiceDateInput, setInvoiceDate] = useInput({
    type: "date",
    label: "Invoice Date",
    initialValue: null
  });
  const [date, dateInput, setDate] = useInput({
    type: "date",
    label: "Date",
    initialValue: null
  });

  const handleSubmit = () => {
    if (!repeating && (!date || !description || !amount)) {
      NotificationManager.warning("You must specify an Invoice Date, Description and Amount!",
        "Missing fields",
        10000)
    } else if (!description || !amount) {
      NotificationManager.warning("You must specify a Description and Amount!",
        "Missing fields",
        10000)
    } else {
      const url = endpoint + "transactions";
      const conf = {
        method: "post",
        data: {
          description,
          category,
          cost_centre: costCentre,
          type,
          amount,
          date: date || null,
          invoice_date: invoice_date || null,
          invoice_amount: invoice_amount || 0,
          invoice_vat: invoice_vat || 0,
          repeating: repeating,
          frequency: frequency,
          invoice_number: invoiceNumber,
          contact: contact
        },
        url
      };

      return axios(conf).then((res) => {
        dispatch(createSetDataFreshAction(dataFresh + 1));
        setCreatedModalOpen(true);
        setCreatedId(res.data.id);
        setDesc("");
        setInvoiceDate("");
        setDate("");
        setAmount(0);
        setContact({});
        setInvoiceNumber("");
        setInvoiceAmount(0);
        setInvoiceVat(0);
      }).catch(() => {
        NotificationManager.error(
          "An error occured",
          "Error!",
          10000
        );
      });

    }
  };

  return (
    <Box>
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger={"Add" + ` ${type_mapping[type]}` + (!!cc && costCentreObjects ? " " + costCentreObjects[cc].name : "") + " Transaction"}
      >
        <Columns>
          <Columns.Column>
            <Field className="has-addons">
              <Control>
                <Label>Repeating</Label>
                <Button
                  color={repeating ? "primary" : ""}
                  onClick={() => setRepeating(true)}
                >
                  Yes
                </Button>
                <Button
                  color={!repeating ? "primary" : ""}
                  onClick={() => {
                    setRepeating(false);
                    setFrequency("")
                  }}
                >
                  No
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          {repeating &&
          <Columns.Column>
            <Field className="has-addons">
              <Control>
                <Label>Frequency</Label>
                <Button
                  color={frequency === "WEEKLY" ? "primary" : ""}
                  onClick={() => setFrequency("WEEKLY")}
                >
                  Weekly
                </Button>
                <Button
                  color={frequency === "MONTHLY" ? "primary" : ""}
                  onClick={() => setFrequency("MONTHLY")}
                >
                  Monthly
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          }
          {descriptionInput}
          <Columns.Column>
            <Field>
              <Label>Contact</Label>
              <AsyncTypeahead
                id="typeahead"
                labelKey="display_name"
                minLength={2}
                name={"contact"}
                onSearch={handleSearch}
                onChange={handleContactSelect}
                placeholder={"Search Contact"}
                options={searchData}
                ref={typeahead => setTypeahead(typeahead)}
                className="typeahead"
                useCache={false}
                isLoading={isLoading}
                selected={
                  contact && contact.id ? [contact] : []
                }
              />
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Category</Label>
                <Select
                  onChange={handleCategoryUpdate}
                  name="type"
                  value={category ? category.id : categoryFields[0].id}
                >
                  {categoryFields.map(value => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
                </Select>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Cost Centre</Label>
                <Select
                  onChange={handleCostCentreUpdate}
                  name="type"
                  value={costCentre ? costCentre.id : null}
                >
                  {costCentres.map(value => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
                </Select>
              </Control>
            </Field>
          </Columns.Column>


        </Columns>
        <Columns>
          {amountInput}

          {!repeating && dateInput}

        </Columns>
        {!repeating &&
        <Columns>
          {invoiceAmountInput}
          {invoiceVatInput}
          <Columns.Column>
            <Field>
              <Label>Invoice Total</Label>
              <Control iconLeft>
                <Input
                  value={(1 * invoice_amount + 1 * invoice_vat).toFixed(2)}
                  type="number"
                  readonly
                  disabled
                />
                <Icon align="left" icon="pound-sign"/>
              </Control>
            </Field>
          </Columns.Column>
          {invoiceNumberInput}
          {invoiceDateInput}
        </Columns>
        }

        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
      <CreatedModal open={createdModalOpen} id={createdId} setOpen={setCreatedModalOpen}/>
    </Box>
  );
}

export default NewTransaction;
