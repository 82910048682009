import {
  Field,
  Label,
  Control,
  Select
} from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import React, { useState } from "react";

function useInput({ label, initialValue, options, testId, callback }) {
  const [value, setValue] = useState(initialValue || "");

  const onChange = e => {
    setValue(e.target.value);
    if (callback) {
      callback();
    }
  };

  const input = (
    <Columns.Column>
      <Field>
        <Label>{label}</Label>
        <Control>
          <Select value={value} onChange={onChange} data-testid={testId}>
            {options.map(item => (
              <option key={item.name} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </Control>
      </Field>
    </Columns.Column>
  );
  return [value, input, setValue];
}

export default useInput;
