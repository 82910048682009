import React, { useState } from "react";

import PaymentRow from "./PaymentRow.js";

import Box from "react-bulma-components/lib/components/box";
import Pagination from "react-bulma-components/lib/components/pagination";
import { createSetPaymentsDataAction } from "../../actions/dataActions";
import {getCostCentresData, getDataFresh, getPaymentsData} from "../../state/selectors";
import { usePaymentsData } from "../../hooks/usePaymentsData";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Control,
  Field,
  Input,
  Label
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import axios from "axios";
import { formatDate, readableDate } from "../../utils";
import useSelect from "../../hooks/useSelect";
import CreatePaymentModal from "./CreatePaymentModal";

export default function Payment(props) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const dataFresh = useSelector(getDataFresh);
  const [filters, setFilters] = useState({});
  const [createPaymentModalOpen, setCreatePaymentModalOpen] = useState(false);
  const [view, viewInput] = useSelect({
    label: "View",
    options: [
      { name: "All", value: "all" },
      { name: "Unpaid", value: "unpaid" },
      { name: "Paid", value: "paid" }
    ],
    initialValue: "unpaid",
    testId: "payment-view",
    callback: () => {
      setPage(1);
    }
  });

  let params = { page };

  if (view !== "all") {
    params.view = view;
  }

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const complete = usePaymentsData(params, dataFresh);
  const data = useSelector(getPaymentsData);
  const costCentres = useSelector(getCostCentresData);
  const onPaymentUpdate = (index, newPayment) => {
    let newState = { ...data };
    newState.results[index] = newPayment;
    dispatch(createSetPaymentsDataAction(newState));
  };

  const headings = [
    {
      label: "Customer",
      field: "contact",
      labelField: "contact__display_name"
    },
    {
      label: "Invoice Number",
      field: "invoice_number",
      labelField: "invoice_number"
    },
    {
      label: "Payment Date",
      field: "payment_date",
      labelField: "payment_date",
      date: true
    },
    {
      label: "Amount",
    },
    {
      label: "Payment Type",
      field: "payment_type",
      labelField: "payment_type"
    },
    {
      label: "Cost Centre",
      field: "cost_centre",
      labelField: "cost_centre__name"
    },
    { label: "Comments" },
    {
      label: "Date Paid",
      field: "date_paid",
      labelField: "date_paid",
      date: true
    }
  ];

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  const costCentreObjects = costCentres.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});


  document.title = "Payments";

  return (
    <div>
      <Box>
        <h1 className="title">Payments</h1>
        <Columns>
          {viewInput}
          <Columns.Column>
            <Field>
              <Label>&nbsp; </Label>
              <Button
                color="success"
                onClick={() => setCreatePaymentModalOpen(true)}
              >
                New Payments +
              </Button>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Upcoming Payments</h1>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr>
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <PaymentRow
                key={value.id}
                payment={value}
                index={index}
                onPaymentUpdate={onPaymentUpdate}
                costCentres={costCentres.results}
                costCentreObjects={costCentreObjects}
                endpoint={props.endpoint}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
      <CreatePaymentModal
        open={createPaymentModalOpen}
        setOpen={setCreatePaymentModalOpen}
        endpoint={props.endpoint}
        costCentres={costCentres.results}
        costCentreObjects={costCentreObjects}
      />
    </div>
  );
}
