import React from "react";
import Table from "react-bulma-components/lib/components/table";
import Heading from "react-bulma-components/lib/components/heading";
import Columns from "react-bulma-components/lib/components/columns";
import { readableDate, formatCurrency } from "../../utils";
import logo from "../Nav/img/Logo.jpg";

class PrintPO extends React.Component {
  get suppliers() {
    const workValues = Object.values(this.props.data);
    const suppliers = {};

    for (let i = 0; i < workValues.length; i++) {
      if (!suppliers[workValues[i].workshop]) {
        suppliers[workValues[i].workshop] = {total: 0, vat_no: workValues[i].workshop_vat_no};
      }
      suppliers[workValues[i].workshop].total += workValues[i].labour_cost;
      for (let j = 0; j < workValues[i].parts.length; j++) {
        suppliers[workValues[i].workshop].total += workValues[i].parts[j].cost;
      }
    }
    return suppliers;
  }

  get totalPartCost() {
    if (Object.entries(this.props.data).length < 1) return 0;

    return Object.entries(this.props.data).reduce((total, work) => {
      return (
        work[1].parts.reduce((work_total, part) => {
          return (Math.round( (work_total + part.cost) *100) )/100;
        }, 0) + total
      );
    }, 0);
  }

  get totalCost() {
    if (Object.entries(this.props.data).length < 1) return 0;
    return Object.entries(this.props.data).reduce((total, work) => {
      return (Math.round((total + work[1].labour_cost)* 100))/100;
    }, 0);
  }

  get totalWithVAT() {
    return (Math.round(((this.totalCost + this.totalPartCost) *1.2) *100)) / 100;
  }

  PartRow(part) {
    return (
      <tr key={part.id}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{part.name}</td>
        <td>{formatCurrency(part.cost)}</td>
      </tr>
    );
  }

  WorkRow(work) {
    let rows = [];
    rows.push(
      <tr key={work.id} className="table-highlight-orange">
        <td>{readableDate(work.finish_date)}</td>
        <td>{work.vehicle_registration}</td>
        <td>{work.description}</td>
        <td>{formatCurrency(work.labour_cost)}</td>
        <td>{work.job_workshop_invoice_number}</td>
        <td>{readableDate(work.job_workshop_payment_date)}</td>
        <td>{work.workshop}</td>
        <td></td>
        <td></td>
      </tr>
    );
    rows = rows.concat(work.parts.map(part => this.PartRow(part)));
    return rows;
  }

  render() {
    return (
      <div className="print-background">
        <Columns>
          <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
          <Heading>Workshop Invoicing</Heading>
        </Columns>
        <Table>
          <thead>
            <tr>
              <th>Total Labour</th>
              <th>Total Parts</th>
              <th>Total Labour and Parts</th>
              <th>Total Inc. VAT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatCurrency(this.totalCost)}</td>
              <td>{formatCurrency(this.totalPartCost)}</td>
              <td>{formatCurrency(this.totalPartCost + this.totalCost)}</td>
              <td>{formatCurrency(this.totalWithVAT)}</td>
            </tr>
          </tbody>
        </Table>
        <Heading size={4}>Supplier Summary</Heading>
        <Table>
          <thead>
            <tr>
              <th>Supplier</th>
              <th>VAT No.</th>
              <th>Total Costs</th>
              <th>Total Costs + VAT</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(this.suppliers).map(entry => (
              <tr key={entry[0]}>
                <td>{entry[0]}</td>
                <td>{entry[1].vat_no}</td>
                <td>{formatCurrency(entry[1].total)}</td>
                <td>{formatCurrency(entry[1].total * 1.2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Heading size={4}>Work Breakdown</Heading>
        <Table>
          <thead>
            <tr>
              <td>Date</td>
              <td>Registration</td>
              <td>Description</td>
              <td>Labour Cost</td>
              <td>Invoice</td>
              <td>Payment Date</td>
              <td>Workshop</td>
              <td>Part Name</td>
              <td>Part Cost</td>
            </tr>
          </thead>
          <tbody>
            {Object.values(this.props.data)
              .sort((a, b) => {
                // sort by workshop then ordered date
                if (a.workshop > b.workshop) return 1;
                if (a.workshop < b.workshop) return -1;

                return new Date(a.finish_date) - new Date(b.finish_date);
              })
              .map(entry => this.WorkRow(entry))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default PrintPO;
