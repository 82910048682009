import React from "react";
import { useGoogleLogin } from "react-use-googlelogin";
import config from "../config";

const GoogleAuthContext = React.createContext(); // Not necessary, but recommended.

export const GoogleAuthProvider = ({ children }) => {
  const googleAuth = useGoogleLogin({
    clientId: config.GOOGLE_CLIENT_ID // Your clientID from Google.
  });

  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {/* The rest of your app */}
      {children}
    </GoogleAuthContext.Provider>
  );
};

export const useGoogleAuth = () => React.useContext(GoogleAuthContext);
