import React from "react";
import Box from "react-bulma-components/lib/components/box/";
import Columns from "react-bulma-components/lib/components/columns";
import useInput from "../../../hooks/useInput";
import usePostVehicleType from "../../../hooks/usePostVehicleType";
import Button from "react-bulma-components/lib/components/button";
import Collapsible from "react-collapsible";

function VehicleType() {
  let [vehicleType, nameInput] = useInput({
    type: "text",
    label: "Vehicle Name"
  });
  let [labourRate, labourInput] = useInput({
    type: "number",
    label: "Special Labour Rate"
  });

  labourRate = parseFloat(labourRate);

  const [, postVehicleType] = usePostVehicleType({
    name: vehicleType,
    special_labour_rate: labourRate
  });

  const handleSubmit = () => {
    postVehicleType();
  };

  return (
    <Box className="settings_type">
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Add Vehicle Type"
      >
        <Columns>
          {nameInput}
          {labourInput}
        </Columns>
        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
    </Box>
  );
}

export default VehicleType;
