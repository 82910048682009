import React, { Component } from "react";

import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Field,
  Label,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Heading from "react-bulma-components/lib/components/heading";

class InsuranceSection extends Component {
  render() {
    return (
      <Box>
        <Heading>Insurance</Heading>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Policy Number</Label>
              <Control>
                <Input
                  type="text"
                  name="policy_number"
                  onChange={this.props.onChange}
                  value={this.props.rental.policy_number}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Policy Name</Label>
              <Control>
                <Input
                  type="text"
                  name="policy_name"
                  onChange={this.props.onChange}
                  value={this.props.rental.policy_name}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Insurer</Label>
              <Control>
                <Input
                  type="text"
                  name="insurer"
                  onChange={this.props.onChange}
                  value={this.props.rental.insurer}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default InsuranceSection;
