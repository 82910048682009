import React, { Component, useState } from "react";
import axios from "axios";
import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Control,
  Field,
  Input,
  Label,
  Textarea
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import Button from "react-bulma-components/lib/components/button";
import Heading from "react-bulma-components/lib/components/heading";
import CustomerRentalRecord from "./CustomerRentalRecord";
import { useDispatch, useSelector } from "react-redux";
import { createSetContactDataAction } from "../../actions/dataActions";
import { useContactData } from "../../hooks/useContactData";
import { getContactData } from "../../state/selectors";
import Spinner from "react-spinner-material/lib";
import Collapsible from "react-collapsible";
import ContactInfoSection from "./ContactInfoSection";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import TypeChangeModal from "./TypeChangeModal";
import Supplier from "./SupplierSection";
import Customer from "./CustomerSection";
import qs from "qs";
import Table from "react-bulma-components/lib/components/table";
import Comment from "../Job/Comment";
import Instruction from "./Instruction";
import shortid from "shortid";

function Form({ match, location, endpoint, history }) {
  const dispatch = useDispatch();

  const id = match.params.contact;
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const complete = useContactData(id, 1, queryParams.type);
  const contact = useSelector(getContactData);
  const [modalOpen, setModalOpen] = useState(false);

  const [typeModalOpen, setTypeModalOpen] = useState(false);
  const [targetType, setTargetType] = useState();

  const handleChange = e => {
    dispatch(createSetContactDataAction({ [e.target.name]: e.target.value }));
  };

  const handleCheckbox = e => {
    const {name, checked} = e.target
    dispatch(createSetContactDataAction({ [name]: checked }));
  };

  const setType = type => {
    if (!id) {
      dispatch(createSetContactDataAction({ contact_type: type }));
    } else {
      setTargetType(type);
      setTypeModalOpen(true);
    }
  };

  const setActive = active => {
      dispatch(createSetContactDataAction({ accounts_active: active }));
  };
  // if (queryParams.type) {
  //   dispatch(createSetContactDataAction({ contact_type: queryParams.type }));
  // }

  const addRentalInstruction = e => {
    e.preventDefault();
    dispatch(createSetContactDataAction({
      rental_instructions: contact.rental_instructions.concat({
        key: shortid.generate(),
        text: ""
      })
    }))
  };

  const updateRentalInstructions = (instruction, index) => {
    let newInstructions = [...contact.rental_instructions];
    newInstructions.splice(index, 1, instruction);
    dispatch(createSetContactDataAction({ rental_instructions: newInstructions }));
  };

  const removeRentalInstruction = index => {
    let newInstructions = [...contact.rental_instructions];
    newInstructions.splice(index, 1);
    dispatch(createSetContactDataAction({ rental_instructions: newInstructions }));
  };

  const addWorkshopInstruction = e => {
    e.preventDefault();
    dispatch(createSetContactDataAction({
      workshop_instructions: contact.workshop_instructions.concat({
        key: shortid.generate(),
        text: ""
      })
    }))
  };

  const updateWorkshopInstructions = (instruction, index) => {
    let newInstructions = [...contact.workshop_instructions];
    newInstructions.splice(index, 1, instruction);
    dispatch(createSetContactDataAction({ workshop_instructions: newInstructions }));
  };

  const removeWorkshopInstruction = index => {
    let newInstructions = [...contact.workshop_instructions];
    newInstructions.splice(index, 1);
    dispatch(createSetContactDataAction({ workshop_instructions: newInstructions }));
  };

  const handleDelete = e => {
    e.preventDefault();
    const url = endpoint + "contacts/" + contact.id;
    const conf = { method: "delete", url };
    axios(conf).then(response => history.push("/contacts"));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const url = contact.id
      ? `${endpoint}contacts/${contact.id}`
      : `${endpoint}contacts`;
    const method = contact.id ? "put" : "post";

    const conf = {
      method: method,
      data: contact,
      url
    };

    axios(conf).then(response => {
      if (contact.id) {
        createSetContactDataAction(response.data);
      } else {
        history.push("/editcontact/" + response.data.id);
      }
    });
  };

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          color={"#3273dc"}
          stroke={5}
        />
      </Box>
    );
  }

  document.title = "Contact - " + (contact.display_name ? contact.display_name : "New");

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Display Name</Label>
              <Control>
                <Input
                  type="text"
                  name="display_name"
                  onChange={handleChange}
                  value={contact.display_name}
                  required
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field className="has-addons">
              <Control>
                <Label>Type</Label>
                <Button
                  color={contact.contact_type === "CC" ? "primary" : ""}
                  onClick={() => setType("CC")}
                >
                  Customer
                </Button>
                <Button
                  color={contact.contact_type === "SP" ? "primary" : ""}
                  onClick={() => setType("SP")}
                >
                  Supplier
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Contact Info</h1>
        <Columns>
          <Columns.Column>
            <Field>
              <Label className="label">First Name</Label>
              <Control>
                <Input
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  value={contact.first_name}
                  required
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <label className="label">Last Name</label>
              <Control>
                <Input
                  className="input"
                  type="text"
                  name="last_name"
                  onChange={handleChange}
                  value={contact.last_name}
                  required
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Contact Number</Label>
              <Control>
                <Input
                  type="tel"
                  name="contact_number"
                  onChange={handleChange}
                  value={contact.contact_number}
                  required
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={contact.email}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Company Info</h1>
        <Columns>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Name</Label>
                <Input
                  type="text"
                  name="company_name"
                  onChange={handleChange}
                  value={contact.company_name}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Phone</Label>
                <Input
                  type="text"
                  name="company_phone"
                  onChange={handleChange}
                  value={contact.company_phone}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="company_email"
                  onChange={handleChange}
                  value={contact.company_email}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Street Address</Label>
                <Input
                  type="text"
                  name="address_line1"
                  onChange={handleChange}
                  value={contact.address_line1}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Town/City</Label>
                <Input
                  type="text"
                  name="town"
                  onChange={handleChange}
                  value={contact.town}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Postcode</Label>
                <Input
                  type="text"
                  name="postcode"
                  onChange={handleChange}
                  value={contact.postcode}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Company Registration Number</Label>
                <Input
                  type="text"
                  name="company_registration_no"
                  onChange={handleChange}
                  value={contact.company_registration_no}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>VAT Number</Label>
                <Input
                  type="text"
                  name="vat_no"
                  onChange={handleChange}
                  value={contact.vat_no}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Accounts</h1>
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Credit Limit</Label>
              <Control iconLeft>
                <Input
                  className="input"
                  type="number"
                  name="credit_limit"
                  onChange={handleChange}
                  value={contact.credit_limit}
                />
                <Icon icon="pound-sign" align="left" />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Payment Terms</Label>
                <Input
                  type="text"
                  name="payment_terms"
                  onChange={handleChange}
                  value={contact.payment_terms}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>Payment Method</Label>
                <Input
                  type="text"
                  name="payment_method"
                  onChange={handleChange}
                  value={contact.payment_method}
                />
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Heading size={6}>Workshop Instructions</Heading>
            <Table>
              <thead>
              <tr>
                <th>Instruction</th>
                <th>Remove</th>
              </tr>
              </thead>
              {contact.workshop_instructions.map((value, index) => (
                <Instruction
                  contact={contact.id}
                  key={value.id || value.key}
                  index={index}
                  instruction={value}
                  updateInstructions={updateWorkshopInstructions}
                  removeInstruction={removeWorkshopInstruction}
                />
              ))}
            </Table>
            <Columns>
              <Columns.Column>
                <Button
                  id="add-workitem"
                  color="info"
                  onClick={addWorkshopInstruction}
                >
                  Add Workshop Instruction
                </Button>
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Heading size={6}>Rental Instructions</Heading>
            <Table>
              <thead>
              <tr>
                <th>Instruction</th>
                <th>Remove</th>
              </tr>
              </thead>
              {contact.rental_instructions.map((value, index) => (
                <Instruction
                  contact={contact.id}
                  key={value.id || value.key}
                  index={index}
                  instruction={value}
                  updateInstructions={updateRentalInstructions}
                  removeInstruction={removeRentalInstruction}
                />
              ))}
            </Table>
            <Columns>
              <Columns.Column>
                <Button
                  id="add-workitem"
                  color="info"
                  onClick={addRentalInstruction}
                >
                  Add Rental Instruction
                </Button>
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Field className="has-addons">
              <Control>
                <Label>Status</Label>
                <Button
                  color={!contact.accounts_active ? "primary" : ""}
                  onClick={() => setActive(false)}
                >
                  Inactive
                </Button>
                <Button
                  color={contact.accounts_active ? "primary" : ""}
                  onClick={() => setActive(true)}
                >
                  Active
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      {contact.contact_type === "SP" && (
        <Box>
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Workshop Contact Details"
          >
            <Heading size={5}>Primary Workshop Contact</Heading>
            <Columns>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Name</Label>
                    <Input
                      type="text"
                      name="workshop_name"
                      onChange={handleChange}
                      value={contact.workshop_name}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Phone Number</Label>
                    <Input
                      type="text"
                      name="workshop_contact_number"
                      onChange={handleChange}
                      value={contact.workshop_contact_number}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="workshop_email"
                      onChange={handleChange}
                      value={contact.workshop_email}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Comments</Label>
                    <Input
                      type="text"
                      name="workshop_comments"
                      onChange={handleChange}
                      value={contact.workshop_comments}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Heading size={5}>Additional Workshop Contacts</Heading>
            <ContactInfoSection
              contactList={contact.workshop_contacts}
              propertyName="workshop_contacts"
            />
          </Collapsible>
        </Box>
      )}
      {contact.contact_type === "SP" && (
        <Box>
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Parts Desk Contact Details"
          >
            <Heading size={5}>Primary Parts Contact</Heading>
            <Columns>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Name</Label>
                    <Input
                      type="text"
                      name="parts_name"
                      onChange={handleChange}
                      value={contact.parts_name}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Phone Number</Label>
                    <Input
                      type="text"
                      name="parts_contact_number"
                      onChange={handleChange}
                      value={contact.parts_contact_number}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="parts_email"
                      onChange={handleChange}
                      value={contact.parts_email}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Field>
                  <Control>
                    <Label>Comments</Label>
                    <Input
                      type="text"
                      name="parts_comments"
                      onChange={handleChange}
                      value={contact.parts_comments}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Heading size={5}>Additional Parts Contacts</Heading>
            <ContactInfoSection
              contactList={contact.part_contacts}
              propertyName="part_contacts"
            />
          </Collapsible>
        </Box>
      )}
      <Box>
        <Collapsible
          className="title"
          triggerOpenedClassName="title"
          trigger="Additional Contacts"
        >
          <ContactInfoSection
            contactList={contact.additional_contacts}
            propertyName="additional_contacts"
            includeAddress
          />
        </Collapsible>
      </Box>
      {contact.contact_type === "CC" && (
        <Customer contact={contact} handleChange={handleChange} handleCheckbox={handleCheckbox} />
      )}
      {contact.contact_type === "SP" && (
        <Supplier contact={contact} handleChange={handleChange} handleCheckbox={handleCheckbox}/>
      )}
      {contact.contact_type === "CC" && contact.id && (
        <CustomerRentalRecord customer={contact.id} />
      )}
      <Box>
        <Field>
          <Control>
            <Button onClick={handleSubmit} fullwidth type="submit" color="info">
              Save Contact
            </Button>
          </Control>
        </Field>
        {contact.id && (
          <Field>
            <Control>
              <Button
                onClick={() => {
                  setModalOpen(true);
                }}
                fullwidth
                type="submit"
                color="danger"
              >
                Delete Contact
              </Button>
            </Control>
          </Field>
        )}
      </Box>
      <DeleteWarningModal
        open={modalOpen}
        setOpen={setModalOpen}
        onDelete={handleDelete}
      />
      <TypeChangeModal
        open={typeModalOpen}
        setOpen={setTypeModalOpen}
        targetType={targetType}
      />
    </div>
  );
}

export default Form;
