import React, {useState} from "react";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Columns from "react-bulma-components/lib/components/columns";
import Notification from "react-bulma-components/lib/components/notification"
import Heading from "react-bulma-components/lib/components/heading"
import {
  Field,
  Label,
  Input, Control
} from "react-bulma-components/lib/components/form";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import Icon from "react-bulma-components/lib/components/icon";

const AsyncTypeahead = asyncContainer(Typeahead);

function ContactSection(props) {
  const [, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [typeahead, setTypeahead] = useState();

  const types = {
    CC: "Customer",
    SP: "Supplier"
  };

  const handleCustomerSelect = e => {
    typeahead.getInstance().clear();
    props.contactUpdate(e[0]);
  };

  const handleClear = e => {
    typeahead.getInstance().clear();
    props.contactUpdate(undefined);
  };

  const handleSearch = query => {
    setLoading(true);
    axios
      .get(props.endpoint + `?contact_type=${props.type}&search=${query}`)
      .then(resp => {
        setSearchData(resp.data.results);
        setLoading(false);
      });
  };

  return (
    <Box className="contact-section">
      <Columns>
        <Columns.Column>
          <h1 className="title">{props.title}</h1>
        </Columns.Column>

        {props.type === "CC" && !props.print && (
          <Columns.Column>
            <Button
              tabIndex={-1}
              onClick={() => window.open("/editcontact", "_blank")}
              color="success"
              className="is-pulled-right"
              disabled={props.disabled}
            >
              Add a new Customer +
            </Button>
          </Columns.Column>
        )}
        {props.contact.id &&
          <Columns.Column>
            <Button
              tabIndex={-1}
              onClick={() => window.open(`/editcontact/${props.contact.id}`, "_blank")}
              color="info"
              className="is-pulled-right"
            >
              Contact Details
            </Button>
          </Columns.Column>
        }
        <Columns.Column>
          <Button
            tabIndex={-1}
            onClick={handleClear}
            color="warning"
            className="is-pulled-right"
            disabled={props.disabled}
          >
            Clear
          </Button>
        </Columns.Column>
      </Columns>
      {!props.print && (
        <AsyncTypeahead
          isLoading={false}
          id="typeahead"
          labelKey="display_name"
          minLength={2}
          name={"display_name"}
          onSearch={handleSearch}
          onChange={handleCustomerSelect}
          placeholder={`Search ${types[props.type]}`}
          options={searchData}
          ref={typeahead => setTypeahead(typeahead)}
          className="typeahead"
          disabled={props.disabled}
        />
      )}
      <Columns>
        <Columns.Column>
          <Field>
            <Label>Name</Label>
            <Input
              type="text"
              name="display_name"
              // onChange={this.handleChange}
              value={props.contact.display_name}
              readOnly
              disabled
            />
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Contact Number</Label>
            <Input
              type="text"
              name="company_phone"
              // onChange={this.handleChange}
              value={props.contact.company_phone}
              readOnly
              disabled
            />
          </Field>
        </Columns.Column>
        {!props.print && (
          <Columns.Column>
            <Field>
              <Label>Email</Label>
              <Input
                type="text"
                name="company_email"
                value={props.contact.company_email}
                readOnly
                disabled
              />
            </Field>
          </Columns.Column>
        )}
      </Columns>
      {props.print && (
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Email</Label>
              <Input
                type="text"
                name="email"
                value={props.contact.company_email}
                readOnly
                disabled
              />
            </Field>
          </Columns.Column>
        </Columns>
      )}
      <Columns>
        <Columns.Column>
          <Field>
            <Label>Address</Label>
            <pre>{`${props.contact.address_line1 || ""}\n${props.contact.town ||
            ""}\n${props.contact.postcode || ""}`}</pre>
          </Field>
        </Columns.Column>
      </Columns>
      {props.contact.id && props.displayWorkshopInstructions && !props.contact.accounts_active &&
        <Notification
          className="clickable"
          onClick={() => window.open(`/editcontact/${props.contact.id}`, "_blank")}
          color="danger">
          Warning! Account not active, check contact page.
        </Notification>
      }
      {props.displayWorkshopInstructions && props.contact.id &&
        <Columns>
          <Columns.Column>
            <Field>
              <Label>Credit Limit</Label>
              <Control iconLeft>
                <Input
                  type="number"
                  name="credit_limit"
                  value={props.contact.credit_limit}
                  readOnly
                  disabled
                />
                <Icon icon="pound-sign" align="left"/>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Payment Terms</Label>
              <Input
                type="text"
                name="payment_terms"
                value={props.contact.payment_terms}
                readOnly
                disabled
              />
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Label>Payment Method</Label>
              <Input
                type="text"
                name="payment_method"
                value={props.contact.payment_method}
                readOnly
                disabled
              />
            </Field>
          </Columns.Column>
        </Columns>
      }
      {(props.displayWorkshopInstructions && props.contact.workshop_instructions && props.contact.workshop_instructions.length > 0) &&
        <Heading size={6}>Workshop Instructions</Heading>}
      {props.displayWorkshopInstructions && props.contact.workshop_instructions && props.contact.workshop_instructions.map(instruction =>
        <Columns>
          <Columns.Column>
            <Field>
              <Input
                type="text"
                name="workshop_instructions"
                value={instruction.text}
                readOnly
                disabled
              />
            </Field>
          </Columns.Column>
        </Columns>
      )
      }
    </Box>
  );
}

export default ContactSection;
