import {
  Control,
  Field,
  Input
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import Button from "react-bulma-components/lib/components/button";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableCostCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  disabled,
  inlineEdit
}) {
  const input = (
    <td onClick={e => e.stopPropagation()}>
      <Field className="has-addons">
        <Control iconLeft>
          <Input
            onChange={onChange}
            className="small-row-black"
            type={editable ? "number" : "text"}
            name={propName}
            value={
              editable
                ? value
                : (parseFloat(value) || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
            }
            disabled={!editable}
          />
          <Icon className="small-icon" icon="pound-sign" align="left" />
        </Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
      </Field>
    </td>
  );
  return input;
}

export default useEditableTableCostCell;
