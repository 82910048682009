import React, {useState} from "react";
import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import {
  Label,
  Field,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import ExistingItemModal from "./ExistingItemModal";

const AsyncTypeahead = asyncContainer(Typeahead);

function VehicleJobSection({
                             handleVehicleChange,
                             vehicleUpdate,
                             endpoint,
                             vehicle,
                             history,
                             type,
                             disabled
                           }) {
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [typeahead, setTypeahead] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [associatedItems, setAssociatedItems] = useState([]);
  const [targetVehicle, setTargetVehicle] = useState({});

  const handleChange = e => {
    vehicle[e.target.name] = e.target.value;
    handleVehicleChange(vehicle);
  };

  const handleContinue = vehicle => {
    typeahead.getInstance().clear();
    vehicleUpdate(vehicle);
    setModalOpen(false);
  };

  const handleVehicleSelect = e => {
    if (e[0] && e[0].id) {
      axios
        .all([
          axios.get(endpoint + `quotes?vehicle=${e[0].id}&ready_for_customer_invoice=false`),
          axios.get(
            endpoint + `jobs?vehicle=${e[0].id}&view=not_finished&ws=all`
          )
        ])
        .then(
          axios.spread((estimateResp, jobResp) => {
            if (
              jobResp.data.results.length ||
              estimateResp.data.results.length
            ) {
              setAssociatedItems(
                jobResp.data.results
                  .map(jobData => {
                    return {...jobData, itemType: "Job"};
                  })
                  .concat(
                    estimateResp.data.results.map(estimateData => {
                      return {...estimateData, itemType: "Quote"};
                    })
                  )
              );
              setTargetVehicle(e[0]);
              setModalOpen(true);
            } else {
              typeahead.getInstance().clear();
              vehicleUpdate(e[0]);
            }
          })
        );
    }
  };

  const handleSearch = query => {
    setLoading(true);
    let url = endpoint + `vehicles?search=${query}`;
    axios.get(url).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handleVehicleType = customerVehicle => {
    const newVehicle = {vehicle_status: customerVehicle};
    handleVehicleChange(newVehicle);
  };

  return (
    <Box className="top-spacing">
      <h1 className="title">Vehicle</h1>
      {!disabled &&
      <Columns>
        <Columns.Column>
          <Field>
            <Control>
              <AsyncTypeahead
                data-testid="vehicle-search"
                id="typeahead"
                labelKey="registration"
                minLength={2}
                name={"registration"}
                onSearch={handleSearch}
                onChange={handleVehicleSelect}
                placeholder={"Search Vehicle Registration"}
                options={searchData}
                ref={typeahead => setTypeahead(typeahead)}
                className="registration-ui"
                isLoading={isLoading}
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Button
            tabIndex={-1}
            onClick={() => window.open("/editvehicle", "_blank")}
            color="success"
            className="is-pulled-right"
          >
            Add a new Vehicle +
          </Button>
        </Columns.Column>
      </Columns>
      }
      <Columns>
        <Columns.Column>
          <Field>
            <Label  className="label">Registration</Label>
            <Control className="registration-wrapper">
              <Input
                className="registration-field"
                type="text"
                name="registration"
                onChange={handleChange}
                value={vehicle.registration}
                required
                disabled={true}
              />
            </Control>
          </Field>
          {vehicle.id &&
          <Button tabIndex={-1} color="info" onClick={() => window.open(`/editvehicle/${vehicle.id}`, "_blank")}>Vehicle Details</Button>
          }

        </Columns.Column>
          <Columns.Column>
          <Field>
            <Label>LCV Sold</Label>
            <input
              className="checkbox"
              type="checkbox"
              name="lcv_sold"
              readOnly={true}
              checked={vehicle.lcv_sold}
            />
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Control>
              <Label>Vehicle Status</Label>
              <Input
                type="text"
                name="vehicle_status"
                value={vehicle.vehicle_status}
                required
                readOnly={true}
                disabled={true}
              />
            </Control>
          </Field>
        </Columns.Column>
        {vehicle.owner &&
        <Columns.Column>
          <Field>
            <Control>
              <Label>Customer Owner</Label>
              <Input
                type="text"
                name="customer_owner"
                value={vehicle.owner.display_name}
                required
                readOnly={true}
                disabled={true}
              />
            </Control>
          </Field>
        </Columns.Column>
        }
      </Columns>
      <Columns>
        <Columns.Column>
          <Field>
            <Control>
              <Label>Mileage</Label>
              <Input
                type="number"
                name="mileage"
                onChange={handleChange}
                value={String(vehicle.mileage)}
                required
                readOnly={true}
                disabled={true}
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Control>
              <Label>Make</Label>
              <Input
                type="text"
                name="make"
                onChange={handleChange}
                value={vehicle.make}
                required
                readOnly={true}
                disabled={true}
              />
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>Model</Label>
            <Input
              type="text"
              name="model"
              onChange={handleChange}
              value={vehicle.model}
              required
              readOnly={true}
              disabled={true}
            />
          </Field>
        </Columns.Column>
      </Columns>
      <ExistingItemModal
        open={modalOpen}
        setOpen={setModalOpen}
        items={associatedItems}
        onContinue={handleContinue}
        history={history}
        type={type}
        vehicle={targetVehicle}
      />
    </Box>
  );
}

export default VehicleJobSection;
