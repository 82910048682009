import FilterHeading from "../../../Invoicing/FilterHeading";
import React from "react";

function ArrivingHeadings({data, activeTab, vehicleStatus, filters, setPage, setFilters}) {

  return (<tr>
    <th>Order No</th>
    <th>Registration</th>
    <FilterHeading
      key={"month_due"}
      heading={{label: "Month Due", field: "month_due", labelField: "month_due"}}
      setFilters={f => {
        setPage(1);
        setFilters(f);
      }}
      filters={filters}
      options={data.filter_list['month_due']}
    />
    <th>Order Date</th>
    <FilterHeading
    key={"Delivery_date"}
    heading={
      {
        label: "Delivery Date",
        field: "delivery_date",
        labelField: "delivery_date",
        date: true
      }}
    setFilters={f => {
      setPage(1);
      setFilters(f);
    }}
    filters={filters}
    options={data.filter_list['delivery_date']}
  />
    <FilterHeading
      key={"make"}
      heading={{label: "Make", field: "make", labelField: "make"}}
      setFilters={f => {
        setPage(1);
        setFilters(f);
      }}
      filters={filters}
      options={data.filter_list['make']}
    />
    <FilterHeading
      key={"model"}
      heading={{label: "Model", field: "model", labelField: "model"}}
      setFilters={f => {
        setPage(1);
        setFilters(f);
      }}
      filters={filters}
      options={data.filter_list['model']}
    />
    <th>Chassis No.</th>
    <th>Purchase Price</th>
    <th>Status</th>
    <FilterHeading
      key={"reserve_customer__display_name"}
      heading={{label: "Reserved", field: "reserve_customer", labelField: "reserve_customer__display_name"}}
      setFilters={f => {
        setPage(1);
        setFilters(f);
      }}
      filters={filters}
      options={data.filter_list['reserve_customer']}
    />
    <th>Comments</th>
  </tr>)
}

export default ArrivingHeadings;
