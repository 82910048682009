import React, {useState} from "react";
import {formatCurrency, readableDate} from "../../utils";
import {getDataFresh} from "../../state/selectors";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import Button from "react-bulma-components/lib/components/button";
import {NotificationManager} from "react-notifications";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import useSafeSave from "../../hooks/useSafeSave";
import GenericModal from "../Modal/GenericModal";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import useEditableTableDropdownCell from "../../hooks/useEditableTableDropdownCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";

export default function PaymentRow({
                                     payment,
                                     onPaymentUpdate,
                                     costCentres,
                                     costCentreObjects,
                                     endpoint,
                                     index
                                   }) {
  const today = Date.now();
  const overdue = (!payment.date_paid) && new Date(payment.payment_date) < today;
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  let dataFresh = useSelector(getDataFresh);
  const [lastEdited, setLastEdited] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onSave = () => {
    const url = endpoint + "payment/" + payment.id;
    let paymentToSave = {...payment};
    const conf = {
      method: "put",
      data: paymentToSave,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onPaymentDelete = () => {
    const url = endpoint + "payment/" + payment.id;
    const conf = {method: "delete", url};
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  // const onTypeChange = (e, index) => {
  //   const toUpdate = {
  //     target: {
  //       name: "payment_type",
  //       value: typeObjects[e.target.value]
  //     }
  //   };
  //   onChange(toUpdate, index);
  // };

  const checkUpdated = () => {
    return axios.get(endpoint + "payment/" + payment.id).then(newPayment => {
      return newPayment.data.last_saved === payment.last_saved;
    });
  };


  const [makeDirty, saveAndClear] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onCostCentreChange = e => {
    const toUpdate = {
      target: {
        name: "cost_centre",
        value: costCentreObjects[e.target.value]
      }
    };
    onChange(toUpdate)
  }

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newPayment = {...payment};
    newPayment[e.target.name] = e.target.value;
    onPaymentUpdate(index, newPayment);
  };

  const rowOverdueClassname = overdue ? ' table-highlight-orange' : '';

  return (
    <tr key={payment.id} className={'clickable small-row-black' + rowOverdueClassname}>
      <td>{payment.contact ? payment.contact.display_name : ""}</td>
      <td>{useEditableTableTextCell({
        propName: "invoice_number",
        value: payment.invoice_number,
        onChange,
        onSave: saveAndClear,
        editable,
        setEditable,
        inlineEdit: true
      })}</td>
      <td key='payment-date'>{readableDate(payment.payment_date)} {overdue && <b> - OVERDUE!</b>}</td>
      {useEditableTableCostCell({
        propName: "amount",
        value: payment.amount,
        onChange,
        onSave: saveAndClear,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDropdownCell({
        propName: "payment_type",
        value: payment.payment_type,
        options: [
          {name: 'BACS', id: 'BACS'},
          {name: 'CASH', id: 'CASH'},
          {name: 'CARD', id: 'CARD'},
          {name: 'DIRECT DEBIT', id: 'DIRECT DEBIT'}
        ],
        onChange,
        onSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDropdownCell({
        propName: "cost_centre",
        value: payment.cost_centre,
        options: costCentres,
        onChange: onCostCentreChange,
        onSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableTextAreaCell({
        propName: "comments",
        value: payment.comments,
        onChange,
        onSave: saveAndClear,
        inlineEdit: true,
        editable,
        setEditable
      })}
      {useEditableTableDateCell({
        propName: "date_paid",
        value: payment.date_paid,
        onChange,
        onSave: saveAndClear,
        editable,
        setEditable,
        inlineEdit: true
      })}
      <td>
        <Button
          color="danger"
          data-testid={`submit-payment-delete${payment.id}`}
          onClick={() => setDeleteModalOpen(true)}
        >
          X
        </Button>
        <DeleteWarningModal
          onDelete={onPaymentDelete}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
        />
      </td>
    </tr>
  );
}
