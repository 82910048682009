import React from "react";
import Columns from "react-bulma-components/lib/components/columns";

function Figure(props) {
  return (
    <Columns.Column>
      {props.label}: £
      {props.loaded && props.value != null
        ? props.value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        : "Loading..."}
    </Columns.Column>
  );
}

export default Figure;
