import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataFresh, getVehiclesData } from "../../state/selectors";
import {
  createSetDataFreshAction,
  createSetVehiclesDataAction
} from "../../actions/dataActions";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import axios from "axios";
import { NotificationManager } from "react-notifications";

export default function CourtesyCarRow({ value, index, endpoint }) {
  const [editable, setEditable] = useState(false);
  const vehicles = useSelector(getVehiclesData);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const onChange = e => {
    let newVehicle = vehicles.results[index];
    newVehicle[e.target.name] = e.target.value;
    let newVehicles = { ...vehicles };
    newVehicles.results[index] = newVehicle;
    dispatch(createSetVehiclesDataAction(newVehicles));
  };

  const onSave = e => {
    e.preventDefault();
    const url = endpoint + "vehicles/" + value.id;

    const conf = {
      method: "put",
      data: value,
      url
    };

    axios(conf)
      .then(response => {
        if (response) {
          dispatch(createSetDataFreshAction(dataFresh + 1));
          setEditable(false);
        }
      })
      .catch(err => {
        if (err.response.data) {
          NotificationManager.error(
            JSON.stringify(err.response.data),
            "Error!",
            10000
          );
        }
      });
  };

  return (
    <tr
      className="clickable"
      style={{ fontSize: 12 }}
      onClick={() => window.open(`/editvehicle/${value.id}`, "_self")}
      key={value.id}
    >
      <td>{value.registration}</td>
      <td>{value.make}</td>
      <td>{value.model}</td>
      {useEditableTableTextCell({
        propName: "location",
        value: value.location,
        onChange,
        onSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
    </tr>
  );
}
