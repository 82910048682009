import React, {useEffect, useState} from "react";
import {
  Label,
  Field,
  Control,
  Input
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Table from "react-bulma-components/lib/components/table";
import {useDispatch, useSelector} from "react-redux";
import {
  getDataFresh,
  getVehicleTrackersData,
} from "../../state/selectors";
import Pagination from "react-bulma-components/lib/components/pagination";
import VehicleTrackerRow from "./VehicleTrackerRow";
import {
  createSetDataFreshAction,
  createSetVehicleTrackersDataAction,
} from "../../actions/dataActions";
import CreateTrackerModal from "./CreateTrackerModal";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import axios from "axios";
import FAIcon from "../Icon/FAIcon";
import {useVehicleTrackerData} from "../../hooks/useVehicleTrackerData";

function VehicleTrackers(props) {
  console.log({props});

  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState({});
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [createPartModalOpen, setCreatePartModalOpen] = useState(false);

  const headings = [
    {
      label: "IMEI",
      field: "imei",
      labelField: "imei"
    },
    { label: "Vehicle",
      field: "vehicle",
      labelField: "vehicle"
    },
  ];

  let params = {
    page,
    search,
  };

  const vehicleTrackersLoaded = useVehicleTrackerData(params, dataFresh);

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter] || (filters[filter][0] && filters[filter][0][filter] === 0)) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const data = useSelector(getVehicleTrackersData);
  useEffect(() => {
    console.log({data})
  }, [data])

  const updateValue = (index, newTracker) => {
    let currentData = { ...data };
    currentData.results[index] = {...newTracker};
    dispatch(createSetVehicleTrackersDataAction(currentData));
  };

  if (!vehicleTrackersLoaded) return <div>Loading</div>;

  document.title = "Vehicle Trackers";

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Label>Tracker Search</Label>
            <Field className="has-addons">
              <Control>
                <Input
                  onChange={e => {
                    setSearchText(e.target.value);
                  }}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && setSearch(searchText)}
                  data-testid="part-search"
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                />
              </Control>
              <Control>
                <Button
                  data-testid="search-button"
                  color="success"
                  onClick={() => {
                    setSearch(searchText);
                  }}
                >
                  Search
                </Button>
              </Control>
              <Control>
                <Button
                  color="warning"
                  onClick={() => {
                    setSearch("");
                    setSearchText("");
                  }}
                >
                  Clear
                </Button>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Button
                  onClick={() => setCreatePartModalOpen(true)}
                  color="success"
                  className="is-pulled-right"
                >
                  New Tracker +
                </Button>
              </Control>
            </Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Table>
          <thead>
            <tr>
              {(headings.map(heading => <th key={heading.label}>{heading.label}</th>))}
            </tr>
          </thead>
          <tbody>
            {data.results.map((tracker, index) => (
              <VehicleTrackerRow
                key={tracker.id}
                tracker={tracker}
                endpoint={props.endpoint}
                updateValue={newTracker => updateValue(index, newTracker)}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
      <CreateTrackerModal
        open={createPartModalOpen}
        setOpen={setCreatePartModalOpen}
        endpoint={props.endpoint}
      />
    </div>
  );
}

export default VehicleTrackers;
