import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSetQuickLinksDataAction } from "../../../actions/dataActions";
import { getDataFresh, getQuickLinksData } from "../../../state/selectors";
import Spinner from "react-spinner-material";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Table from "react-bulma-components/lib/components/table";
import Pagination from "react-bulma-components/lib/components/pagination";
import { useQuickLinkData } from "../../../hooks/useQuickLinkData";
import NewQuickLink from "./NewQuickLink";
import QuickLinkRow from "./QuickLinkRow";

function QuickLinkView(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const onQuickLinkUpdate = (index, newQuickLink) => {
    let newState = data;
    newState.results[index] = newQuickLink;
    dispatch(createSetQuickLinksDataAction(newState));
  };

  const data = useSelector(getQuickLinksData);

  return (
    <div>
      <NewQuickLink />
      <Box>
        <Heading size={5}>Quick Links</Heading>
        <Heading size={6}>
          Note: Quick Links are seen by all site users
        </Heading>
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>URL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(data.results ?? []).map((value, index) => (
              <QuickLinkRow
                key={value.id}
                quickLinkData={value}
                index={index}
                endpoint={props.endpoint}
                onQuickLinkUpdate={onQuickLinkUpdate}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        >
          {" "}
        </Pagination>
      </Box>
    </div>
  );
}

export default QuickLinkView;
