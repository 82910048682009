import React from "react";
import Table from "react-bulma-components/lib/components/table";
import Heading from "react-bulma-components/lib/components/heading";
import { readableDate, formatCurrency } from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import Columns from "react-bulma-components/lib/components/columns";

class PrintParts extends React.Component {
  get suppliers() {
    const partValues = Object.values(this.props.data);
    const suppliers = {};

    for (let i = 0; i < partValues.length; i++) {
      if (!suppliers[partValues[i].supplier.display_name]) {
        suppliers[partValues[i].supplier.display_name] = {total: 0, vat_no: partValues[i].supplier.vat_no};
      }
      suppliers[partValues[i].supplier.display_name].total += partValues[i].cost;
    }
    return suppliers;
  }

  get totalCost() {
    return Object.values(this.suppliers).reduce((sum, next) => sum + next.total, 0);
  }

  get totalWithVAT() {
    return (Math.round((this.totalCost * 1.2)*100))/100;
  }

  // make it work for quotes

  PartRow(part) {
    return (
      <tr key={part.id}>
        <td>{part.job ? part.job.vehicle.make : part.quote.vehicle.make}</td>
        <td>
          {part.job ? part.job.vehicle.model : part.quote.vehicle.model}
        </td>
        <td>{part.name}</td>
        <td>{formatCurrency(part.cost)}</td>
        <td>{readableDate(part.date_ordered)}</td>
        <td>{readableDate(part.payment_date)}</td>
        <td>{part.supplier.display_name}</td>
        <td>{part.part_invoice_number}</td>
      </tr>
    );
  }

  render() {
    return (
      <div className="print-background">
        <Columns>
          <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
          <Heading>Parts Invoicing</Heading>
        </Columns>
        <Table>
          <thead>
            <tr>
              <th>Total Cost</th>
              <th>Total With VAT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatCurrency(this.totalCost)}</td>
              <td>{formatCurrency(this.totalWithVAT)}</td>
            </tr>
          </tbody>
        </Table>
        <Heading size={4}>Supplier Summary</Heading>
        <Table>
          <thead>
            <tr>
              <th>Supplier</th>
              <th>VAT No.</th>
              <th>Total Costs</th>
              <th>Total Costs + VAT</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(this.suppliers).map(entry => (
              <tr>
                <td>{entry[0]}</td>
                <td>{entry[1].vat_no}</td>
                <td>{formatCurrency(entry[1].total)}</td>
                <td>{formatCurrency(entry[1].total * 1.2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Heading size={4}>Parts Breakdown</Heading>
        <Table>
          <thead>
            <tr>
              <th>Make</th>
              <th>Model</th>
              <th>Part Name</th>
              <th>Cost</th>
              <th>Date Ordered</th>
              <th>Payment Date</th>
              <th>Supplier</th>
              <th>Invoice Number</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(this.props.data)
              .sort((a, b) => {
                // sort by supplier then ordered date
                if (a.supplier.display_name > b.supplier.display_name) return 1;
                if (a.supplier.display_name < b.supplier.display_name)
                  return -1;

                return new Date(a.date_ordered) - new Date(b.date_ordered);
              })
              .map(entry => this.PartRow(entry))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default PrintParts;
